import customTheme from "../../styles/theme";
import { Spinner, SpinnerProps } from "@chakra-ui/react";
import React from "react";

export function NembessSpinner(props: SpinnerProps) {
  return (
    <Spinner
      {...props}
      thickness={"5px"}
      color={customTheme.colors.pybessPurple}
      emptyColor={"gray.200"}
      size={"xl"}
    />
  );
}
