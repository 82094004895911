import {
  ModalBody,
  Table,
  TableCaption,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import ViewportContext from "../styles/viewport-context";
import { useContext, useEffect, useState } from "react";
import { BessStation, DuDetail, VPP } from "../api/api-types";
import axios from "axios";

type BessInfoModalProps = {
  selectedBessStation: VPP | BessStation;
};

export function BessInfoModal(props: BessInfoModalProps) {
  const viewport = useContext(ViewportContext);
  const isSmallScreen = ["mobile", "tablet"].includes(viewport.type);
  const { selectedBessStation } = props;
  const generatorDuid = selectedBessStation.duids.find(
    (duid) => duid.duid_type === "GENERATOR"
  );
  const loadDuid = selectedBessStation.duids.find(
    (duid) => duid.duid_type === "LOAD"
  );
  const [duDetail, setDuDetail] = useState<DuDetail[]>([]);

  useEffect(() => {
    async function fetchDudetail(stationId: number) {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_APIHOST}/power_stations/${stationId}/dudetail`
        );
        setDuDetail(response.data);
      } catch (error) {
        console.error(error);
      }
    }
    fetchDudetail(selectedBessStation.id);
  }, [selectedBessStation]);
  return (
    <ModalBody>
      <Text fontSize={isSmallScreen ? "xs" : "sm"}>
        {/*{selectedBessStation && selectedBessStation.configuration}*/}
      </Text>
      <br />
      <Table fontSize={isSmallScreen ? "xs" : "sm"} variant="simple">
        <TableCaption> Generation DUID: {generatorDuid?.duid}</TableCaption>
        <Thead>
          <Tr>
            <Th>Financial Year</Th>
            <Th isNumeric>Transmission Loss Factor</Th>
            <Th isNumeric>Distribution Loss Factor</Th>
          </Tr>
        </Thead>
        <Tbody>
          {duDetail
            .filter((value) => value.duid === generatorDuid?.duid)
            .map((value, index) => (
              <Tr key={index}>
                <Td>{value.financial_year}</Td>
                <Td isNumeric>{value.transmissionlossfactor}</Td>
                <Td isNumeric>{value.distributionlossfactor}</Td>
              </Tr>
            ))}
        </Tbody>
      </Table>
      <Table fontSize={isSmallScreen ? "xs" : "sm"} variant="simple">
        <TableCaption> Load DUID: {loadDuid?.duid}</TableCaption>
        <Thead>
          <Tr>
            <Th>Financial Year</Th>
            <Th isNumeric>Transmission Loss Factor</Th>
            <Th isNumeric>Distribution Loss Factor</Th>
          </Tr>
        </Thead>
        <Tbody>
          {duDetail
            .filter((value) => value.duid === loadDuid?.duid)
            .map((value, index) => (
              <Tr key={index}>
                <Td>{value.financial_year}</Td>
                <Td isNumeric>{value.transmissionlossfactor}</Td>
                <Td isNumeric>{value.distributionlossfactor}</Td>
              </Tr>
            ))}
        </Tbody>
      </Table>
    </ModalBody>
  );
}
