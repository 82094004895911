import { extendTheme, theme } from "@chakra-ui/react";
import { createBreakpoints } from "@chakra-ui/theme-tools";

export const DESKTOP_LARGE_WIDTH_BREAKPOINT = 1920;
export const DESKTOP_WIDTH_BREAKPOINT = 1024;
export const TABLET_WIDTH_BREAKPOINT = 768;
export const PAGE_SPACING_PER_VIEWPORT = [1, 3, 10, "8vw"];

export function getViewportType(width) {
  if (width >= DESKTOP_LARGE_WIDTH_BREAKPOINT) {
    return "desktop-lg";
  } else if (width >= DESKTOP_WIDTH_BREAKPOINT) {
    return "desktop";
  } else if (
    width < DESKTOP_WIDTH_BREAKPOINT &&
    width >= TABLET_WIDTH_BREAKPOINT
  ) {
    return "tablet";
  } else {
    return "mobile";
  }
}

createBreakpoints({
  sm: `${TABLET_WIDTH_BREAKPOINT}px`,
  md: `${DESKTOP_WIDTH_BREAKPOINT}px`,
  lg: `${DESKTOP_LARGE_WIDTH_BREAKPOINT}px`,
  xl: `${DESKTOP_LARGE_WIDTH_BREAKPOINT}px`,
});

export const colors = {
  ...theme.colors,
  contingency: {
    50: "#d52145",
    100: "#d52145",
    200: "#d52145",
    300: "#d52145",
    400: "#d52145",
    500: "#d52145",
    600: "#d52145",
    700: "#d52145",
    800: "#d52145",
    900: "#d52145",
  },
  regulation: {
    50: "#3a50c5",
    100: "#3a50c5",
    200: "#3a50c5",
    300: "#3a50c5",
    400: "#3a50c5",
    500: "#3a50c5",
    600: "#3a50c5",
    700: "#3a50c5",
    800: "#3a50c5",
    900: "#3a50c5",
  },
  energy: {
    50: "#131625",
    100: "#131625",
    200: "#131625",
    300: "#131625",
    400: "#131625",
    500: "#131625",
    600: "#131625",
    700: "#131625",
    800: "#131625",
    900: "#131625",
  },
  backgroundGray: "rgb(245, 246, 248)",

  pybessPurple: "#8e2eb2",
  pybessDarkBlue: "#00468F",
  pybessLightBlue: "#32B6E6",
  chargingBlue: "#0000FF",
  dischargingPurple: "#CC8899",
  navbarBg: "#ffffff",
  darkNavy: "#131625",
};

const customTheme = extendTheme({
  colors,
});

export default customTheme;
