import React, { useContext, useEffect, useLayoutEffect, useRef } from 'react';
import customTheme from '../styles/theme';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import { ColumnSeries, XYChart } from '@amcharts/amcharts4/charts';
import ViewportContext from '../styles/viewport-context';
import { APIRevenue } from '../api/api-types';
import { dateStringToDate } from '../utils';

const REVENUE_TYPE_TO_COLOR = {
  net_energy_revenue: customTheme.colors.energy[700],
  regulation_revenue: customTheme.colors.regulation[600],
  contingency_revenue: customTheme.colors.contingency[500],
};

export type RevenueData = {
  contingency_revenue: number;
  regulation_revenue: number;
  net_energy_revenue: number;
  start_date: Date;
};

type RevenueChartData = {
  contingency_revenue: number;
  regulation_revenue: number;
  net_energy_revenue: number;
  start_date: Date;
  url: string | null;
  aggregation: string;
};

type RevenueChartProps = {
  data: APIRevenue[];
  aggregation: string;
  bessStationId: number | null;
  updateDates: (startDate: Date, endDate: Date) => void;
  startDate: Date;
  endDate: Date;
  dashboardTechnology: 'BATTERY' | 'VPP' | null;
  chartDivHeight?: number;
  selectedDate?: Date;
};

const dashboardTechnologyToPrefix: any = {
  BATTERY: 'batteries',
  VPP: 'vpps',
};

function transformData(
  data: APIRevenue[],
  bessStationId: number | null,
  dashboardTechnology: string,
  aggregation: string,
): RevenueChartData[] {
  return data.map((item) => {
    const prefix =
      dashboardTechnologyToPrefix[dashboardTechnology.toUpperCase()];
    const url =
      dashboardTechnology &&
      `/${prefix}/operations/${bessStationId}?date=${item.start_date
        .toString()
        .substring(0, 10)}`;
    return {
      start_date: dateStringToDate(item.start_date),
      net_energy_revenue: item.net_energy_revenue,
      contingency_revenue: item.contingency_revenue,
      regulation_revenue: item.regulation_revenue,
      url: url,
      aggregation: aggregation,
    };
  });
}

export function RevenueChart(props: RevenueChartProps) {
  const viewport = useContext(ViewportContext);
  const isSmallScreen = ['mobile', 'tablet'].includes(viewport.type);
  const chartRef = useRef<XYChart | null>(null);

  const {
    aggregation,
    data,
    bessStationId,
    updateDates,
    startDate,
    endDate,
    chartDivHeight,
    dashboardTechnology,
    selectedDate,
  } = props;

  useLayoutEffect(() => {
    function onColumnClick(ev: any) {
      const agg = ev.target.dataItem._dataContext.aggregation;
      if (agg.toUpperCase() === 'MONTH') {
        const startDate = ev.target.dataItem._dataContext.start_date;
        const endDate = new Date(
          startDate.getFullYear(),
          startDate.getMonth() + 1,
          0,
        );
        updateDates(startDate, endDate);
      }
    }

    function initialiseChart(
      data: RevenueChartData[],
      startDate: Date,
      endDate: Date,
      aggregation: string,
      onColumnClick: any,
    ) {
      let chart = am4core.create('chartdiv', am4charts.XYChart);
      chart.exporting.menu = new am4core.ExportMenu();
      
      // position zoom out button
      chart.zoomOutButton.align = 'left';
      chart.zoomOutButton.valign = 'top';
      chart.zoomOutButton.background.fill = am4core.color('#25283D');
      chart.zoomOutButton.icon.stroke = am4core.color('#EFD9CE');
      chart.zoomOutButton.icon.strokeWidth = 2;
      // @ts-ignore
      chart.zoomOutButton.background.states.getKey('hover').properties.fill =
        am4core.color('#8e2eb2');

      chart.paddingRight = 20;
      chart.numberFormatter.numberFormat = '$#,###.##a';
      chart.numberFormatter.bigNumberPrefixes = [
        { number: 1e3, suffix: 'k' },
        { number: 1e6, suffix: 'm' },
        { number: 1e9, suffix: 'b' },
      ];
      chart.data = data;

      let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
      dateAxis.renderer.grid.template.location = 0;
      dateAxis.renderer.grid.template.disabled = true;
      dateAxis.min = startDate.getTime();
      dateAxis.max = endDate.getTime();
      dateAxis.tooltipDateFormat = 'yyyy-MM-dd';

      let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      if (valueAxis.tooltip) {
        valueAxis.tooltip.disabled = true;
      }
      valueAxis.renderer.minWidth = 0;
      valueAxis.renderer.grid.template.disabled = false;
      if (isSmallScreen) {
        valueAxis.renderer.labels.template.fontSize = 10;
        valueAxis.renderer.labels.template.paddingLeft = 0;
      }

      function highlightColumn(ev: any) {
        chart.series.each(function(series) {
          if (series instanceof am4charts.ColumnSeries) {
            series.columns.each(function(column) {
              if (column.dataItem?.dates.dateX === ev.target.dataItem.dateX) {
                column.isActive = true;
              } else {
                column.isActive = false;
              }
            });
          }
        });
      }

      let contingencySeries = chart.series.push(new am4charts.ColumnSeries());
      contingencySeries.dataFields.dateX = 'start_date';
      contingencySeries.dataFields.valueY = 'contingency_revenue';
      contingencySeries.name = 'Contingency';
      contingencySeries.tooltipText = 'Contingency: {valueY.value}';
      contingencySeries.fillOpacity = 0.7;
      contingencySeries.fill = REVENUE_TYPE_TO_COLOR['contingency_revenue'];
      contingencySeries.stroke = am4core.color('white', 0); //REVENUE_TYPE_TO_COLOR["contingency_revenue"];
      contingencySeries.dummyData = { radius: 8 };
      if (aggregation.toUpperCase() === 'DAY') {
        contingencySeries.columns.template.propertyFields.url = 'url';
      }
      let contFill = contingencySeries.columns.template.states.create('active');
      contFill.properties.fillOpacity = 1;
      contingencySeries.columns.template.events.on(
        'hit',
        highlightColumn,
        chartRef.current,
      );

      let energySeries = chart.series.push(new am4charts.ColumnSeries());
      energySeries.dataFields.dateX = 'start_date';
      energySeries.dataFields.valueY = 'net_energy_revenue';
      energySeries.tooltipText = 'Energy: {valueY.value}';
      energySeries.fillOpacity = 0.7;
      energySeries.name = 'Energy';
      energySeries.stacked = true;
      energySeries.fill = REVENUE_TYPE_TO_COLOR['net_energy_revenue'];
      energySeries.stroke = am4core.color('white', 0); //REVENUE_TYPE_TO_COLOR["net_energy_revenue"];
      energySeries.dummyData = { radius: 8 };
      if (aggregation.toUpperCase() === 'DAY') {
        energySeries.columns.template.propertyFields.url = 'url';
      }
      let energyFill = energySeries.columns.template.states.create('active');
      energyFill.properties.fillOpacity = 1;
      energySeries.columns.template.events.on('hit', onColumnClick);

      let regulationSeries = chart.series.push(new am4charts.ColumnSeries());
      regulationSeries.dataFields.dateX = 'start_date';
      regulationSeries.dataFields.valueY = 'regulation_revenue';
      regulationSeries.tooltipText = 'Regulation: {valueY.value}';
      regulationSeries.stacked = true;
      regulationSeries.fillOpacity = 0.7;
      regulationSeries.name = 'Regulation';
      //regulationSeries.tensionX = tension;
      regulationSeries.fill = REVENUE_TYPE_TO_COLOR['regulation_revenue'];
      regulationSeries.stroke = am4core.color('white', 0); //REVENUE_TYPE_TO_COLOR["regulation_revenue"];
      regulationSeries.dummyData = { radius: 8 };
      if (aggregation.toUpperCase() === 'DAY') {
        regulationSeries.columns.template.propertyFields.url = 'url';
      }
      let regFill = regulationSeries.columns.template.states.create('active');
      regFill.properties.fillOpacity = 1;
      regulationSeries.columns.template.events.on('hit', onColumnClick);

      chart.cursor = new am4charts.XYCursor();
      chart.cursor.lineX.disabled = true;
      chart.cursor.lineY.disabled = true;

      return chart;
    }

    const transformedData = transformData(
      data,
      bessStationId,
      dashboardTechnology || '',
      aggregation,
    );
    const chart = initialiseChart(
      transformedData,
      startDate,
      endDate,
      aggregation,
      onColumnClick,
    );

    chartRef.current = chart;
    return () => {
      chartRef.current && chartRef.current.dispose();
      chartRef.current = null;
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useLayoutEffect(() => {
    if (chartRef.current) {
      const transformedData = transformData(
        data,
        bessStationId,
        dashboardTechnology || '',
        aggregation,
      );
      chartRef.current.data = transformedData;
      const dateAxis = chartRef.current.xAxes.getIndex(0) as
        | am4charts.DateAxis
        | undefined;
      if (dateAxis) {
        dateAxis.max = endDate.getTime();
        dateAxis.min = startDate.getTime();
      }
      chartRef.current.invalidateData();
      // console.log(chartRef.current);
      const contingencySeries = chartRef.current.series.getIndex(0) as
        | am4charts.ColumnSeries
        | undefined;
      const energySeries = chartRef.current.series.getIndex(1) as
        | am4charts.ColumnSeries
        | undefined;
      const regSeries = chartRef.current.series.getIndex(2) as
        | am4charts.ColumnSeries
        | undefined;
      if (contingencySeries && energySeries && regSeries) {
        if (aggregation.toUpperCase() === 'MONTH') {
          contingencySeries.columns.template.propertyFields.url = undefined;
          energySeries.columns.template.propertyFields.url = undefined;
          regSeries.columns.template.propertyFields.url = undefined;
        } else {
          contingencySeries.columns.template.propertyFields.url = 'url';
          energySeries.columns.template.propertyFields.url = 'url';
          regSeries.columns.template.propertyFields.url = 'url';
        }
      }
      const yAxis = chartRef.current.yAxes.getIndex(0);
      if (yAxis) {
        if (isSmallScreen) {
          yAxis.renderer.labels.template.fontSize = 10;
          yAxis.renderer.labels.template.paddingLeft = 0;
        } else {
          yAxis.renderer.labels.template.fontSize = null;
          yAxis.renderer.labels.template.paddingLeft = 0;
        }
      }
    }
  }, [
    data,
    startDate,
    endDate,
    isSmallScreen,
    aggregation,
    bessStationId,
    updateDates,
    dashboardTechnology,
  ]);

  useEffect(() => {
    if (chartRef.current && selectedDate) {
      chartRef.current?.series.values.forEach((series) => {
        const columnSeries = series as ColumnSeries;
        columnSeries.columns.values.forEach((column) => {
          if (
            column.dataItem?.dates.dateX.toString() === selectedDate.toString()
          ) {
            column.setState('active');
          } else {
            column.setState('default');
          }
        });
      });
    }
  }, [selectedDate, chartRef.current]);  // somehow adding chartRer.current here highlights the 1st column when nothing else will

  return (
    <div
      id='chartdiv'
      style={{ width: '100%', height: `${chartDivHeight || 360}px` }}
    />
  );
}
