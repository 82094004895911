import { Box, Center, Flex, IconButton, Link, Menu, MenuButton, MenuItem, MenuList, Text } from '@chakra-ui/react';
import React, { useContext } from 'react';
import ViewportContext from '../styles/viewport-context';
import { BatteryCharging, HddNetwork, House, Person, Question } from 'react-bootstrap-icons';
import customTheme from '../styles/theme';
import NEMLogo from '../logo3.js';
import { useNavigate } from 'react-router-dom';
import { HamburgerIcon } from '@chakra-ui/icons';

const NAVBAR_LINKS = [
  { display: 'Home', path: '/', type: 'LINK', icon: House },
  { display: 'Simulator', type: 'LINK', path: '/simulator', icon: HddNetwork },
  { display: 'BESS', path: '/batteries', type: 'LINK', icon: BatteryCharging },
  { display: 'VPP', path: '/vpps', type: 'LINK', icon: House },
  { display: 'FAQ', path: '/faq', type: 'LINK', icon: Question },
  { display: 'About', path: '/about', type: 'LINK', icon: Person },
];

export const NAVBAR_HEIGHT = 80;

type NavbarProps = {
  activePage: string;
  toggleSidebar?: () => void;
  showHamburger?: boolean;
};

export function Navbar(props: NavbarProps) {
  const viewportType = useContext(ViewportContext).type;
  const history = useNavigate();
  const { activePage } = props;
  const isSmallScreen = ['mobile', 'tablet'].includes(viewportType);
  return (
    <Box
      width='100vw'
      position='fixed'
      top={0}
      left={0}
      height={`${NAVBAR_HEIGHT}px`}
      bgColor={customTheme.colors.navbarBg}
      boxShadow={'0px 1px 3px rgba(0, 0, 0, 0.2)'}
      zIndex={9}
    >
      <Flex justifyContent={'space-between'} alignItems={'center'} pt={1}>
        <Box pl={2}>
          <Link href={'/'}>
            <NEMLogo
              style={{ minHeight: '40px' }}
              height={`${isSmallScreen ? 55 : 65}px`}
              // padding="15px"
              width={`${isSmallScreen ? 55 : 65}px`}
            />

          </Link>
        </Box>
        <Box>
          {isSmallScreen ? (
            <Box pt={3} pr={3}>
              <Menu>
                <Center>
                  <MenuButton
                    as={IconButton}
                    aria-label='Options'
                    icon={<HamburgerIcon />}
                    variant='outline'
                  />
                </Center>
                <Center pt={1}>
                  <Text fontSize={'xs'} mb={0}>Menu</Text>
                </Center>

                <MenuList>
                  {NAVBAR_LINKS.map((link, index) => {
                    const Icon = link.icon;
                    return (
                      <MenuItem
                        key={index}
                        as='a'
                        href={link.path}
                        icon={Icon && <Icon />}
                      >
                        <Text
                          as='u'
                          style={{
                            textDecorationThickness:
                              link.display === activePage ? 2 : 1,
                            textUnderlineOffset: 3,
                          }}
                          color={
                            link.display === activePage
                              ? customTheme.colors.pybessPurple
                              : null
                          }
                          fontWeight={
                            link.display === activePage ? 'bolder' : 'normal'
                          }
                        >
                          {link['display']}
                        </Text>
                      </MenuItem>
                    );
                  })}
                </MenuList>
              </Menu>
            </Box>
          ) : (
            <Flex gap={12} pr={'40px'}>
              {NAVBAR_LINKS.slice()
                .map((link, index) => (
                  <Link
                    onClick={() => history(link['path'], { state: { isReloading: true } })}
                    key={index}
                    color={
                      link.display === activePage
                        ? customTheme.colors.pybessPurple
                        : null
                    }
                    //remove the underline on hover
                    _hover={{
                      textDecoration: 'none',
                    }}
                  >
                    <Text
                      as='u'
                      style={{
                        textDecorationThickness:
                          link.display === activePage ? 2 : 1,
                        textUnderlineOffset: 3,
                      }}
                      fontWeight={
                        link.display === activePage ? 'bolder' : 'normal'
                      }
                    >
                      {link['display']}
                    </Text>
                  </Link>
                ))}
            </Flex>
          )}
        </Box>
      </Flex>
    </Box>
  );
}
