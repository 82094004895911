import { Box, Flex, Text } from "@chakra-ui/react";
import customTheme from "../styles/theme";
import { useContext } from "react";
import ViewportContext from "../styles/viewport-context";

type RevenueType = "ENERGY" | "REGULATION" | "CONTINGENCY" | "TOTAL";

const REVENUE_TYPE_TO_COLOR: Record<RevenueType, string> = {
  ENERGY: customTheme.colors.energy[500],
  REGULATION: customTheme.colors.regulation[500],
  CONTINGENCY: customTheme.colors.contingency[500],
  TOTAL: customTheme.colors.pybessPurple,
};

function nFormatter(num: number, digits: number) {
  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return Math.abs(num) >= item.value;
    });
  return item
    ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol
    : "0";
}

type RevenueCardProps = {
  type: RevenueType;
  total: number;
};

const TYPE_TO_ABBREV = {
  ENERGY: "Energy",
  REGULATION: "Reg.",
  CONTINGENCY: "Cont.",
  TOTAL: "Total",
};

export function RevenueCard(props: RevenueCardProps) {
  const viewport = useContext(ViewportContext);
  const isSmallScreen = ["mobile", "tablet"].includes(viewport.type);
  const { type, total } = props;
  return (
    <Box width={"100%"} borderRadius={10} bgColor={"white"}>
      <Flex h={"65px"} flexWrap={isSmallScreen ? "wrap" : undefined}>
        <hr
          style={{
            width: "0px",
            height: "calc(100% - 16px)",
            borderWidth: "2px",
            borderStyle: "solid",
            borderColor: REVENUE_TYPE_TO_COLOR[type],
            opacity: 1,
            margin: "5px",
            marginLeft: "10px",
            backgroundColor: REVENUE_TYPE_TO_COLOR[type],
          }}
        />
        <Box>
          <Text
            fontSize={["sm", "xl"]}
            paddingLeft={1}
            color={REVENUE_TYPE_TO_COLOR[type]}
            fontWeight={"semibold"}
            mb={0}
          >
            {isSmallScreen
              ? TYPE_TO_ABBREV[type]
              : `${type[0]}${type.slice(1).toLowerCase()}`}
          </Text>
          <Text size={["sm", "md"]} padding={1} fontWeight={"semibold"}>
            ${nFormatter(parseFloat(total.toPrecision(3)), 3)}
          </Text>
        </Box>
      </Flex>
    </Box>
  );
}
