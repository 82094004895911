import {
  Avatar,
  Badge,
  Box,
  Button,
  Center,
  Flex,
  Grid,
  GridItem,
  HStack,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure
} from "@chakra-ui/react";
import { InfoIcon, QuestionOutlineIcon } from "@chakra-ui/icons";
import { useContext, useEffect, useState } from "react";
import axios, { AxiosResponse } from "axios";
import { DateRangePicker, RangeKeyDict } from "react-date-range";
import { BatteryCharging, Calendar2Week } from "react-bootstrap-icons";
import { RevenueCard } from "./RevenueCard";
import ViewportContext from "../styles/viewport-context";
import customTheme from "../styles/theme";
import { dateStringToDate, formatDate } from "../utils";
import { RevenueChart } from "./RevenueChart";
import { BessInfoModal } from "./BessInfo";
import { ElementProps } from "./Dashboard";
import { useNavigate, useParams } from "react-router-dom";
import { APIRevenue, BessStation, VPP } from "../api/api-types";
import { NembessSpinner } from "./designSystem/NembessSpinner";
import { FCASBreakdownChart } from "./FCASBreakdownChart";

const APIHOST = process.env.REACT_APP_APIHOST;

type ViewportMap = {
  mobile: number;
  tablet: number;
  desktop: number;
  "desktop-lg": number;
};

type RevenueSummary = {
  regulation_revenue: number;
  contingency_revenue: number;
  net_energy_revenue: number;
};

const VIEWPORT_TYPE_TO_NUM_COLS: ViewportMap = {
  mobile: 4,
  tablet: 4,
  desktop: 4,
  "desktop-lg": 4,
};

function humanReadableDate(date: Date) {
  return date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "short",
    day: "numeric",
  });
}

function aggregateRevenue(revenueData: APIRevenue[]) {
  // Aggregate API response to get total values in period
  let aggregatedData: RevenueSummary = {
    regulation_revenue: 0,
    contingency_revenue: 0,
    net_energy_revenue: 0,
  };
  if (revenueData.length > 0) {
    aggregatedData = revenueData.reduce<RevenueSummary>(
      (a, b) => ({
        regulation_revenue: a.regulation_revenue + b.regulation_revenue,
        contingency_revenue: a.contingency_revenue + b.contingency_revenue,
        net_energy_revenue: a.net_energy_revenue + b.net_energy_revenue,
      }),
      aggregatedData
    );
  }
  return aggregatedData;
}

function datediff(first: Date, second: Date) {
  // Take the difference between the dates and divide by milliseconds per day.
  // Round to nearest whole number to deal with DST.
  return Math.round(
    (second.getTime() - first.getTime()) / (1000 * 60 * 60 * 24)
  );
}

type BreakPoints = {
  [key: number]: string;
};

function datesToAggregation(startDate: Date, endDate: Date) {
  const breakPoints: BreakPoints = {
    390: "day",
    3650: "month",
  };
  const dayDiff = datediff(startDate, endDate);

  let newAggregation = "day";
  for (const [key, value] of Object.entries(breakPoints)) {
    if (dayDiff < parseInt(key)) {
      newAggregation = value;
      break;
    }
  }
  return newAggregation;
}

async function fetchRevenue(
  stationId: number,
  startDate: Date,
  endDate: Date,
  aggregation: string
) {
  const params = {
    start_date: formatDate(startDate),
    end_date: formatDate(endDate),
    aggregation: aggregation,
  };

  try {
    const response: AxiosResponse<APIRevenue[]> = await axios.get(
      `${APIHOST}/power_stations/${stationId}/revenue`,
      {
        params: params,
        timeout: 10000,
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
}

type RevenueState = {
  selectedStationRevenue: APIRevenue[]; // May be of day or month intervals
  aggregatedRevenue: RevenueSummary; // Aggregated revenue to a single number for the dispalyed period
  datePickerStartDate: Date | null;
  datePickerEndDate: Date | null;
  startDate: Date | null;
  endDate: Date | null;
  range: string | null;
  aggregation: string;
  selectedStation: BessStation | VPP | null;
  datePickerExpanded: boolean;
  datePickerInteractions: number; // Hack to get datepicker to close
  hasData: boolean; // New BESS's may not have any data
  isLoaded: boolean;
};

const INITIAL_STATE: RevenueState = {
  selectedStationRevenue: [],
  aggregatedRevenue: {
    contingency_revenue: 0,
    regulation_revenue: 0,
    net_energy_revenue: 0,
  },
  datePickerStartDate: null,
  datePickerEndDate: null,
  startDate: null,
  endDate: null,
  range: "3m",
  aggregation: "day",
  selectedStation: null,
  isLoaded: false,
  datePickerExpanded: false,
  datePickerInteractions: 0,
  hasData: true,
};

export function Revenue(props: ElementProps) {
  const viewport = useContext(ViewportContext);
  const isSmallScreen = ["mobile", "tablet"].includes(viewport.type);

  const [state, setState] = useState<RevenueState>(INITIAL_STATE);

  const { id } = useParams<{ id: string }>();
  const stationId = id ? parseInt(id) : null;

  const { isOpen, onOpen, onClose } = useDisclosure();

  const { dashboardTechnology, stations } = props;

  const navigate = useNavigate();
  useEffect(() => {
    if (!stationId) {
      setState((state: RevenueState) => ({
        ...state,
        isLoaded: true,
        hasData: false,
      }));
    }
    const selectedStation = stations.find(
      (station: BessStation | VPP) => station.id === stationId
    );

    setState((state: RevenueState) => ({
      ...state,
      selectedStation: selectedStation || null,
      hasData: true,
    }));
  }, [stationId, stations]);

  useEffect(() => {
    async function fetchData(
      stationId: number,
      startDate: Date,
      endDate: Date
    ) {
      const aggregation = datesToAggregation(startDate, endDate);
      const bessRevenue = await fetchRevenue(
        stationId,
        startDate,
        endDate,
        aggregation
      );
      let aggregatedRevenue: RevenueSummary = aggregateRevenue(bessRevenue);

      setState((state: RevenueState) => ({
        ...state,
        selectedStationRevenue: bessRevenue,
        aggregation: aggregation,
        isLoaded: true,
        aggregatedRevenue: aggregatedRevenue,
        startDate: startDate,
        endDate: endDate,
        datePickerStartDate: startDate,
        datePickerEndDate: endDate,
      }));
    }
    if (state.selectedStation) {
      let startDate: Date | null = state.startDate;
      let endDate: Date | null = state.endDate;
      if (!state.startDate && !state.endDate) {
        const initialDateOffset = 24 * 60 * 60 * 1000 * 90; // 90 days
        if (props.endDate) {
          // initialize to 90 days before the latest date in DB
          startDate = new Date();
          startDate.setTime(props.endDate.getTime() - initialDateOffset);
          startDate.setDate(1);
          endDate = props.endDate;
        }
      }
      if (startDate && endDate) {
        fetchData(state.selectedStation.id, startDate, endDate);
      }
    } else {
      setState((state: RevenueState) => ({
        ...state,
        selectedStationRevenue: [],
        aggregatedRevenue: {
          contingency_revenue: 0,
          regulation_revenue: 0,
          net_energy_revenue: 0,
        },
        datePickerStartDate: state.startDate,
        datePickerEndDate: state.endDate,
      }));
    }
  }, [
    state.selectedStation,
    state.aggregation,
    state.endDate,
    state.startDate,
    props.endDate,
  ]);

  function onSelectStation(event: React.ChangeEvent<HTMLSelectElement>) {
    setState({ ...state, isLoaded: false });
    const stationId = parseInt(event.target.value);
    if (props.dashboardTechnology === "BATTERY") {
      navigate(`/batteries/revenue/${stationId}`);
    } else if (props.dashboardTechnology === "VPP") {
      navigate(`/vpps/revenue/${stationId}`);
    }
  }

  function handleRangeChange(selectedRange: "3m" | "1y" | "total") {
    if (!props.endDate || !state.selectedStation) {
      return;
    }

    setState({ ...state, isLoaded: false });
    let startDate: Date;
    if (selectedRange === "3m") {
      const dateOffset = 24 * 60 * 60 * 1000 * 90; // 90 days
      startDate = new Date();
      startDate.setTime(props.endDate.getTime() - dateOffset);
      startDate.setDate(1);
    } else if (selectedRange === "1y") {
      const dateOffset = 24 * 60 * 60 * 1000 * 365; // 365 days
      startDate = new Date();
      startDate.setTime(startDate.getTime() - dateOffset);
      startDate.setDate(1);
    } else {
      startDate = dateStringToDate(state.selectedStation.install_date);
    }
    const aggregation = datesToAggregation(startDate, props.endDate);
    setState({
      ...state,
      startDate: startDate,
      endDate: props.endDate,
      range: selectedRange,
      datePickerExpanded: false,
      aggregation: aggregation,
    });
  }

  function handleSelect(ranges: RangeKeyDict) {
    if (!ranges.selection.startDate || !ranges.selection.endDate) {
      return;
    }

    let datePickerInteractions = state.datePickerInteractions;
    datePickerInteractions++;
    if (datePickerInteractions < 2) {
      setState({
        ...state,
        datePickerInteractions: datePickerInteractions,
        datePickerStartDate: ranges.selection.startDate,
        datePickerEndDate: ranges.selection.endDate,
      });
    } else {
      setState({
        ...state,
        datePickerStartDate: ranges.selection.startDate,
        datePickerEndDate: ranges.selection.endDate,
        startDate: ranges.selection.startDate,
        endDate: ranges.selection.endDate,
        datePickerInteractions: 0,
        datePickerExpanded: false,
      });
    }
  }

  function toggleDatePicker(
    event: React.MouseEvent<HTMLDivElement> | React.MouseEvent<SVGElement>
  ) {
    setState({
      ...state,
      datePickerExpanded: !state.datePickerExpanded,
      range: null,
    });
  }

  function updateDates(startDate: Date, endDate: Date) {
    setState((state: RevenueState) => ({
      ...state,
      startDate: startDate,
      endDate: endDate,
      range: null,
    }));
  }

  const selectionRange = {
    startDate: state.datePickerStartDate || undefined,
    endDate: state.datePickerEndDate || undefined,
    key: "selection",
  };

  return (
    <Box>
      <Flex alignItems={"center"} gap={1}>
        <Avatar
          src={
            dashboardTechnology === "BATTERY"
              ? state.selectedStation?.image
              : `https://pybess-images.s3.ap-southeast-2.amazonaws.com/${state.selectedStation?.id}-avatar.png`
          }
          ml={2}
          mr={2}
          size={isSmallScreen ? "md" : "xl"}
          icon={<BatteryCharging />}
          bg={"gray.200"}
        />
        <Box>
          <Select
            placeholder="Select option"
            onChange={onSelectStation}
            value={stationId || ""}
            fontSize={isSmallScreen ? "sm" : "md"}
            backgroundColor={"white"}
          >
            {stations.map((station: VPP | BessStation) => (
              <option key={station.id} value={station.id}>
                {station.station_name}
              </option>
            ))}
          </Select>
        </Box>
        <Box>
          {dashboardTechnology === "BATTERY" && (
            <InfoIcon
              color={customTheme.colors.pybessPurple}
              onClick={onOpen}
              w={5}
              h={5}
            />
          )}
        </Box>
        <Box>
          {state.selectedStation?.current_power_capacity && (
            <Badge colorScheme={"purple"} size={"xl"} marginLeft={2}>
              {state.selectedStation?.current_power_capacity}
              MW
            </Badge>
          )}
          {dashboardTechnology === "BATTERY" && (
            <Badge colorScheme={"purple"} size={"xl"} marginLeft={2}>
              {!state.selectedStation
                ? null
                : "current_storage_capacity" in state.selectedStation
                ? state.selectedStation.current_storage_capacity
                : null}
              MWh
            </Badge>
          )}
        </Box>

        <Modal onClose={onClose} isOpen={isOpen} size={"xl"} isCentered>
          <ModalOverlay />
          <ModalContent maxH="60vh" overflowY="auto">
            <ModalHeader>
              {" "}
              {state.selectedStation ? state.selectedStation?.display_name : ""}
            </ModalHeader>
            <ModalCloseButton />
            {state.selectedStation && (
              <BessInfoModal selectedBessStation={state.selectedStation} />
            )}
            <ModalFooter>
              <Button onClick={onClose}>Close</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Flex>

      <Grid
        templateColumns={`repeat(${
          VIEWPORT_TYPE_TO_NUM_COLS[viewport.type]
        }, 1fr)`}
        gap={4}
        paddingTop={4}
        alignContent={"center"}
      >
        <GridItem
          colSpan={4}
          rowSpan={4}
          backgroundColor={"white"}
          borderRadius={10}
        >
          <Grid>
            <GridItem colSpan={1}>
              <HStack spacing={"5px"} padding={2}>
                <Button
                  size={isSmallScreen ? "xs" : "sm"}
                  isActive={state.range === "3m"}
                  onClick={(event) => handleRangeChange("3m")}
                  _focus={undefined}
                  _active={{
                    color: "white",
                    bgColor: customTheme.colors.pybessPurple,
                    borderColor: "white",
                  }}
                  variant={"outline"}
                >
                  3M
                </Button>
                <Button
                  size={isSmallScreen ? "xs" : "sm"}
                  isActive={state.range === "1y"}
                  onClick={(event) => handleRangeChange("1y")}
                  _focus={undefined}
                  _active={{
                    color: "white",
                    bgColor: customTheme.colors.pybessPurple,
                    borderColor: "white",
                  }}
                  variant={"outline"}
                >
                  1Y
                </Button>
                <Button
                  size={isSmallScreen ? "xs" : "sm"}
                  isActive={state.range === "total"}
                  onClick={(event) => handleRangeChange("total")}
                  _focus={undefined}
                  _active={{
                    color: "white",
                    bgColor: customTheme.colors.pybessPurple,
                    borderColor: "white",
                  }}
                  variant={"outline"}
                >
                  Total
                </Button>
              </HStack>
            </GridItem>
            <GridItem colSpan={1} alignSelf={"center"}>
              {!isSmallScreen && (
                <Flex alignItems={"center"}>
                  <QuestionOutlineIcon color={"purple"} />
                  <Text pl={1} pt={1} mb={0} fontSize={"sm"}>
                    {state.aggregation === "month"
                      ? "Click on each revenue bar to drill down by date."
                      : "Click on each revenue bar to drill down into BESS Operations."}
                  </Text>
                </Flex>
              )}
            </GridItem>
            <GridItem colSpan={2} justifySelf={"end"} alignSelf={"center"}>
              <Box
                padding={2}
                justifySelf={"end"}
                onClick={toggleDatePicker}
                cursor={"pointer"}
                pr={4}
              >
                <Flex alignItems={"center"}>
                  <Box paddingRight={1}>
                    <Center>
                      <Text fontSize={isSmallScreen ? "2xs" : "sm"} mb={0}>
                        {state.datePickerStartDate &&
                          humanReadableDate(state.datePickerStartDate)}{" "}
                        -{" "}
                        {state.datePickerEndDate &&
                          humanReadableDate(state.datePickerEndDate)}
                      </Text>
                    </Center>
                  </Box>
                  <Box padding={1} pt={0}>
                    <Calendar2Week
                      size={isSmallScreen ? 14 : 18}
                      onClick={toggleDatePicker}
                    />
                  </Box>
                </Flex>
              </Box>
            </GridItem>
            <GridItem
              gridColumnStart={1}
              colSpan={4}
              gridRowStart={2}
              gridRowEnd={3}
              zIndex={2}
              justifySelf={"end"}
            >
              {state.datePickerExpanded && (
                <DateRangePicker
                  ranges={[selectionRange]}
                  onChange={handleSelect}
                  staticRanges={[]}
                  inputRanges={[]}
                  moveRangeOnFirstSelection={false}
                  retainEndDateOnFirstSelection={false}
                  calendarFocus={"forwards"}
                  showDateDisplay={false}
                />
              )}
            </GridItem>
            <GridItem
              colSpan={4}
              rowSpan={3}
              gridRowStart={2}
              gridColumnStart={1}
            >
              {state.isLoaded &&
              state.hasData &&
              state.startDate &&
              state.endDate ? (
                <RevenueChart
                  aggregation={state.aggregation}
                  data={state.selectedStationRevenue}
                  bessStationId={stationId}
                  updateDates={updateDates}
                  startDate={state.startDate}
                  endDate={state.endDate}
                  dashboardTechnology={dashboardTechnology}
                />
              ) : !id ? (
                <Box height={"360px"}></Box>
              ) : (
                <Box height={"360px"}>
                  <Center pt={"150px"}>
                    <NembessSpinner />
                    <Text>Loading</Text>
                  </Center>
                </Box>
              )}
            </GridItem>
          </Grid>
        </GridItem>

        <GridItem rowSpan={4} colSpan={isSmallScreen ? 4 : 4}>
          {isSmallScreen ? (
            <Flex>
              <Box width={"33%"}>
                <RevenueCard
                  type={"CONTINGENCY"}
                  total={state.aggregatedRevenue.contingency_revenue}
                />
              </Box>
              <Box width={"33%"}>
                <RevenueCard
                  type={"REGULATION"}
                  total={state.aggregatedRevenue.regulation_revenue}
                />
              </Box>
              <Box width={"33%"}>
                <RevenueCard
                  type={"ENERGY"}
                  total={state.aggregatedRevenue.net_energy_revenue}
                />
              </Box>
            </Flex>
          ) : (
            <Flex justifyContent={"space-around"}>
              <RevenueCard
                type={"CONTINGENCY"}
                total={state.aggregatedRevenue.contingency_revenue}
              />
              <RevenueCard
                type={"REGULATION"}
                total={state.aggregatedRevenue.regulation_revenue}
              />
              <RevenueCard
                type={"ENERGY"}
                total={state.aggregatedRevenue.net_energy_revenue}
              />
              <RevenueCard
                type={"TOTAL"}
                total={
                  state.aggregatedRevenue.regulation_revenue +
                  state.aggregatedRevenue.contingency_revenue +
                  state.aggregatedRevenue.net_energy_revenue
                }
              />
            </Flex>
          )}
        </GridItem>
        <GridItem
          rowSpan={4}
          colSpan={isSmallScreen ? 4 : 4}
          backgroundColor={"white"}
          borderRadius={10}
        >
          <Tabs variant={"enclosed"} colorScheme={"purple"} isLazy>
            <TabList>
              <Tab fontSize={["sm", "md"]}>Contingency Breakdown</Tab>
              {dashboardTechnology === "BATTERY" && (
                <Tab fontSize={["sm", "md"]}>Regulation Breakdown</Tab>
              )}
            </TabList>
            <TabPanels border={"none"}>
              <TabPanel>
                <FCASBreakdownChart
                  data={state.selectedStationRevenue}
                  startDate={state.startDate}
                  endDate={state.endDate}
                  aggregation={state.aggregation}
                  divStyle={{ position: "relative", bottom: "20px" }}
                  fcasType={"CONTINGENCY"}
                />
              </TabPanel>
              {dashboardTechnology === "BATTERY" && (
                <TabPanel>
                  <FCASBreakdownChart
                    data={state.selectedStationRevenue}
                    startDate={state.startDate}
                    endDate={state.endDate}
                    aggregation={state.aggregation}
                    divStyle={{ position: "relative", bottom: "20px" }}
                    fcasType={"REGULATION"}
                  />
                </TabPanel>
              )}
            </TabPanels>
          </Tabs>
        </GridItem>
      </Grid>
    </Box>
  );
}
