export function formatDate(date: Date) {
  let d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
}

export function dateStringToDate(dateString: string) {
  const dateParts = dateString.split("T");
  const datePart = dateParts[0];
  const timePart = dateParts[1];
  const year = parseInt(datePart.split("-")[0]);
  const month = parseInt(datePart.split("-")[1]);
  const day = parseInt(datePart.split("-")[2]);
  const hour = timePart ? parseInt(timePart.split(":")[0]) : 0;
  const minute = timePart ? parseInt(timePart.split(":")[1]) : 0;
  const seconds = timePart ? parseInt(timePart.split(":")[2]) : 0;
  const dt = new Date(year, month - 1, day, hour, minute, seconds);
  return dt;
}

export function daysBetweenTwoDates(start: Date, end: Date) {
  // calculate the difference between start and end
  const timeDiff = end.getTime() - start.getTime();
  return timeDiff / (1000 * 3600 * 24);
}
