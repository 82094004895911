import React, { useContext, useLayoutEffect, useRef } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import { useTheme } from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import { XYChart } from "@amcharts/amcharts4/charts";
import ViewportContext from "../styles/viewport-context";
import { APIRevenue } from "../api/api-types";
import am4themes_material from "@amcharts/amcharts4/themes/material";

type FCASBreakdownChartProps = {
  data: APIRevenue[];
  startDate: Date | null;
  endDate: Date | null;
  aggregation: string;
  divStyle?: React.CSSProperties;
  fcasType: "REGULATION" | "CONTINGENCY";
};

const CONTINGENCY_MARKETS = [
  { dataKey: "raise1secrev", label: "Raise 1Sec" },
  { dataKey: "raise6secrev", label: "Raise 6Sec" },
  { dataKey: "raise60secrev", label: "Raise 60Sec" },
  { dataKey: "raise5minrev", label: "Raise 5Min" },
  { dataKey: "lower1secrev", label: "Lower 1Sec" },
  { dataKey: "lower6secrev", label: "Lower 6Sec" },
  { dataKey: "lower60secrev", label: "Lower 60Sec" },
  { dataKey: "lower5minrev", label: "Lower 5Min" },
];
const REGULATION_MARKETS = [
  { dataKey: "raiseregrev", label: "Raise" },
  { dataKey: "lowerregrev", label: "Lower" },
];

export function FCASBreakdownChart(props: FCASBreakdownChartProps) {
  const viewport = useContext(ViewportContext);
  const isSmallScreen = ["mobile", "tablet"].includes(viewport.type);
  const chartRef = useRef<XYChart | null>(null);

  const { data, startDate, endDate, aggregation, divStyle, fcasType } = props;
  useTheme(am4themes_material);
  useLayoutEffect(() => {
    if (!startDate || !endDate || !data) {
      return;
    }
    function initialiseChart(
      data: any[],
      startDate: Date,
      endDate: Date,
      aggregation: string
    ) {
      let chart = am4core.create("fcas-breakdown-chart", am4charts.XYChart);
      chart.exporting.menu = new am4core.ExportMenu();
      chart.zoomOutButton.align = "left";
      chart.zoomOutButton.valign = "top";
      chart.zoomOutButton.background.fill = am4core.color("#25283D");
      chart.zoomOutButton.icon.stroke = am4core.color("#EFD9CE");
      chart.zoomOutButton.icon.strokeWidth = 2;
      // @ts-ignore
      chart.zoomOutButton.background.states.getKey("hover").properties.fill =
        am4core.color("#8e2eb2");

      chart.paddingRight = 20;
      chart.paddingTop = 50;
      chart.numberFormatter.numberFormat = "$#,###.##a";
      chart.numberFormatter.bigNumberPrefixes = [
        { number: 1e3, suffix: "k" },
        { number: 1e6, suffix: "m" },
        { number: 1e9, suffix: "b" },
      ];
      chart.data = data;

      let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
      dateAxis.renderer.grid.template.location = 0;
      dateAxis.renderer.grid.template.disabled = true;
      dateAxis.min = startDate.getTime();
      dateAxis.max = endDate.getTime();
      dateAxis.tooltipDateFormat = "yyyy-MM-dd";

      let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      if (valueAxis.tooltip) {
        valueAxis.tooltip.disabled = true;
      }
      valueAxis.renderer.minWidth = 0;
      valueAxis.renderer.grid.template.disabled = false;
      if (isSmallScreen) {
        valueAxis.renderer.labels.template.fontSize = 10;
        valueAxis.renderer.labels.template.paddingLeft = 0;
      }
      const marketList =
        fcasType === "REGULATION" ? REGULATION_MARKETS : CONTINGENCY_MARKETS;
      marketList.forEach((market, index) => {
        let series = chart.series.push(new am4charts.ColumnSeries());
        if (fcasType === "REGULATION") {
          series.fill = chart.colors.getIndex(index * 5);
        }
        series.stacked = true;
        series.dataFields.dateX = "start_date";
        series.dataFields.valueY = market.dataKey;
        series.name = market.label;
        series.tooltipText = `${market.label}: {valueY.value}`;
        series.fillOpacity = 0.9;
        // contingencySeries.fill = REVENUE_TYPE_TO_COLOR["contingency_revenue"];
        series.stroke = am4core.color("white", 0); //REVENUE_TYPE_TO_COLOR["contingency_revenue"];
        series.dummyData = { radius: 8 };
      });

      chart.cursor = new am4charts.XYCursor();
      chart.cursor.lineX.disabled = true;
      chart.cursor.lineY.disabled = true;
      chart.legend = new am4charts.Legend();
      chart.legend.position = "bottom";
      chart.legend.labels.template.fontSize = `${isSmallScreen ? 8 : 14}`;
      // Give the legend a margin to push it away from the chart
      chart.legend.paddingTop = isSmallScreen ? 0 : 20;
      chart.legend.paddingBottom = isSmallScreen ? 20 : 0;
      // chart.legend.contentAlign = "right";
      let markerTemplate = chart.legend.markers.template;
      markerTemplate.width = isSmallScreen ? 10 : 20;
      markerTemplate.height = isSmallScreen ? 10 : 20;

      chart.legend.itemContainers.template.padding(3, 0, 3, 0);
      chart.legend.itemContainers.template.margin(0, 1, 0, 1);

      return chart;
    }

    if (!chartRef.current) {
      const chart = initialiseChart(data, startDate, endDate, aggregation);
      chartRef.current = chart;
    } else {
      chartRef.current.data = data;
      // @ts-ignore
      const dateAxis = chartRef.current._xAxes._values[0];
      dateAxis.max = endDate.getTime();
      dateAxis.min = startDate.getTime();
      chartRef.current.invalidateData();
    }
    // @ts-ignore
    const contingencySeries = chartRef.current._series._values[0];
    if (aggregation.toUpperCase() === "MONTH") {
      contingencySeries.columns.template.propertyFields.url = null;
    } else {
      contingencySeries.columns.template.propertyFields.url = "url";
    }
    if (isSmallScreen) {
      // @ts-ignore
      chartRef.current._yAxes._values[0].renderer.labels.template.fontSize = 10;
      // @ts-ignore
      chartRef.current._yAxes._values[0].renderer.labels.template.paddingLeft = 0;
      let markerTemplate = chartRef.current.legend.markers.template;
      if (markerTemplate) {
        markerTemplate.width = 10;
        markerTemplate.height = 10;
      }
    } else {
      // @ts-ignore
      chartRef.current._yAxes._values[0].renderer.labels.template.fontSize =
        null;
      // @ts-ignore
      chartRef.current._yAxes._values[0].renderer.labels.template.paddingLeft =
        null;
      let markerTemplate = chartRef.current.legend.markers.template;
      if (markerTemplate) {
        markerTemplate.width = 20;
        markerTemplate.height = 20;
      }
    }

    return () => {
      chartRef.current && chartRef.current.dispose();
      chartRef.current = null;
    };
  }, [aggregation, data, endDate, fcasType, isSmallScreen, startDate]);
  return (
    <div
      id={"fcas-breakdown-chart"}
      style={{ ...divStyle, width: "100%", height: "400px" }}
    />
  );
}
