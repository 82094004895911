import { Box, Center, Link, Stack, Text } from "@chakra-ui/react";
import { X } from "react-bootstrap-icons";
import NEMLogo from "../logo3.js";
import { useNavigate } from "react-router-dom";
import { DashboardLink } from "./Dashboard";

export const SIDEBAR_WIDTH = 140;

type SidebarProps = {
  dashboardLinks: DashboardLink[];
  selectedPage: string;
  toggleSidebar: () => void;
  showSideBar: boolean;
};

export function Sidebar(props: SidebarProps) {
  const { dashboardLinks, selectedPage, toggleSidebar, showSideBar } = props;
  const history = useNavigate();
  return (
    <Box
      width={SIDEBAR_WIDTH}
      height="100vh"
      boxShadow="0 4px 4px 0 rgba(0, 0, 0, 0.2)" //, 0 6px 20px 0 rgba(0, 0, 0, 0.19)"
      marginTop={showSideBar ? "0px" : "80px"}
      position="fixed"
      left={0}
      top={0}
      zIndex={showSideBar ? 100 : 5}
      backgroundColor={"white"}
    >
      {showSideBar && (
        <Box width={"100%"} position={"relative"}>
          <Box position={"absolute"} top={"0px"} right={"0px"}>
            <X size={42} onClick={toggleSidebar} />
          </Box>
          <Link href={"/"}>
            <Center paddingTop={10}>
              <NEMLogo
                width={`${SIDEBAR_WIDTH / 2}px`}
                height={`${SIDEBAR_WIDTH / 2}px`}
              />
            </Center>
          </Link>
        </Box>
      )}
      <Stack
        direction="column"
        spacing={0}
        marginTop={showSideBar ? "10px" : "0px"}
      >
        {dashboardLinks.map((link, index) => {
          return (
            <Link
              onClick={() => {
                history(link.linkRoute);
              }}
              key={index}
              _focus={undefined}
            >
              <Box paddingTop={5} paddingBottom={5}>
                <Box>
                  <Center
                    backgroundColor={
                      link.linkText === selectedPage
                        ? "rgba(142, 46, 178, 0.2)"
                        : undefined
                    }
                    borderRadius={"0px 10px 10px 0px"}
                  >
                    <Text
                      fontWeight={
                        link.linkText === selectedPage ? "semibold" : undefined
                      }
                      mb={0}
                      pt={1}
                      pb={1}
                    >
                      {link.linkText}
                    </Text>
                  </Center>
                </Box>
              </Box>

              {/*<Divider orientation={"horizontal"} color={"white"} />*/}
            </Link>
          );
        })}
      </Stack>
    </Box>
  );
}
