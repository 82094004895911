import {
  AbsoluteCenter,
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Center,
  Heading,
  Image,
  Link,
  Text
} from "@chakra-ui/react";
import { renderOptions } from "../lib/contentful";
import { Navbar } from "./Navbar";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import customTheme from "../styles/theme";
import { useEffect, useState } from "react";
import { NembessSpinner } from "./designSystem/NembessSpinner";

const query = `
{
  faqCollection {
    items {
      question
      answer {
        json
       }
    }
  }
}
`;


const ACCESS_TOKEN = "MKmYJuh6nJjqKgeewXDjJ0vp2ft1yEJmg90d-D4QgpA";
const SPACE_ID = "c11smyglnkkv";

export function FAQ(props) {

  const [faqs, setFaqs] = useState([]);

  useEffect(() => {
    window
      .fetch(`https://graphql.contentful.com/content/v1/spaces/${SPACE_ID}/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // Authenticate the request
          Authorization: `Bearer ${ACCESS_TOKEN}`,
        },
        // send the GraphQL query
        body: JSON.stringify({ query }),
      })
      .then((response) => response.json())
      .then(({ data, errors }) => {
        if (errors) {
          console.error(errors);
        }

        // rerender the entire component with new data
        setFaqs(data.faqCollection.items);
      });
  }, []);

  return (
    <Box>
      {faqs.length === 0 && (<Box position={"absolute"} w={"100vw"} h={"100vh"} top={0} left={0}><AbsoluteCenter><NembessSpinner /></AbsoluteCenter></Box>) }
      <Navbar activePage={"FAQ"} />
      <Box marginTop="80px">
        <Center>
          <Heading padding={4} fontSize={["md", "2xl"]}>
            Join The Discussion
          </Heading>
        </Center>
        <Center>
          <Text padding={4}>
            Have an idea for a new feature, want to collaborate, or report a
            bug? Let us know on our{" "}
            <Link
              href={"https://github.com/dtam95/nembess/discussions"}
              target={"_blank"}
              style={{ color: customTheme.colors.pybessPurple }}
            >
              {" "}
              GitHub Discussions Page.{" "}
            </Link>{" "}
          </Text>
        </Center>
        <Center>
          <Link
            href={"https://github.com/dtam95/nembess/discussions"}
            target={"_blank"}
          >
            <Image src={"github.png"} width={"20vw"} />
          </Link>
        </Center>
        <Heading padding={4} pl={6} pt={8} fontSize={["md", "2xl"]}>
          Frequently Asked Questions
        </Heading>
        <Box>
          <Accordion allowToggle={true} onChange={props.updateExpandedIndex}>
            {faqs.map((faq, i) => (
              <AccordionItem
                marginLeft={[1, 10]}
                marginRight={[1, 10]}
                key={i}
                marginTop={4}
              >
                <AccordionButton
                  backgroundColor={"white"}
                  _focus={null}
                  _expanded={{
                    color: customTheme.colors.pybessPurple,
                  }}
                  _hover={null}
                  h={"55px"}
                  padding={0}
                  borderRadius={"10px"}
                >
                  <hr
                    style={{
                      width: "0px",
                      height: "calc(100% - 16px)",
                      borderWidth: "2px",
                      borderStyle: "solid",
                      borderColor:
                        props.expandedIndex === i
                          ? customTheme.colors.pybessPurple
                          : customTheme.colors.backgroundGray,
                      opacity: 1,
                      marginLeft: "10px",
                      backgroundColor:
                        props.expandedIndex === i
                          ? customTheme.colors.pybessPurple
                          : customTheme.colors.backgroundGray,
                    }}
                  />
                  <Box flex="1" textAlign="left" paddingLeft={2}>
                    <Heading size={"sm"} fontWeight={"regular"}>
                      {faq.question}
                    </Heading>
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel backgroundColor={"white"} marginBottom={4}>
                  <Box
                    borderTop={"1px solid rgb(220, 220, 220)"}
                    paddingTop={4}
                  >
                    <>
                      {documentToReactComponents(
                        faq.answer.json,
                        renderOptions
                      )}
                    </>
                  </Box>
                </AccordionPanel>
              </AccordionItem>
            ))}
          </Accordion>
        </Box>
      </Box>
    </Box>
  );
}
