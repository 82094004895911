import React from 'react';
import './batteryLoaderStyles.scss';
import { Center, Flex, Progress, Text } from '@chakra-ui/react';
import { NembessSpinner } from '../designSystem/NembessSpinner';

export function BatteryLoader(props: { progress: number }) {

  const progress = props.progress;
  return (<div id='wrapper'>
    {/*<div className={'loader'} />*/}
    <Center pb={6}><NembessSpinner /></Center>

    {/*<div className='loading-bar'>*/}
    {/*  <div className='progress-bar'></div>*/}
    {/*</div>*/}
    <Progress hasStripe colorScheme={'purple'} value={progress} />
    <Flex justifyContent={'space-between'}><Text
      fontFamily={'Sans-serif'}>{progress < 100 ? 'Simulating' : 'Complete'}</Text><Text>{progress}%</Text></Flex>

    {/*<div className='status'>*/}
    {/*  <div className='state' />*/}
    {/*  <div className='percentage' />*/}
    {/*</div>*/}
  </div>);
}