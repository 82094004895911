import React, { useContext, useLayoutEffect, useRef } from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import { XYChart } from '@amcharts/amcharts4/charts';
import ViewportContext from '../styles/viewport-context';
import { FCASType } from './Operation';

export type OperationalChartData = {
  settlementdate: Date;
  lowerreg_mw: number;
  raisereg_mw: number;
  avg_raise_contingency_mw: number;
  avg_lower_contingency_mw: number;
  charge_mw: number;
  discharge_mw: number;
};

type OperationalChartProps = {
  fcasType: FCASType;
  data: OperationalChartData[];
  isLoaded: boolean;
  setIsLoaded: () => void;
};

export function OperationalChart(props: OperationalChartProps) {
  const chartRef = useRef<XYChart | null>(null);
  const viewport = useContext(ViewportContext);
  const isSmallScreen = ['mobile', 'tablet'].includes(viewport.type);

  const { fcasType, data, setIsLoaded } = props;

  function updateChartBasedOnFcasType(
    fcasType: 'REGULATION' | 'CONTINGENCY',
    chart: XYChart,
  ) {
    // @ts-ignore
    const lowerRegSeries = chart._series._values[2];
    // @ts-ignore
    const raiseRegSeries = chart._series._values[3];
    // @ts-ignore
    const lowerContingencySeries = chart._series._values[4];
    // @ts-ignore
    const raiseContingencySeries = chart._series._values[5];
    if (fcasType === FCASType.Regulation) {
      lowerContingencySeries.hidden = true;
      raiseContingencySeries.hidden = true;
      lowerContingencySeries.hiddenInLegend = true;
      raiseContingencySeries.hiddenInLegend = true;
      lowerContingencySeries.hide();
      raiseContingencySeries.hide();

      lowerRegSeries.hidden = false;
      raiseRegSeries.hidden = false;
      lowerRegSeries.hiddenInLegend = false;
      raiseRegSeries.hiddenInLegend = false;
      raiseRegSeries.show();
      lowerRegSeries.show();
    } else {
      lowerContingencySeries.hidden = false;
      raiseContingencySeries.hidden = false;
      lowerContingencySeries.hiddenInLegend = false;
      raiseContingencySeries.hiddenInLegend = false;
      lowerContingencySeries.show();
      raiseContingencySeries.show();

      lowerRegSeries.hidden = true;
      raiseRegSeries.hidden = true;
      lowerRegSeries.hiddenInLegend = true;
      raiseRegSeries.hiddenInLegend = true;
      raiseRegSeries.hide();
      lowerRegSeries.hide();
    }
  }

  // Perform initial chart setup with empty dependency array, to delay the cleanup function until the user moves away from the page
  useLayoutEffect(() => {
    let chart = am4core.create('operational-chartdiv', am4charts.XYChart);
    chart.exporting.menu = new am4core.ExportMenu();
    chart.zoomOutButton.align = 'left';
    chart.zoomOutButton.valign = 'top';
    chart.zoomOutButton.background.fill = am4core.color('#25283D');
    chart.zoomOutButton.icon.stroke = am4core.color('#EFD9CE');
    chart.zoomOutButton.icon.strokeWidth = 2;
    // @ts-ignore
    chart.zoomOutButton.background.states.getKey('hover').properties.fill =
      am4core.color('#8e2eb2');

    let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.grid.template.disabled = true;
    //dateAxis.renderer.minGridDistance = 30;
    dateAxis.dateFormats.setKey('hour', 'H');
    dateAxis.periodChangeDateFormats.setKey('hour', 'H');
    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.renderer.grid.template.disabled = false;

    dateAxis.title.text = 'Time of Day';

    let chargeSeries = chart.series.push(new am4charts.LineSeries());
    chargeSeries.dataFields.valueY = 'charge_mw';
    chargeSeries.dataFields.dateX = 'settlementdate';
    chargeSeries.stroke = am4core.color('#71D5E4');
    if (chargeSeries.tooltip) {
      chargeSeries.tooltip.getFillFromObject = false;
      chargeSeries.tooltip.background.fill = am4core.color('#71D5E4');
    }

    chargeSeries.strokeWidth = 1;
    chargeSeries.tooltipText = '{value}';
    chargeSeries.name = 'Charging';
    chargeSeries.tooltipText = 'Charging: [bold]{valueY}[/] MW';

    let dischargeSeries = chart.series.push(new am4charts.LineSeries());
    dischargeSeries.dataFields.valueY = 'discharge_mw';
    dischargeSeries.dataFields.dateX = 'settlementdate';
    dischargeSeries.stroke = am4core.color('#C55FFC');
    if (dischargeSeries.tooltip) {
      dischargeSeries.tooltip.getFillFromObject = false;
      dischargeSeries.tooltip.background.fill = am4core.color('#C55FFC');
    }

    dischargeSeries.strokeWidth = 1;
    dischargeSeries.tooltipText = '{value}';
    dischargeSeries.name = 'Discharging';
    dischargeSeries.tooltipText = 'Discharging: [bold]{valueY}[/] MW';

    let lowerRegSeries = chart.series.push(new am4charts.ColumnSeries());
    lowerRegSeries.dataFields.valueY = 'lowerreg_mw';
    lowerRegSeries.dataFields.dateX = 'settlementdate';
    lowerRegSeries.name = 'Lower Reg';
    lowerRegSeries.fill = am4core.color('#40B0DF');
    lowerRegSeries.fillOpacity = 0.1;
    lowerRegSeries.strokeWidth = 0;
    lowerRegSeries.columns.template.width = am4core.percent(100);
    lowerRegSeries.stacked = true;
    if (lowerRegSeries.tooltip) {
      lowerRegSeries.tooltip.background.fillOpacity = 0.3;
    }

    lowerRegSeries.tooltipText =
      'Lower Regulation Enablement: [bold]{valueY}[/] MW';

    let raiseRegSeries = chart.series.push(new am4charts.ColumnSeries());
    raiseRegSeries.dataFields.valueY = 'raisereg_mw';
    raiseRegSeries.dataFields.dateX = 'settlementdate';
    raiseRegSeries.name = 'Raise Reg';
    raiseRegSeries.fillOpacity = 0.1;
    raiseRegSeries.fill = am4core.color('#a614ff');
    raiseRegSeries.strokeWidth = 0;
    raiseRegSeries.columns.template.width = am4core.percent(100);
    raiseRegSeries.stacked = true;
    if (raiseRegSeries.tooltip) {
      raiseRegSeries.tooltip.background.fillOpacity = 0.3;
    }

    raiseRegSeries.tooltipText =
      'Raise Regulation Enablement: [bold]{valueY}[/] MW';

    let lowerContingencySeries = chart.series.push(
      new am4charts.ColumnSeries(),
    );
    lowerContingencySeries.dataFields.valueY = 'avg_lower_contingency_mw';
    lowerContingencySeries.dataFields.dateX = 'settlementdate';
    lowerContingencySeries.name = 'Lower Contingency';
    lowerContingencySeries.fillOpacity = 0.1;
    lowerContingencySeries.fill = am4core.color('#40B0DF');
    lowerContingencySeries.strokeWidth = 0;
    lowerContingencySeries.columns.template.width = am4core.percent(100);
    lowerContingencySeries.stacked = true;
    if (lowerContingencySeries.tooltip) {
      lowerContingencySeries.tooltip.background.fillOpacity = 0.3;
    }

    lowerContingencySeries.tooltipText =
      'Lower Contingency Enablement: [bold]{valueY}[/] MW';

    let raiseContingencySeries = chart.series.push(
      new am4charts.ColumnSeries(),
    );
    raiseContingencySeries.dataFields.valueY = 'avg_raise_contingency_mw';
    raiseContingencySeries.dataFields.dateX = 'settlementdate';
    raiseContingencySeries.name = 'Raise Contingency';
    raiseContingencySeries.fillOpacity = 0.1;
    raiseContingencySeries.fill = am4core.color('#a614ff');
    raiseContingencySeries.strokeWidth = 0;
    raiseContingencySeries.columns.template.width = am4core.percent(100);
    raiseContingencySeries.stacked = true;
    if (raiseContingencySeries.tooltip) {
      raiseContingencySeries.tooltip.background.fillOpacity = 0.3;
    }

    raiseContingencySeries.tooltipText =
      'Raise Contingency Enablement: [bold]{valueY}[/] MW';

    updateChartBasedOnFcasType(fcasType, chart);

    chart.legend = new am4charts.Legend();
    chart.legend.position = 'bottom';
    chart.legend.labels.template.fontSize = `${isSmallScreen ? 8 : 14}`;
    chart.legend.itemContainers.template.paddingLeft = 0;
    chart.legend.layout = 'horizontal';
    chart.legend.labels.template.paddingRight = 0;
    chart.legend.labels.template.paddingLeft = 0;
    chart.legend.itemContainers.template.padding(3, 0, 3, 0);
    chart.legend.itemContainers.template.margin(0, 2, 0, 2);
    let markerTemplate = chart.legend.markers.template;
    markerTemplate.strokeWidth = 3;
    chart.cursor = new am4charts.XYCursor();
    chart.cursor.lineY.disabled = true;
    chart.cursor.lineX.disabled = true;

    if (chart.preloader) {
      chart.preloader.disabled = true;
    }

    valueAxis.cursorTooltipEnabled = false;

    if (isSmallScreen) {
      valueAxis.renderer.labels.template.fontSize = 10;
      valueAxis.renderer.labels.template.paddingLeft = 0;
      dateAxis.renderer.minGridDistance = 30;
    } else {
      dateAxis.renderer.minGridDistance = 70;
    }
    chart.events.on('ready', setIsLoaded);
    chart.events.on('dataitemsvalidated', setIsLoaded);
    chartRef.current = chart;
    return () => {
      if (chartRef.current) {
        chartRef.current.dispose();
        chartRef.current = null;
      }
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useLayoutEffect(() => {
    if (chartRef.current) {
      chartRef.current.data = data;
      chartRef.current.invalidateData();
    }
  }, [data, isSmallScreen]);

  useLayoutEffect(() => {
    if (chartRef.current) {
      updateChartBasedOnFcasType(fcasType, chartRef.current);
    }
  }, [fcasType]);

  return (
    <>
      <div
        id='operational-chartdiv'
        style={{ width: '100%', height: '400px' }}
      />
      <div id={'legenddiv'} />
    </>
  );
}
