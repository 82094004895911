import { Badge, Box, Center, Heading, Image, Stat, StatGroup, StatLabel, StatNumber } from '@chakra-ui/react';

import { NumericFormat } from 'react-number-format';
import { ReactEventHandler, useEffect, useRef } from 'react';

type SummaryCardProps = {
  revenue: number | undefined;
  imageUrl: string;
  bessName: string;
  powerCapacity: number;
  storageCapacity?: number | null;
  incrementImageLoad: () => void;
};

export function SummaryCard(props: SummaryCardProps) {
  const { revenue, imageUrl, bessName, powerCapacity, incrementImageLoad } = props;
  const onLoad: ReactEventHandler<HTMLDivElement> = (event) => {
    incrementImageLoad();
  };
  const imageRef = useRef<HTMLImageElement>(null);
  useEffect(() => {
    if (imageRef.current?.complete) {
      incrementImageLoad();
    }
  }, []);
  let image;
  if (revenue && revenue > 0) {
    image = (
      <Image
        ref={imageRef}
        src={imageUrl}
        alignSelf={'center'}
        borderTopRadius={20}
        onLoad={onLoad}
        minWidth={'100%'}
      />
    );
  } else {
    image = (
      <Box position={'relative'}>
        <Image
          ref={imageRef}
          src={imageUrl}
          alignSelf={'center'}
          borderTopRadius={20}
          onLoad={onLoad}
          minWidth={'100%'}
        />
        <Box
          position={'absolute'}
          top={'0px'}
          height='150px'
          width={'100%'}
          backgroundSize='cover'
          backgroundPosition='top'
          backgroundImage={`linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6))`}
          alignSelf={'center'}
          borderTopRadius={20}
        >
          <Center h='150px' color={'white'}>
            {' '}
            Under Commissioning{' '}
          </Center>
        </Box>
      </Box>
    );
  }
  return (
    <Box
      bg='white'
      borderRadius={20}
      minWidth='180px'
      boxShadow={'rgba(108, 122, 137, 0.5) 0px 5px 15px'}
    >
      <Box height={'150px'} overflow={'hidden'}>
        {image}
      </Box>
      <Box bg='white' width={'100%'} borderBottomRadius={'20px'}>
        <Center minH={'4rem'} paddingBottom={0} paddingTop={1}>
          <Heading textAlign='center' fontSize={'xl'} paddingBottom={0}>
            {bessName}
          </Heading>
        </Center>

        <Center paddingTop={2}>
          <Badge colorScheme={'purple'} size={'xl'} marginLeft={2}>
            {powerCapacity}MW
          </Badge>
          {/*<Divider maxWidth={"30px"} color={customTheme.colors.pybessPurple} />*/}
        </Center>

        <Box paddingBottom='5px' paddingTop='20px'>
          <Center padding={2} pb={0}>
            <StatGroup>
              <Stat>
                <StatLabel>Market Revenue to Date</StatLabel>
                <Center>
                  <StatNumber>
                    <NumericFormat
                      value={
                        revenue ? Math.max(revenue / 1000000, 0).toFixed(2) : 0
                      }
                      displayType={'text'}
                      thousandSeparator={true}
                      prefix={'$'}
                      suffix={'m'}
                    />
                  </StatNumber>
                </Center>
              </Stat>
            </StatGroup>
          </Center>
        </Box>
      </Box>
    </Box>
  );
}
