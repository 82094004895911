import * as am4core from "@amcharts/amcharts4/core";
import * as am4maps from "@amcharts/amcharts4/maps";
import am4geodata_worldLow from "@amcharts/amcharts4-geodata/worldLow";
import customTheme from "../styles/theme";
import { Box } from "@chakra-ui/react";
import React, { useContext, useLayoutEffect, useRef } from "react";
import ViewportContext from "../styles/viewport-context";

function addBessCapacitiesInPixels(data, bessStationInFocus) {
  return data.map((item) => {
    let powerCapacity = item.current_power_capacity;
    return {
      ...item,
      pixels: Math.max(10 * (powerCapacity / 350), 7),
      pixelsTwo: Math.max(1.1 * 10 * (powerCapacity / 350), 7),
      fill:
        bessStationInFocus === item.id
          ? customTheme.colors.pybessPurple
          : customTheme.colors.energy[50],
      zIndex: bessStationInFocus === item.id ? 10 : 1,
      hidden: bessStationInFocus === item.id ? false : true,
    };
  });
}

export function MapChart(props) {
  const viewport = useContext(ViewportContext);
  const isSmallScreen = ["mobile", "tablet"].includes(viewport.type);
  const chartRef = useRef(null);
  let { data, bessStationInFocus } = props;

  useLayoutEffect(() => {
    if (!chartRef.current) {
      const mapData = addBessCapacitiesInPixels(data, bessStationInFocus);
      chartRef.current = am4core.create("map-chartdiv", am4maps.MapChart);

      // Set map definition
      chartRef.current.geodata = am4geodata_worldLow;

      // Set projection
      chartRef.current.projection = new am4maps.projections.Miller();

      // Create map polygon series
      let polygonSeries = chartRef.current.series.push(
        new am4maps.MapPolygonSeries()
      );

      // Exclude Antartica
      polygonSeries.exclude = ["AQ"];

      // Make map load polygon (like country names) data from GeoJSON
      polygonSeries.useGeodata = true;

      // Configure series
      let polygonTemplate = polygonSeries.mapPolygons.template;
      // polygonTemplate.tooltipText = "{name}";
      polygonTemplate.polygon.fill = am4core.color(
        customTheme.colors.energy[50]
      );
      polygonTemplate.polygon.fillOpacity = 0.3;

      // Create hover state and set alternative fill color
      // let hs = polygonTemplate.states.create("hover");
      // hs.properties.fill = chartRef.current.colors.getIndex(0);

      // Add image series
      let imageSeries = chartRef.current.series.push(
        new am4maps.MapImageSeries()
      );
      imageSeries.mapImages.template.propertyFields.title = "display_name";
      imageSeries.mapImages.template.propertyFields.longitude = "longitude";
      imageSeries.mapImages.template.propertyFields.latitude = "latitude";
      imageSeries.mapImages.template.tooltipText = "{title}";
      imageSeries.mapImages.template.propertyFields.url = "url";
      imageSeries.mapImages.template.propertyFields.fill = "fill";
      imageSeries.mapImages.template.propertyFields.zIndex = "zIndex";
      imageSeries.mapImages.template.tooltipText = "{title}";
      imageSeries.mapImages.template.propertyFields.showTooltipOn =
        "showTooltipOn";

      // disable tooltip animation
      imageSeries.tooltip.animationDuration = 0;
      let circle = imageSeries.mapImages.template.createChild(am4core.Circle);
      circle.propertyFields.height = "pixels";
      circle.propertyFields.width = "pixels";
      circle.fillOpacity = 0.7;
      circle.propertyFields.fill = "color";
      circle.nonScaling = true;

      let circle2 = imageSeries.mapImages.template.createChild(am4core.Circle);
      circle2.propertyFields.height = "pixelsTwo";
      circle2.propertyFields.width = "pixelsTwo";
      circle2.propertyFields.hidden = "hidden";
      circle2.propertyFields.fill = "color";

      circle2.events.on("inited", function (event) {
        animateBullet(event.target);
      });

      function animateBullet(circle) {
        let animation = circle.animate(
          [
            {
              property: "scale",
              from: 1 / chartRef.current.zoomLevel,
              to: 5 / chartRef.current.zoomLevel,
            },
            { property: "opacity", from: 1, to: 0 },
          ],
          1000,
          am4core.ease.circleOut
        );
        animation.events.on("animationended", function (event) {
          animateBullet(event.target.object);
        });
      }

      imageSeries.data = mapData;
      imageSeries.chart.events.on("ready", function (ev) {
        chartRef.current.zoomToMapObject(polygonSeries.getPolygonById("AU"));
        chartRef.current.seriesContainer.draggable = false;
        chartRef.current.seriesContainer.resizable = false;
        chartRef.current.maxZoomLevel = 10;
        chartRef.current.chartContainer.wheelable = false;
      });
    }
    return () => {
      chartRef.current && chartRef.current.dispose();
      chartRef.current = null;
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useLayoutEffect(() => {
    if (chartRef.current) {
      const newData = chartRef.current.series.values[1].data.map((item) => ({
        ...item,
        fill:
          item.id === bessStationInFocus
            ? customTheme.colors.pybessPurple
            : customTheme.colors.energy[50],
        zIndex: bessStationInFocus === item.id ? 10 : 1,
        hidden: bessStationInFocus !== item.id,
        showTooltipOn: item.id === bessStationInFocus ? "always" : "none",
      }));
      chartRef.current.series.values[1].data = newData;
      chartRef.current.series.values[1].invalidateData();
    }
  }, [data, bessStationInFocus]);

  return (
    <Box w="100%">
      <div
        id="map-chartdiv"
        style={{
          width: "100%",
          height: isSmallScreen ? `calc(70vh - 80px)` : `calc(100vh - 80px)`,
        }}
      />
    </Box>
  );
}
