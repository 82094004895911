import React, { useContext, useLayoutEffect, useRef } from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import { XYChart } from '@amcharts/amcharts4/charts';
import ViewportContext from '../../styles/viewport-context';

export type DetailedPriceChartData = {
  settlementdate: Date;
  rrp: number;
  raise_reg_rrp: number;
  lower_reg_rrp: number;
  raise_1sec_rrp: number;
  lower_1sec_rrp: number;
  raise_6sec_rrp: number;
  lower_6sec_rrp: number;
  raise_60sec_rrp: number;
  lower_60sec_rrp: number;
  raise_5min_rrp: number;
  lower_5min_rrp: number;
};

type PriceChartProps = {
  data: DetailedPriceChartData[];
  isLoaded: boolean;
};

const LINE_SERIES = [
  {
    name: 'Wholesale',
    dataField: 'rrp',
    tooltipText: 'Wholesale: [bold]{valueY}[/] $/MWh',
  },
  {
    name: 'Raise Reg',
    dataField: 'raise_reg_rrp',
    tooltipText: 'Raise Reg: [bold]{valueY}[/] $/MW',
  },
  {
    name: 'Lower Reg',
    dataField: 'lower_reg_rrp',
    tooltipText: 'Lower Reg: [bold]{valueY}[/] $/MW',
  },
  {
    name: 'Raise 1 Sec',
    dataField: 'raise_1sec_rrp',
    tooltipText: 'Raise 1 Sec: [bold]{valueY}[/] $/MW',
  },
  {
    name: 'Lower 1 Sec',
    dataField: 'lower_1sec_rrp',
    tooltipText: 'Lower 1 Sec: [bold]{valueY}[/] $/MW',
  },
  {
    name: 'Raise 6 Sec',
    dataField: 'raise_6sec_rrp',
    tooltipText: 'Raise 6 Sec: [bold]{valueY}[/] $/MW',
  },
  {
    name: 'Lower 6 Sec',
    dataField: 'lower_6sec_rrp',
    tooltipText: 'Lower 6 Sec: [bold]{valueY}[/] $/MW',
  },
  {
    name: 'Raise 60 Sec',
    dataField: 'raise_60sec_rrp',
    tooltipText: 'Raise 60 Sec: [bold]{valueY}[/] $/MW',
  },
  {
    name: 'Lower 60 Sec',
    dataField: 'lower_60sec_rrp',
    tooltipText: 'Lower 60 Sec: [bold]{valueY}[/] $/MW',
  },
  {
    name: 'Raise 5 Min',
    dataField: 'raise_5min_rrp',
    tooltipText: 'Raise 5 Min: [bold]{valueY}[/] $/MW',
  },
  {
    name: 'Lower 5 Min',
    dataField: 'lower_5min_rrp',
    tooltipText: 'Lower 5 Min: [bold]{valueY}[/] $/MW',
  },
];

/**
 * Chart for displaying all reg/contingency rrps in the same chart.
 *
 * @param props
 * @constructor
 */

export function DetailedPriceChart(props: PriceChartProps) {
  const chartRef = useRef<XYChart | null>(null);
  const viewport = useContext(ViewportContext);
  const isSmallScreen = ['mobile', 'tablet'].includes(viewport.type);

  const { data } = props;

  useLayoutEffect(() => {
    let chart = am4core.create('price-chartdiv', am4charts.XYChart);
    let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.grid.template.disabled = true;
    dateAxis.dateFormats.setKey('hour', 'H');
    dateAxis.periodChangeDateFormats.setKey('hour', 'H');
    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.renderer.grid.template.disabled = false;
    valueAxis.cursorTooltipEnabled = false;
    chart.exporting.menu = new am4core.ExportMenu();
    chart.zoomOutButton.align = 'left';
    chart.zoomOutButton.valign = 'top';
    chart.zoomOutButton.background.fill = am4core.color('#25283D');
    chart.zoomOutButton.icon.stroke = am4core.color('#EFD9CE');
    chart.zoomOutButton.icon.strokeWidth = 2;
    const backgroundHover =
      chart.zoomOutButton.background.states.getKey('hover');
    if (backgroundHover) {
      backgroundHover.properties.fill = am4core.color('#8e2eb2');
    }

    // Bunch of shit to get the axis title display above the y axis
    dateAxis.title.text = 'Time of Day';

    LINE_SERIES.forEach((item: any) => {
      let series = chart.series.push(new am4charts.LineSeries());
      series.dataFields.valueY = item.dataField;
      series.dataFields.dateX = 'settlementdate';
      series.tooltipText = '{value}';
      series.name = item.name;
      series.tooltipText = item.tooltipText;
      // series.stroke = am4core.color("#b33dc6");
      // series.fill = am4core.color("#b33dc6");
      series.strokeWidth = 1;
      series.showOnInit = false;
    });

    chart.cursor = new am4charts.XYCursor();
    chart.cursor.lineY.disabled = true;
    chart.cursor.lineX.disabled = true;

    if (isSmallScreen) {
      valueAxis.renderer.labels.template.fontSize = 10;
      valueAxis.renderer.labels.template.paddingLeft = 0;
      //valueAxis.strictMinMax = true;
    }
    chart.legend = new am4charts.Legend();
    chart.legend.labels.template.fontSize = `${isSmallScreen ? 8 : 14}`;
    chart.legend.itemContainers.template.padding(3, 0, 3, 0);
    chart.legend.itemContainers.template.margin(0, 1, 0, 1);
    chart.legend.useDefaultMarker = true;

    //const valueAxis = x._yAxes._values[0];
    if (isSmallScreen) {
      valueAxis.renderer.labels.template.fontSize = 10;
      valueAxis.renderer.labels.template.paddingLeft = 0;
      //valueAxis.strictMinMax = true;
      dateAxis.renderer.minGridDistance = 30;
    } else {
      valueAxis.renderer.labels.template.fontSize = null;
      valueAxis.renderer.labels.template.paddingLeft = 0;
      valueAxis.strictMinMax = false;
      dateAxis.renderer.minGridDistance = 70;
    }
    chartRef.current = chart;
    return () => {
      if (chartRef.current) {
        chartRef.current.dispose();
        chartRef.current = null;
      }
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useLayoutEffect(() => {
    if (chartRef.current) {
      chartRef.current.data = data;
      chartRef.current?.invalidateRawData();
      const valueAxis = chartRef.current.yAxes.getIndex(0);
      const dateAxis = chartRef.current.xAxes.getIndex(0);
      if (valueAxis && dateAxis) {
        if (isSmallScreen) {
          valueAxis.renderer.labels.template.fontSize = 10;
          valueAxis.renderer.labels.template.paddingLeft = 0;
          //valueAxis.strictMinMax = true;
          dateAxis.renderer.minGridDistance = 30;
        } else {
          valueAxis.renderer.labels.template.fontSize = null;
          valueAxis.renderer.labels.template.paddingLeft = 0;
          // valueAxis.strictMinMax = false;
          dateAxis.renderer.minGridDistance = 70;
        }
      }
    }
  }, [data, isSmallScreen]);

  return (
    <>
      <div id='price-chartdiv' style={{ width: '100%', height: '400px' }} />
      <div id='price-legendwrapper' style={{ overflowX: 'auto' }}>
        <div id='price-legenddiv' />
      </div>
    </>
  );
}
