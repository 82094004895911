import { Controller, FieldValues, useForm } from 'react-hook-form';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Center,
  Divider,
  Flex,
  FormControl,
  Grid,
  GridItem,
  Heading,
  HStack,
  Input,
  InputGroup,
  InputLeftAddon,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Select,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { DetailedPriceChart, DetailedPriceChartData } from './DetailedPriceChart';
import customTheme from '../../styles/theme';
import './simulatorStyles.css';
import { BatteryCharging, ChevronLeft, ChevronRight } from 'react-bootstrap-icons';
import { RevenueCard } from '../RevenueCard';
import { OperationalChart, OperationalChartData } from '../OperationalChart';
import { FCASType } from '../Operation';
import ViewportContext from '../../styles/viewport-context';
import { RevenueChart } from '../RevenueChart';
import { dateStringToDate, daysBetweenTwoDates, formatDate } from '../../utils';
import { PopoverHeader } from 'react-bootstrap';
import { InfoIcon } from '@chakra-ui/icons';
import { Navbar, NAVBAR_HEIGHT } from '../Navbar';
import DatePicker from 'react-datepicker';
import ReactGA from 'react-ga4';
import { APIRevenue } from '../../api/api-types';
import { BatteryLoader } from '../batteryLoader/BatteryLoader';

const API_HOST = process.env.REACT_APP_APIHOST;

type RevenueSummary = {
  regulation_revenue: number;
  contingency_revenue: number;
  net_energy_revenue: number;
};

type SimulatorRequest = {
  region_id: string;
  power_capacity_mw: number;
  storage_capacity_mwh: number;
  round_trip_efficiency: number;
  start_date: string;
  end_date: string;
  cycles_per_day: number;
  contingency_fcas_percent: number;
  regulation_fcas_percent: number;
  regulation_fcas_throughput_percent: number;
  availability: number;
  pop: number;
};

async function runSimulator(requestBody: SimulatorRequest) {
  try {
    const response = await axios.post(`${API_HOST}/simulator`, requestBody, {
      timeout: 30000,
    });
    return response.data;
  } catch (error) {
    console.error(error);
    window.location.reload();
  }
}

export type SimulatorData = {
  datetime: string;
  usable_storage_mwh: number;
  dispatch: number;
  rrp: number;
  raise_dispatch: number;
  raise_reg_rrp: number;
  raise_throughput_ratio: number;
  lower_dispatch: number;
  lower_reg_rrp: number;
  lower_throughput_ratio: number;
  metered_dispatch_mwh: number;
  discharged_dispatch_mwh: number;
  soc_mwh: number;
  soc_percent: number;
  energy_rev: number;
  raise_rev: number;
  lower_rev: number;
  reg_energy_rev: number;
  metered_energy_rev: number;
  raise_load: number;
  raise_gen: number;
  lower_load: number;
  lower_gen: number;
  dcharge_vars: number;
  charge_vars: number;
  raise_1sec: number;
  raise_1sec_rrp: number;
  raise_1sec_rev: number;
  lower_1sec: number;
  lower_1sec_rrp: number;
  lower_1sec_rev: number;
  raise_6sec: number;
  raise_6sec_rrp: number;
  raise_6sec_rev: number;
  lower_6sec: number;
  lower_6sec_rrp: number;
  lower_6sec_rev: number;
  raise_60sec: number;
  raise_60sec_rrp: number;
  raise_60sec_rev: number;
  lower_60sec: number;
  lower_60sec_rrp: number;
  lower_60sec_rev: number;
  raise_5min: number;
  raise_5min_rrp: number;
  raise_5min_rev: number;
  lower_5min: number;
  lower_5min_rrp: number;
  lower_5min_rev: number;
  energy_revenue: number;
  regulation_revenue: number;
  contingency_revenue: number;
  battery_revenue_pop: number;
};

type SimulatorState = {
  fcasType: FCASType;
  data: SimulatorData[];
  aggregatedRevenue: RevenueSummary;
  isRunning: boolean;
  inputsExpanded: boolean;
  dailyRevenue: APIRevenue[];
  startDate: Date | null;
  endDate: Date | null;
  selectedDate: Date | null;
  currentDayData: SimulatorData[];
  operationalChartData: OperationalChartData[];
  priceChartData: DetailedPriceChartData[];
};

const INITIAL_STATE: SimulatorState = {
  data: [],
  isRunning: false,
  aggregatedRevenue: {
    regulation_revenue: 0,
    contingency_revenue: 0,
    net_energy_revenue: 0,
  },
  inputsExpanded: true,
  fcasType: FCASType.Regulation,
  dailyRevenue: [],
  startDate: null,
  endDate: null,
  selectedDate: null,
  currentDayData: [],
  operationalChartData: [],
  priceChartData: [],
};

function extractOperationsData(
  simulatorData: SimulatorData[],
): OperationalChartData[] {
  return simulatorData.map((d) => ({
    settlementdate: new Date(d.datetime),
    lowerreg_mw: -(d.lower_gen + d.lower_load),
    raisereg_mw: d.raise_gen + d.raise_load,
    avg_raise_contingency_mw: d.raise_5min, // TODO: proper average
    avg_lower_contingency_mw: -d.lower_5min,
    charge_mw: Math.min(d.metered_dispatch_mwh * 12, 0),
    discharge_mw: Math.max(d.metered_dispatch_mwh * 12, 0),
  }));
}

function extractPriceData(
  simulatorData: SimulatorData[],
): DetailedPriceChartData[] {
  return simulatorData.map((d) => ({
    settlementdate: dateStringToDate(d.datetime),
    rrp: d.rrp,
    raise_reg_rrp: d.raise_reg_rrp,
    lower_reg_rrp: d.lower_reg_rrp,
    raise_1sec_rrp: d.raise_1sec_rrp,
    lower_1sec_rrp: d.lower_1sec_rrp,
    raise_6sec_rrp: d.raise_6sec_rrp,
    lower_6sec_rrp: d.lower_6sec_rrp,
    raise_60sec_rrp: d.raise_60sec_rrp,
    lower_60sec_rrp: d.lower_60sec_rrp,
    raise_5min_rrp: d.raise_5min_rrp,
    lower_5min_rrp: d.lower_5min_rrp,
  }));
}

function InputPopover(props: { header: string; body: string }) {
  const { header, body } = props;
  return (
    <Popover trigger={'hover'}>
      <PopoverTrigger>
        <InfoIcon color={customTheme.colors.pybessPurple} w={4} h={4} ml={1} />
      </PopoverTrigger>
      <Portal>
        <PopoverContent>
          <PopoverHeader>
            <Text fontWeight={'bold'} pl={2} pt={1}>
              {header}
            </Text>
          </PopoverHeader>
          <PopoverBody>
            <Box>{body}</Box>
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
}

function aggregateRevenue(revenueData: APIRevenue[]) {
  // Aggregate API response to get total values in period
  let aggregatedData: RevenueSummary = {
    regulation_revenue: 0,
    contingency_revenue: 0,
    net_energy_revenue: 0,
  };
  if (revenueData.length > 0) {
    aggregatedData = revenueData.reduce<RevenueSummary>(
      (a, b) => ({
        regulation_revenue: a.regulation_revenue + b.regulation_revenue,
        contingency_revenue: a.contingency_revenue + b.contingency_revenue,
        net_energy_revenue: a.net_energy_revenue + b.net_energy_revenue,
      }),
      aggregatedData,
    );
  }
  return aggregatedData;
}

function getDailyRevenue(revenueData: SimulatorData[]) {
  // Aggregate API response to get total values in period
  let dailyRevenue: { [key: string]: APIRevenue } = {};

  if (revenueData.length > 0) {
    revenueData.reduce((dailyRevenue, d) => {
      let dateString = d.datetime.slice(0, 10);
      if (!dailyRevenue[dateString]) {
        dailyRevenue[dateString] = {
          start_date: dateString,
          regulation_revenue: 0,
          contingency_revenue: 0,
          net_energy_revenue: 0,
          lowerregrev: 0,
          raiseregrev: 0,
          lower5minrev: 0,
          raise5minrev: 0,
          lower60secrev: 0,
          raise60secrev: 0,
          lower6secrev: 0,
          raise6secrev: 0,
          raise1secrev: 0,
          lower1secrev: 0,
        };
      }
      dailyRevenue[dateString].regulation_revenue += d.regulation_revenue;
      dailyRevenue[dateString].contingency_revenue += d.contingency_revenue;
      dailyRevenue[dateString].net_energy_revenue += d.energy_revenue;
      dailyRevenue[dateString].lowerregrev += d.lower_rev;
      dailyRevenue[dateString].raiseregrev += d.raise_rev;
      dailyRevenue[dateString].lower5minrev += d.lower_5min_rev;
      dailyRevenue[dateString].raise5minrev += d.raise_5min_rev;
      dailyRevenue[dateString].lower60secrev += d.lower_60sec_rev;
      dailyRevenue[dateString].raise60secrev += d.raise_60sec_rev;
      dailyRevenue[dateString].lower6secrev += d.lower_6sec_rev;
      dailyRevenue[dateString].raise6secrev += d.raise_6sec_rev;
      return dailyRevenue;
    }, dailyRevenue);
  }
  // Convert to array
  return Object.values(dailyRevenue);
}

function ChartHeading(props: {
  name: string;
  containerProps?: any;
}) {
  const { name, containerProps } = props;
  return (
    <Box ml={4} mt={2} mb={4} {...containerProps}>
      <Text fontSize={['md', 'lg']} mb={0}>{name}</Text>
      <Divider borderColor={'pybessPurple'} borderWidth={'1px'} mt={'1px'} />
    </Box>
  );
}

const updateDates = (startDate: Date, endDate: Date) => {
  console.log('hello');
};

export function Simulator(props: any) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    getValues,
    setValue,
  } = useForm({ mode: 'onChange' });

  const { onClose } = useDisclosure();

  const viewport = useContext(ViewportContext);
  const isSmallScreen = ['mobile', 'tablet'].includes(viewport.type);

  const [state, setState] = useState<SimulatorState>(INITIAL_STATE);
  let [progress, setProgress] = useState(0);

  useEffect(() => {
    let interval: NodeJS.Timer | undefined = undefined;
    if (state.isRunning) {
      interval = setInterval(() => {
        let increment = Math.round(Math.random() * 10);
        if (progress >= 90) {
          increment = 1;
        }
        setProgress((prevProgress) => Math.min(prevProgress + increment, 99));
      }, 1000);

    }
    return () => interval && clearInterval(interval);
  }, [progress, state.isRunning]);

  useEffect(() => {
    if (state.data.length && state.selectedDate) {
      // THIS WILL CAUSE COMPARISON BY REFERENCE U IDIOT
      const currentDayData = state.data.filter(
        (d: SimulatorData) =>
          new Date(d.datetime).toDateString().slice(0, 10) ===
          state.selectedDate?.toDateString().slice(0, 10),
      );
      const operationalChartData = extractOperationsData(currentDayData);
      const priceChartData = extractPriceData(currentDayData);
      setState((prevState: SimulatorState) => {
        return {
          ...prevState,
          currentDayData,
          operationalChartData,
          priceChartData,
        };
      });
    }
  }, [state.data, state.selectedDate]);
  const setIsLoaded = () => {
    setState((prevState: SimulatorState) => ({
      ...prevState,
      isRunning: false,
    }));
  };
  const toggleFCASType = (fcasType: FCASType) => {
    setState({
      ...state,
      fcasType: fcasType,
    });
  };

  function incrementDate() {
    if (state.selectedDate && state.endDate) {
      const nextDate = new Date(state.selectedDate);
      nextDate.setDate(nextDate.getDate() + 1);
      if (nextDate <= state.endDate) {
        setState({
          ...state,
          selectedDate: nextDate,
        });
      }
    }
  }

  function decrementDate() {
    if (state.selectedDate && state.startDate) {
      const nextDate = new Date(state.selectedDate);
      nextDate.setDate(nextDate.getDate() - 1);
      if (nextDate >= state.startDate) {
        setState({ ...state, selectedDate: nextDate });
      }
    }
  }

  const toggleAccordion = () => {
    setState({ ...state, inputsExpanded: false });
  };

  const onSubmit = (data: FieldValues) => {
    ReactGA.event({ category: 'simulator', action: 'simulator_run' });
    setState({ ...state, isRunning: true, inputsExpanded: false });
    const apiData: SimulatorRequest = {
      region_id: data.state,
      power_capacity_mw: +data.powerCapacity,
      storage_capacity_mwh: +data.storageCapacity,
      round_trip_efficiency: +data.roundTripEfficiency,
      start_date: new Date(data.startDate.getTime() - (data.startDate.getTimezoneOffset() * 60000)).toISOString().split('T')[0],
      end_date: new Date(data.endDate.getTime() - (data.endDate.getTimezoneOffset() * 60000)).toISOString().split('T')[0],
      cycles_per_day: +data.cyclesPerDay,
      contingency_fcas_percent: +data.contingencyFCASPercent,
      regulation_fcas_percent: +data.regulationFCASPercent,
      regulation_fcas_throughput_percent: +data.regulationFCASThroughput,
      availability: 1,
      pop: 1,
    };
    runSimulator(apiData).then((response) => {
      const dailyRevenue = getDailyRevenue(response);
      setProgress(100);
      setState((prevState: SimulatorState) => ({
        ...prevState,
        inputsExpanded: false,
        data: response,
        aggregatedRevenue: aggregateRevenue(dailyRevenue),
        dailyRevenue: dailyRevenue,
        startDate: data.startDate,
        endDate: data.endDate,
        selectedDate: data.startDate,
      }));
      setProgress(100);
      setTimeout(() => {
        setProgress(0);
      }, 1000);


    });
  };

  return (
    <Box height={'100%'}>
      <Navbar
        showHamburger={true}
        toggleSidebar={() => {
        }}
        activePage={'Simulator'}
      />
      <Box
        position={'fixed'}
        top={`${NAVBAR_HEIGHT}px`}
        p={6}
        width={`100vw`}
        height={`calc(100% - ${NAVBAR_HEIGHT}px)`}
        overflowY={'scroll'}
      >
        <Box bgColor={'white'} style={{ borderRadius: '20px' }}>
          {isSmallScreen ? (
            <>
              <Flex justifyContent={'flex-start'} alignItems={'center'} gap={4}>
                <Box pl={4}>
                  <BatteryCharging
                    color={customTheme.colors.pybessPurple}
                    size={64}
                  />
                </Box>
                <Box>
                  <Heading mb={0}>BESS Simulator</Heading>
                </Box>
              </Flex>
              <Flex justifyContent={'flex-start'}>
                <Box>
                  <Text p={4} pt={1}>
                    Choose your market, time period and battery parameters to
                    see how an idealised version of the battery would have
                    performed
                  </Text>
                </Box>
              </Flex>
            </>
          ) : (
            <Flex justifyContent={'flex-start'} alignItems={'center'} gap={4}>
              <Box pl={4}>
                <BatteryCharging
                  color={customTheme.colors.pybessPurple}
                  size={64}
                />
              </Box>
              <Box>
                <Heading mb={0}>BESS Simulator</Heading>
              </Box>
              <Box>
                <Text pt={2} mb={0}>
                  Choose your market, time period and battery parameters to see
                  how an idealised version of the battery would have performed
                </Text>
              </Box>
            </Flex>
          )}
        </Box>
        <Box bgColor={'white'} mt={4} style={{ borderRadius: '15px' }}>
          <Accordion
            allowToggle
            width={'100%'}
            index={state.inputsExpanded ? 0 : undefined}
            borderWidth={0}
            borderColor={'white'}


          >
            <AccordionItem border={'none'} className={'simulatorAccordionItem'}
                           sx={{ '& > .chakra-collapse': { 'overflow': 'visible !important' } }}>
              <AccordionButton
                onClick={(event) => {
                  toggleAccordion();
                }}
                _hover={{ backgroundColor: 'white' }}
                borderRadius={'15px'}
                w={'100%'}
              >
                <Flex justifyContent={'space-between'} w={'100%'}>
                  <Box>
                    <ChartHeading
                      name={'Parameters'}
                      containerProps={{ ml: 0, mr: 4 }}
                    />
                  </Box>
                  <Box pt={2}>
                    <AccordionIcon />
                  </Box>
                </Flex>
              </AccordionButton>
              <AccordionPanel pb={4} overflow={'visible'}>
                {(errors.startDate?.type === 'maxPeriod' || errors.endDate?.type === 'maxPeriod') && (
                  <Alert status={'error'}>
                    <AlertIcon />
                    <AlertTitle>The date range can be max 7 days.</AlertTitle>
                    <AlertDescription>
                      For longer simulation periods, please reach out to us at{' '}
                      info@nembess.com
                    </AlertDescription>
                  </Alert>
                )}
                {(errors.startDate?.type === 'relativeDate' || errors.endDate?.type === 'relativeDate') && (
                  <Alert status={'error'}>
                    <AlertIcon />
                    <AlertTitle>Start date cannot be after end date.</AlertTitle>
                  </Alert>
                )}
                <Box padding={4}>
                  <FormControl>
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <Grid
                        templateColumns={`repeat(${
                          isSmallScreen ? 1 : 6
                        }, 1fr)`}
                        gap={6}
                      >
                        <GridItem>
                          <Center>
                            <label htmlFor={'state'}>State</label>
                          </Center>

                          <Select
                            id={'state'}
                            placeholder={'Select state'}
                            {...register('state', { required: true })}
                            defaultValue={'SA1'}
                          >
                            <option value={'NSW1'}>NSW</option>
                            <option value={'VIC1'}>VIC</option>
                            <option value={'QLD1'}>QLD</option>
                            <option value={'SA1'}>SA</option>
                            <option value={'TAS1'}>TAS</option>
                          </Select>
                        </GridItem>
                        <GridItem>
                          <Center>
                            <label htmlFor={'startDate'}>Start Date</label>
                          </Center>

                          <Controller
                            defaultValue={new Date(2023, 0, 1)}
                            render={({ field }) => {
                              const onChange = (e: any) => {

                                if (!e) {
                                  field.onChange(null);
                                } else {
                                  // set end date to startDate + 7
                                  const endDate = new Date(e);
                                  endDate.setDate(endDate.getDate() + 6);
                                  setValue('endDate', endDate, { shouldValidate: true });
                                  field.onChange(e);
                                }
                              };
                              return <Box
                                cursor={'pointer'}
                                border={`1px solid ${
                                  (errors.endDate || errors.startDate) ? 'red' : '#F5F6F8FF'
                                }`}
                                borderRadius={'5px'}
                                pt={2}
                                className={'startDateInputBox'}
                                width={'100%'}
                              >
                                <DatePicker
                                  onChange={onChange}
                                  selected={field.value}
                                  dateFormat={'do MMM yyyy'}
                                />
                              </Box>;
                            }}
                            name={'startDate'}
                            control={control}
                            rules={{
                              validate: {
                                relativeDate: (value) => {
                                  // hack if startDate fails validation then it is startDate > endDate
                                  // if endDate fails validation that represents > 7 day period
                                  return value <= getValues('endDate');
                                },
                                maxPeriod: (value) => {
                                  return daysBetweenTwoDates(
                                    value,
                                    getValues('endDate'),
                                  ) <= 6;

                                },
                              },
                            }}
                          />
                        </GridItem>
                        <GridItem>
                          <Center>
                            <label htmlFor={'endDate'}>End Date</label>
                          </Center>

                          <Controller
                            defaultValue={new Date(2023, 0, 7)}
                            render={({ field }) => (
                              <Box
                                cursor={'pointer'}
                                border={`1px solid ${
                                  (errors.endDate || errors.startDate) ? 'red' : '#F5F6F8FF'
                                }`}
                                borderRadius={'5px'}
                                pt={2}
                                className={'endDateInputBox'}
                              >
                                <DatePicker
                                  wrapperClassName={'endDateInputBox'}
                                  onChange={field.onChange}
                                  selected={field.value}
                                  dateFormat={'do MMM yyyy'}
                                />
                              </Box>
                            )}
                            name={'endDate'}
                            control={control}
                            rules={{
                              validate: {
                                relativeDate: (value) => {
                                  // hack if startDate fails validation then it is startDate > endDate
                                  // if endDate fails validation that represents > 7 day period
                                  return value >= getValues('startDate');
                                },
                                maxPeriod: (value) => {
                                  return daysBetweenTwoDates(
                                    getValues('startDate'),
                                    value,
                                  ) <= 6;

                                },
                              },
                            }}
                          />
                        </GridItem>
                        <GridItem>
                          <Center>
                            <label htmlFor={'powerCapacity'}>
                              Power Capacity
                            </label>
                            <InputPopover
                              header={'Power Capacity'}
                              body={
                                'The maximum instantaneous power output of the battery in Megawatts'
                              }
                            />
                          </Center>

                          <InputGroup>
                            <InputLeftAddon children='MW' />
                            <NumberInput
                              id={'powerCapacity'}
                              defaultValue={25}
                              step={1}
                            >
                              <NumberInputField
                                {...register('powerCapacity')}
                              />
                              <NumberInputStepper>
                                <NumberIncrementStepper />
                                <NumberDecrementStepper />
                              </NumberInputStepper>
                            </NumberInput>
                          </InputGroup>
                        </GridItem>
                        <GridItem>
                          <Center>
                            <label htmlFor={'storageCapacity'}>
                              Storage Capacity
                            </label>
                            <InputPopover
                              header={'Storage Capacity'}
                              body={
                                'The maximum amount of energy that can be stored in the battery in Megawatt-hours'
                              }
                            />
                          </Center>

                          <InputGroup>
                            <InputLeftAddon children='MWh' />
                            <NumberInput
                              id={'storageCapacity'}
                              defaultValue={52}
                              step={1}
                            >
                              <NumberInputField
                                {...register('storageCapacity')}
                              />
                              <NumberInputStepper>
                                <NumberIncrementStepper />
                                <NumberDecrementStepper />
                              </NumberInputStepper>
                            </NumberInput>
                          </InputGroup>
                        </GridItem>
                        <GridItem>
                          <Center>
                            <label htmlFor={'roundTripEfficiency'}>
                              Round Trip Efficiency
                            </label>
                            <InputPopover
                              header={'Round Trip Efficiency'}
                              body={
                                'The percentage of energy put into the BESS that can later be retrieved. For example, a BESS with a round-trip efficiency of 85% would be able to output 0.85MWh of AC electricity for every 1MWh of AC electricity used to charge it.'
                              }
                            />
                          </Center>

                          <InputGroup>
                            <InputLeftAddon children='%' />
                            <NumberInput
                              id={'roundTripEfficiency'}
                              defaultValue={85}
                              step={1}
                            >
                              <NumberInputField
                                {...register('roundTripEfficiency')}
                              />
                              <NumberInputStepper>
                                <NumberIncrementStepper />
                                <NumberDecrementStepper />
                              </NumberInputStepper>
                            </NumberInput>
                          </InputGroup>
                        </GridItem>
                        <GridItem>
                          <Center>
                            <label htmlFor={'cyclesPerDay'}>
                              Cycles Per Day
                            </label>
                            <InputPopover
                              header={'Cycles per day'}
                              body={
                                'The average number of full charge/discharge cycles that the battery should be restricted to over the simulation period.'
                              }
                            />
                          </Center>

                          <InputGroup>
                            <NumberInput
                              id={'cyclesPerDay'}
                              defaultValue={1}
                              step={0.1}
                              min={0.1}
                              max={10}
                              width={'100%'}
                            >
                              <NumberInputField {...register('cyclesPerDay')} />
                              <NumberInputStepper>
                                <NumberIncrementStepper />
                                <NumberDecrementStepper />
                              </NumberInputStepper>
                            </NumberInput>
                          </InputGroup>
                        </GridItem>
                        <GridItem>
                          <Center>
                            <label htmlFor={'contingencyFCASPercent'}>
                              Contingency
                            </label>
                            <InputPopover
                              header={'Contingency FCAS Allocation'}
                              body={
                                'The proportion of the battery\'s power capacity that can be bid into the contingency markets as a price-taker'
                              }
                            />
                          </Center>

                          <InputGroup>
                            <InputLeftAddon children='%' />
                            <NumberInput
                              id={'contingencyFCASPercent'}
                              defaultValue={52}
                              step={1}
                              max={100}
                              min={0}
                            >
                              <NumberInputField
                                {...register('contingencyFCASPercent')}
                              />
                              <NumberInputStepper>
                                <NumberIncrementStepper />
                                <NumberDecrementStepper />
                              </NumberInputStepper>
                            </NumberInput>
                          </InputGroup>
                        </GridItem>
                        <GridItem>
                          <Center>
                            <label htmlFor={'regulationFCASPercent'}>
                              Regulation
                            </label>
                            <InputPopover
                              header={'Regulation FCAS Allocation'}
                              body={
                                'The proportion of the battery\'s power capacity that can be bid into the regulation markets as a price-taker'
                              }
                            />
                          </Center>

                          <InputGroup>
                            <InputLeftAddon children='%' />
                            <NumberInput
                              id={'regulationFCASPercent'}
                              defaultValue={100}
                              step={1}
                              max={100}
                              min={0}
                            >
                              <NumberInputField
                                {...register('regulationFCASPercent')}
                              />
                              <NumberInputStepper>
                                <NumberIncrementStepper />
                                <NumberDecrementStepper />
                              </NumberInputStepper>
                            </NumberInput>
                          </InputGroup>
                        </GridItem>
                        <GridItem>
                          <Center>
                            <label htmlFor={'regulationFCASThroughput'}>
                              Regulation Throughput
                            </label>
                            <InputPopover
                              header={'Regulation Throughput'}
                              body={
                                'The percentage of the FCAS enablement that impacts the metered energy. For example, a BESS enabled for 100MW of raise regulation with 20% regulation throughput would export the equivalent amount of energy as a 20MW BESS discharging continuously over the interval.'
                              }
                            />
                          </Center>

                          <InputGroup>
                            <InputLeftAddon children='%' />
                            <NumberInput
                              id={'regulationFCASThroughput'}
                              defaultValue={20}
                              step={1}
                              max={100}
                              min={0}
                            >
                              <NumberInputField
                                {...register('regulationFCASThroughput')}
                              />
                              <NumberInputStepper>
                                <NumberIncrementStepper />
                                <NumberDecrementStepper />
                              </NumberInputStepper>
                            </NumberInput>
                          </InputGroup>
                        </GridItem>
                        <GridItem colSpan={isSmallScreen ? 1 : 5} />
                        <GridItem>
                          <Input
                            type={'submit'}
                            bgColor={customTheme.colors.pybessPurple}
                            color={'white'}
                            value={'Run'}
                          />
                        </GridItem>
                      </Grid>
                    </form>
                  </FormControl>
                </Box>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </Box>
        <Modal onClose={onClose} isOpen={state.isRunning} isCentered size={'xs'}>
          <ModalOverlay />
          <ModalContent>

            <ModalBody>
              <Center>
                <BatteryLoader progress={progress} />
              </Center>
            </ModalBody>

          </ModalContent>
        </Modal>

        {state.data.length > 0 && (
          <Grid paddingTop={4} templateColumns={`repeat(4, 1fr)`} gap={6}>
            <GridItem colSpan={isSmallScreen ? 4 : 3}>
              <Box backgroundColor={'white'} style={{ borderRadius: '15px' }}>
                <Flex justifyContent={'flex-start'}>
                  <ChartHeading
                    name={'Revenue'}
                  />
                </Flex>

                {state.startDate && state.endDate && state.selectedDate && (
                  <RevenueChart
                    data={state.dailyRevenue}
                    aggregation={'day'}
                    bessStationId={null}
                    updateDates={updateDates}
                    startDate={state.startDate}
                    endDate={state.endDate}
                    dashboardTechnology={null}
                    chartDivHeight={250}
                    selectedDate={state.selectedDate}
                  />
                )}
              </Box>
            </GridItem>
            <GridItem colSpan={isSmallScreen ? 4 : 1} rowSpan={2}>
              {isSmallScreen ? (
                <HStack>
                  <RevenueCard
                    type={'ENERGY'}
                    total={state.aggregatedRevenue.net_energy_revenue}
                  />
                  <RevenueCard
                    type={'REGULATION'}
                    total={state.aggregatedRevenue.regulation_revenue}
                  />
                  <RevenueCard
                    type={'CONTINGENCY'}
                    total={state.aggregatedRevenue.contingency_revenue}
                  />
                  {!isSmallScreen && (
                    <RevenueCard
                      type={'TOTAL'}
                      total={
                        state.aggregatedRevenue.net_energy_revenue +
                        state.aggregatedRevenue.regulation_revenue +
                        state.aggregatedRevenue.contingency_revenue
                      }
                    />
                  )}
                </HStack>
              ) : (
                <VStack>
                  <RevenueCard
                    type={'ENERGY'}
                    total={state.aggregatedRevenue.net_energy_revenue}
                  />
                  <RevenueCard
                    type={'REGULATION'}
                    total={state.aggregatedRevenue.regulation_revenue}
                  />
                  <RevenueCard
                    type={'CONTINGENCY'}
                    total={state.aggregatedRevenue.contingency_revenue}
                  />
                  <RevenueCard
                    type={'TOTAL'}
                    total={
                      state.aggregatedRevenue.net_energy_revenue +
                      state.aggregatedRevenue.regulation_revenue +
                      state.aggregatedRevenue.contingency_revenue
                    }
                  />
                </VStack>
              )}
            </GridItem>
            <GridItem colSpan={isSmallScreen ? 4 : 3}>
              <Box backgroundColor={'white'} style={{ borderRadius: '15px' }}>
                <Flex justifyContent={'space-between'} alignItems={'center'}>
                  <ChartHeading
                    name={'Dispatch'}
                  />
                  <Box>
                    <Flex alignItems={'center'}>
                      <ChevronLeft
                        onClick={() => decrementDate()}
                        cursor={'pointer'}
                      />
                      <Box
                        bg={'backgroundGray'}
                        p={1}
                        borderRadius={'5px'}
                        minWidth={'60px'}
                        minHeight={'25px'}
                      >
                        <Text
                          color={state.selectedDate ? undefined : 'gray'}
                          fontSize={['xs', 'md']}
                          mb={0}
                        >
                          {state.selectedDate
                            ? formatDate(state.selectedDate)
                            : 'YYYY-mm-dd'}
                        </Text>

                      </Box>
                      <ChevronRight
                        onClick={() => incrementDate()}
                        cursor={'pointer'}
                      />
                    </Flex>
                  </Box>
                  <Box>
                    {' '}
                    <Box
                      display={'flex'}
                      justifyContent={'flex-end'}
                      pr={5}
                    >
                      <Button
                        onClick={() => toggleFCASType(FCASType.Regulation)}
                        isActive={state.fcasType === FCASType.Regulation}
                        size={isSmallScreen ? 'xs' : 'md'}
                        margin={'1px'}
                        _focus={undefined}
                        _active={{
                          color: 'white',
                          bgColor: customTheme.colors.pybessPurple,
                          borderColor: 'white',
                        }}
                        variant={'outline'}
                      >
                        Regulation
                      </Button>
                      <Button
                        onClick={() => toggleFCASType(FCASType.Contingency)}
                        isActive={state.fcasType === FCASType.Contingency}
                        size={isSmallScreen ? 'xs' : 'md'}
                        margin={'1px'}
                        _focus={undefined}
                        _active={{
                          color: 'white',
                          bgColor: customTheme.colors.pybessPurple,
                          borderColor: 'white',
                        }}
                        variant={'outline'}
                      >
                        Contingency
                      </Button>

                    </Box>
                  </Box>
                </Flex>
                <Box pt={2}>
                  {state.operationalChartData.length > 0 && (
                    <OperationalChart
                      data={state.operationalChartData}
                      setIsLoaded={setIsLoaded}
                      isLoaded={true}
                      fcasType={state.fcasType}
                    />
                  )}
                </Box>
              </Box>
            </GridItem>
            <GridItem colSpan={isSmallScreen ? 4 : 3}>
              <Box backgroundColor={'white'} style={{ borderRadius: '15px' }}>
                <Flex justifyContent={'flex-start'}>
                  <Box pb={2}>
                    <ChartHeading
                      name={'Price $/MW(h)'}
                    />
                  </Box>
                </Flex>

                <DetailedPriceChart
                  data={state.priceChartData}
                  isLoaded={true}
                />
              </Box>
            </GridItem>
          </Grid>
        )}
      </Box>
    </Box>
  );
}
