import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Home } from './components/Home';
import { Dashboard } from './components/Dashboard';
import { FAQ } from './components/FAQ';
import { About } from './components/About';
import { useWindowSizeChange, ViewportProvider } from './styles/viewport-context';
import React, { useState } from 'react';
import { getViewportType } from './styles/theme';
import * as am4core from '@amcharts/amcharts4/core';
import am4themes_material from '@amcharts/amcharts4/themes/material';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import './styles/customStyles.css';
import { createBrowserHistory } from 'history';
import ReactGA from 'react-ga4';
import CacheBuster from 'react-cache-buster';
import packageJson from '../package.json';
import { NembessSpinner } from './components/designSystem/NembessSpinner';
import { AbsoluteCenter } from '@chakra-ui/react';
import { Simulator } from './components/simulator/Simulator';

am4core.useTheme(am4themes_material);
const history = createBrowserHistory();
if (document.location.hostname.search('nembess.com') !== -1) {
  ReactGA.initialize('G-SQJBYXP9PX');

  history.listen((location) => {
    ReactGA.set({ page: location.location.pathname });
    ReactGA.send({ hitType: 'pageview', page: location.location.pathname });
  });
}


function App() {
  const [viewportDimensions, setViewportDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const viewportType = getViewportType(viewportDimensions.width);
  useWindowSizeChange(() => {
    setViewportDimensions({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  });
  return (
    <CacheBuster
      currentVersion={packageJson.version}
      isEnabled={true}
      isVerboseMode={false}
      loadingComponent={<AbsoluteCenter><NembessSpinner /></AbsoluteCenter>}
      metaFileDirectory={'.'}

    >
      <ViewportProvider
        value={{ type: viewportType, height: viewportDimensions.height }}
      >
        <Router>
          <Routes>
            <Route path='/' element={<Home />} />
            <Route
              path='/batteries'
              element={
                <Dashboard
                  history={history}
                  dashboardTechnology={'BATTERY'}
                  selectedPage={'Overview'}
                />
              }
            />
            <Route
              path='/batteries/revenue/:id'
              element={
                <Dashboard
                  history={history}
                  selectedPage={'Revenue'}
                  dashboardTechnology={'BATTERY'}
                />
              }
            />
            <Route
              path='/batteries/revenue'
              element={
                <Dashboard
                  history={history}
                  selectedPage={'Revenue'}
                  dashboardTechnology={'BATTERY'}
                />
              }
            />
            <Route
              path='/vpps/revenue/:id'
              element={
                <Dashboard
                  history={history}
                  selectedPage={'Revenue'}
                  dashboardTechnology={'VPP'}
                />
              }
            />
            <Route
              path='/batteries/operations/:id'
              element={
                <Dashboard
                  history={history}
                  selectedPage={'Operation'}
                  dashboardTechnology={'BATTERY'}
                />
              }
            />
            <Route
              path='/batteries/operations'
              element={
                <Dashboard
                  history={history}
                  selectedPage={'Operation'}
                  dashboardTechnology={'BATTERY'}
                />
              }
            />
            <Route
              path='/case_studies'
              element={
                <Dashboard
                  history={history}
                  selectedPage={'Case Studies'}
                  dashboardTechnology={'BATTERY'}
                />
              }
            />
            <Route
              path='/events'
              element={
                <Dashboard
                  history={history}
                  selectedPage={'Events'}
                  dashboardTechnology={'BATTERY'}
                />
              }
            />
            <Route
              path='/simulator'
              element={<Simulator />}
            />
            <Route path='/about' element={<About />} />
            <Route path='/faq' element={<FAQ />} />
            <Route
              path='/vpps'
              element={
                <Dashboard
                  history={history}
                  dashboardTechnology={'VPP'}
                  selectedPage={'Overview'}
                />
              }
            />
            <Route
              path='/vpps/revenue'
              element={
                <Dashboard
                  history={history}
                  dashboardTechnology={'VPP'}
                  selectedPage={'Revenue'}
                />
              }
            />
            <Route
              path='/vpps/operations/:id'
              element={
                <Dashboard
                  history={history}
                  selectedPage={'Operation'}
                  dashboardTechnology={'VPP'}
                />
              }
            />
            <Route
              path='/vpps/operations'
              element={
                <Dashboard
                  history={history}
                  selectedPage={'Operation'}
                  dashboardTechnology={'VPP'}
                />
              }
            />
          </Routes>
        </Router>
      </ViewportProvider>
    </CacheBuster>
  );
}

export default App;
