import React, { useEffect } from "react";

export enum ViewPortTypes {
  MOBILE = "mobile",
  TABLET = "tablet",
  DESKTOP = "desktop",
  DESKTOP_LG = "desktop-lg",
}

type ViewPortContextType = {
  type: ViewPortTypes;
  height: number;
};

const ViewportContext = React.createContext<ViewPortContextType>({
  type: ViewPortTypes.DESKTOP,
  height: -1,
});

export const ViewportProvider = ViewportContext.Provider;
export const ViewportConsumer = ViewportContext.Consumer;

export default ViewportContext;

export function useWindowSizeChange(onChange: EventListener) {
  useEffect(() => {
    window.addEventListener("resize", onChange);
    return () => window.removeEventListener("resize", onChange);
  });
}
