import {
  Avatar,
  Badge,
  Box,
  Button,
  Center,
  Flex,
  Grid,
  GridItem,
  HStack,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Spacer,
  Spinner,
  Text,
  useDisclosure
} from "@chakra-ui/react";
import { useContext, useEffect, useRef, useState } from "react";
import { OperationalChart, OperationalChartData } from "./OperationalChart";
import DatePicker from "react-datepicker";
import axios from "axios";
import "react-datepicker/dist/react-datepicker.css";
import { PriceChart, PriceChartData } from "./PriceChart";
import ViewportContext from "../styles/viewport-context";
import { BessInfoModal } from "./BessInfo";
import { BatteryCharging, Calendar2Week, ChevronLeft, ChevronRight } from "react-bootstrap-icons";
import customTheme from "../styles/theme";
import { InfoIcon } from "@chakra-ui/icons";
import { ElementProps } from "./Dashboard";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { BessStation, OperationsData, VPP } from "../api/api-types";

const HOST = process.env.REACT_APP_APIHOST;

function formatDate(date: Date) {
  let month = "" + (date.getMonth() + 1);
  let day = "" + date.getDate();
  const year = date.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
}

function addDays(date: Date, days: number) {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

export enum FCASType {
  Regulation = "REGULATION",
  Contingency = "CONTINGENCY",
}

type OperationState = {
  isLoaded: boolean;
  isOperationalChartLoaded: boolean;
  selectedStationImage: string | null;
  currentDaysData: OperationsData[];
  currentDate: Date | null;
  fcasType: FCASType;
  selectedStation: BessStation | VPP | null;
  hasData: boolean;
  operationalChartData: OperationalChartData[];
  priceChartData: PriceChartData[];
};

const INITIAL_STATE: OperationState = {
  isLoaded: false,
  isOperationalChartLoaded: false,
  selectedStationImage: null,
  currentDaysData: [],
  currentDate: null, // new Date(2020, 11, 1),
  fcasType: FCASType.Regulation, // or CONTINGENCY,
  selectedStation: null,
  hasData: true,
  operationalChartData: [],
  priceChartData: [],
};

async function fetchOperationalData(
  stationId: number,
  date: Date
): Promise<OperationsData[]> {
  try {
    const response = await axios.get(
      `${HOST}/power_stations/${stationId}/operations`,
      {
        params: {
          date: formatDate(date),
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
}

export function Operation(props: ElementProps) {
  const viewport = useContext(ViewportContext);
  const isSmallScreen = ["mobile", "tablet"].includes(viewport.type);

  const {stations, endDate, dashboardTechnology } = props;
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const stationId = id ? parseInt(id) : null;

  const datePicker = useRef<DatePicker>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [searchParams] = useSearchParams();
const dateString = searchParams.get("date");
  let date: Date;
  if (typeof dateString === "string") {
    date = new Date(dateString);
    INITIAL_STATE.currentDate = date;
  } else {
    INITIAL_STATE.currentDate = endDate;
  }

  INITIAL_STATE.fcasType =
    dashboardTechnology === "VPP" ? FCASType.Contingency : FCASType.Regulation;
  const [state, setState] = useState(INITIAL_STATE);

  useEffect(() => {
    async function fetchData(currentDate: Date, sId: number) {
      const data = await fetchOperationalData(sId, currentDate);
      const selectedStation: BessStation | VPP | undefined = stations.find(
        (element: VPP | BessStation) => element.id === sId
      );
      const operationalChartData: OperationalChartData[] = data.map((item) => ({
        settlementdate: new Date(item.settlementdate),
        lowerreg_mw: -item.lowerreg_mw,
        raisereg_mw: item.raisereg_mw,
        avg_raise_contingency_mw: item.avg_raise_contingency_mw,
        avg_lower_contingency_mw: -item.avg_lower_contingency_mw,
        charge_mw: -item.charge_mw,
        discharge_mw: item.discharge_mw,
      }));
      const priceChartData: PriceChartData[] = data.map((item) => ({
        settlementdate: new Date(item.settlementdate),
        wholesale_price: item.wholesale_price,
        raise_reg_price: item.raise_reg_price,
        lower_reg_price: item.lower_reg_price,
        avg_raise_contingency_price: item.avg_raise_contingency_price,
        avg_lower_contingency_price: item.avg_lower_contingency_price,
      }));
      setState((state) => ({
        ...state,
        isLoaded: true,
        currentDaysData: data,
        selectedStation: selectedStation || null,
        selectedBessStationDuids: selectedStation?.duids || [],
        selectedStationImage: selectedStation?.image || null,
        isOperationalChartLoaded: true,
        operationalChartData: operationalChartData,
        priceChartData: priceChartData,
      }));
    }
    if (state.currentDate && stationId && stations) {
      fetchData(state.currentDate, stationId);
    } else {
      setState((state) => ({
        ...state,
        currentDaysData: [],
        selectedStation: null,
        selectedStationImage: null,
      }));
    }
  }, [state.currentDate, stationId, stations]);

  function handleDateChange(newDate: Date | null) {
    setState({
      ...state,
      currentDate: newDate,
      isOperationalChartLoaded: false,
    });
  }

  function toggleFCASType(fcasType: FCASType) {
    setState({
      ...state,
      fcasType: fcasType,
    });
  }

  function onSelectStation(event: any) {
    setState({ ...state, isLoaded: false, isOperationalChartLoaded: false });
    const stationId = parseInt(event.target.value);
    const dashboardTechnologyToPrefix: any = {
      BATTERY: "batteries",
      VPP: "vpps",
    };
    navigate(
      `/${
        dashboardTechnologyToPrefix[dashboardTechnology.toUpperCase()]
      }/operations/${stationId}`
    );
  }

  function incrementDate() {
    setState({
      ...state,
      currentDate: state.currentDate && addDays(state.currentDate, 1),
      isOperationalChartLoaded: false,
    });
  }

  function decrementDate() {
    setState({
      ...state,
      currentDate: state.currentDate && addDays(state.currentDate, -1),
      isOperationalChartLoaded: false,
    });
  }

  function toggleDatePicker() {
    if (datePicker.current) {
      if (!datePicker.current.isCalendarOpen()) {
        datePicker.current.setOpen(true);
      }
    }
  }
  return (
    <Box>
      <Box paddingTop={2}>
        <HStack>
          <Avatar
            src={
              dashboardTechnology === "BATTERY"
                ? state.selectedStation?.image
                : `https://pybess-images.s3.ap-southeast-2.amazonaws.com/${stationId}-avatar.png`
            }
            ml={2}
            mr={2}
            size={isSmallScreen ? "md" : "xl"}
            icon={<BatteryCharging />}
            bg={"gray.200"}
          />

          <Box>
            <HStack spacing="5px">
              <Box>
                <Select
                  placeholder="Select option"
                  onChange={onSelectStation}
                  value={stationId || ""}
                  fontSize={isSmallScreen ? "sm" : "md"}
                  backgroundColor={"white"}
                >
                  {stations.map((station) => (
                    <option key={station.id} value={station.id}>
                      {station.station_name}
                    </option>
                  ))}
                </Select>
              </Box>
              <Box>
                {dashboardTechnology === "BATTERY" && (
                  <InfoIcon
                    color={customTheme.colors.pybessPurple}
                    onClick={onOpen}
                    w={5}
                    h={5}
                  />
                )}
              </Box>
              <Box>
                {state.selectedStation?.current_power_capacity && (
                  <Badge colorScheme={"purple"} size={"xl"} marginLeft={2}>
                    {state.selectedStation?.current_power_capacity}
                    MW
                  </Badge>
                )}
                {dashboardTechnology === "BATTERY" && !isSmallScreen && (
                  <Badge colorScheme={"purple"} size={"xl"} marginLeft={2}>
                    {!state.selectedStation
                      ? null
                      : "current_storage_capacity" in state.selectedStation
                      ? state.selectedStation.current_storage_capacity
                      : null}
                    MWh
                  </Badge>
                )}
              </Box>
            </HStack>
          </Box>
        </HStack>
        <Modal onClose={onClose} isOpen={isOpen} isCentered>
          <ModalOverlay />
          <ModalContent maxH="60vh" overflowY="auto">
            <ModalHeader>
              {" "}
              {state.selectedStation?.station_name || ""}
            </ModalHeader>
            <ModalCloseButton />
            {state.selectedStation && (
              <BessInfoModal selectedBessStation={state.selectedStation} />
            )}
            <ModalFooter>
              <Button onClick={onClose}>Close</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Box>
      <Grid paddingTop={2} gap={4} templateColumns={`repeat(1, 1fr)`}>
        <GridItem>
          <Box backgroundColor={"white"} borderRadius={10}>
            <Box display={"flex"} justifyContent={"flex-end"} padding={2}>
              {dashboardTechnology === "BATTERY" && (
                <Button
                  onClick={() => toggleFCASType(FCASType.Regulation)}
                  isActive={state.fcasType === FCASType.Regulation}
                  size={isSmallScreen ? "xs" : "md"}
                  margin={"1px"}
                  _focus={undefined}
                  _active={{
                    color: "white",
                    bgColor: customTheme.colors.pybessPurple,
                    borderColor: "white",
                  }}
                  variant={"outline"}
                >
                  Regulation
                </Button>
              )}

              <Button
                onClick={() => toggleFCASType(FCASType.Contingency)}
                isActive={state.fcasType === FCASType.Contingency}
                size={isSmallScreen ? "xs" : "md"}
                margin={"1px"}
                _focus={undefined}
                _active={{
                  color: "white",
                  bgColor: customTheme.colors.pybessPurple,
                  borderColor: "white",
                }}
                variant={"outline"}
              >
                Contingency
              </Button>
              <Spacer />
              <Flex>
                <Box cursor={"pointer"}>
                  <DatePicker
                    selected={state.currentDate}
                    onChange={(date) => handleDateChange(date)}
                    dateFormat={"do MMM yyyy"}
                    ref={datePicker}
                  />
                </Box>
                <Box onClick={decrementDate} cursor={"pointer"} paddingTop={1}>
                  <Center>
                    <ChevronLeft size={isSmallScreen ? 16 : 24} />
                  </Center>
                </Box>
                <Box
                  onClick={incrementDate}
                  cursor={"pointer"}
                  paddingRight={isSmallScreen ? 2 : 4}
                  paddingTop={1}
                >
                  <Center>
                    <ChevronRight size={isSmallScreen ? 16 : 24} />
                  </Center>
                </Box>
                <Spacer />
                <Box cursor={"pointer"} paddingRight={2} paddingTop={1}>
                  <Center>
                    <Calendar2Week
                      size={isSmallScreen ? 16 : 24}
                      onClick={toggleDatePicker}
                    />
                  </Center>
                </Box>
                <Spacer />
              </Flex>
            </Box>
            <Box paddingLeft={4} paddingBottom={4}>
              <Text size={isSmallScreen ? "xs" : "sm"}>Power (MW)</Text>
            </Box>

            {state.isOperationalChartLoaded || !stationId ? (
              <OperationalChart
                data={state.operationalChartData}
                fcasType={state.fcasType}
                isLoaded={state.isOperationalChartLoaded}
                setIsLoaded={() => {}}
              />
            ) : (
              <Box height={"400px"}>
                <Center>
                  <Spinner color={customTheme.colors.pybessPurple} />
                  <Text>Loading</Text>
                </Center>
              </Box>
            )}
          </Box>
        </GridItem>
        <GridItem>
          <Box backgroundColor={"white"} borderRadius={10}>
            <Box padding={4}>
              <Text size={isSmallScreen ? "xs" : "sm"}>Price $/MW(h)</Text>
            </Box>

            {state.isOperationalChartLoaded || !stationId ? (
              <PriceChart
                data={state.priceChartData}
                isLoaded={state.isOperationalChartLoaded}
              />
            ) : (
              <Box height={"400px"}>
                <Center>
                  <Spinner color={customTheme.colors.pybessPurple} />
                  <Text>Loading</Text>
                </Center>
              </Box>
            )}
          </Box>
        </GridItem>
      </Grid>
    </Box>
  );
}
