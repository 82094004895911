import { Box, Center, Code, Grid, GridItem, Heading, Image, Link, Text } from "@chakra-ui/react";
import { Envelope } from "react-bootstrap-icons";
import { Navbar } from "./Navbar";
import { useContext } from "react";
import ViewportContext from "../styles/viewport-context";
import NEMLogo from "../logo3";

const VIEWPORT_TYPE_TO_NUM_COLS = {
  mobile: 1,
  tablet: 2,
  desktop: 2,
  "desktop-lg": 2,
};

export function About() {
  const viewportType = useContext(ViewportContext).type;
  return (
    <Box>
      <Navbar activePage={"About"} />
      <Box
        position={"fixed"}
        top="80px"
        padding={4}
        width={"100%"}
        height={"calc(100% - 80px)"}
        overflow={"scroll"}
      >
        <Grid
          templateColumns={`repeat(${VIEWPORT_TYPE_TO_NUM_COLS[viewportType]}, 1fr)`}
        >
          <GridItem margin={"auto"}>
            <Center textAlign={"center"}>
              <Heading size={"3xl"}> About Us </Heading>
            </Center>
            <Center textAlign={"justify"} padding={1} maxWidth={"600px"}>
              <Text fontSize={"lg"} paddingTop={2}>
                <strong>NEMBESS</strong> is designed, developed and maintained
                by a community of enthusiasts in the energy industry with a keen
                interest in watching the current energy transition unfold.
                NEMBESS is currently being developed during our contributors'
                spare time, and hence makes no guarantees to the correctness of
                the publicly presented information.
              </Text>
            </Center>
          </GridItem>
          <GridItem>
            <Center margin={4}>
              <Box borderRadius={"50%"} bgColor={"navy"}>
                <Center>
                  <NEMLogo />
                </Center>
              </Box>
            </Center>
          </GridItem>
          <GridItem colSpan={VIEWPORT_TYPE_TO_NUM_COLS[viewportType]} pt={12}>
            <Center textAlign={"center"}>
              <Heading size={"xl"} fontWeight={"semibold"}>
                Contact Us
              </Heading>
            </Center>
            <Center textAlign={"center"}>
              <Text fontSize={"lg"} padding={1}>
                Got an idea for a new feature or want to collaborate? Let us
                know!
              </Text>
            </Center>
            <Center textAlign={"center"} padding={1}>
              <Grid
                templateColumns={`repeat(${VIEWPORT_TYPE_TO_NUM_COLS[viewportType]}, 1fr)`}
                gap={4}
              >
                <GridItem>
                  <Link href={"mailto:info@nembess.com"}>
                    <Center height={"70px"}>
                      <Envelope size={32} />
                    </Center>
                    <Text pt={2}>
                      <Code>info@nembess.com</Code>
                    </Text>
                  </Link>
                </GridItem>
                <GridItem>
                  <Center>
                    <Link
                      href={"https://github.com/dtam95/nembess/discussions"}
                      target={"_blank"}
                    >
                      <Image src={"github.png"} height={"70px"} />
                      <Text pt={2}>Discussion Forum</Text>
                    </Link>
                  </Center>
                </GridItem>
              </Grid>
            </Center>
          </GridItem>
        </Grid>
      </Box>
    </Box>
  );
}
