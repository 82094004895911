import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Box, ChakraProvider } from "@chakra-ui/react";
import customTheme from "./styles/theme";
import * as am4core from "@amcharts/amcharts4/core";
// Importing the Bootstrap CSS
import "bootstrap/dist/css/bootstrap.min.css";

am4core.addLicense("CH430708908");
am4core.addLicense("MP430708908");
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ChakraProvider theme={customTheme}>
    <Box backgroundColor="rgb(245, 246, 248)" minHeight="100vh">
      <App />
    </Box>
  </ChakraProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
