import { Sidebar, SIDEBAR_WIDTH } from "./Sidebar";
import { Box } from "@chakra-ui/react";
import { Navbar, NAVBAR_HEIGHT } from "./Navbar";
import { useContext, useEffect, useState } from "react";
import { Revenue } from "./Revenue";
import { PowerStationSummary } from "./PowerStationSummary";
import { Operation } from "./Operation";
import { CaseStudies } from "./CaseStudies";
import { Events } from "./Events";
import ViewportContext from "../styles/viewport-context";
import axios from "axios";
import * as H from "history";
import { BessStation, VPP } from "../api/api-types";
import { SidebarDrawer } from "./SidebarDrawer";

const APIHOST = process.env.REACT_APP_APIHOST;

export type DashboardLink = {
  linkText: string;
  linkComponent: (props: ElementProps) => JSX.Element;
  linkRoute: string;
};

export const DASHBOARD_LINKS: DashboardLink[] = [
  {
    linkText: "Overview",
    linkComponent: PowerStationSummary,
    linkRoute: "/batteries",
  },
  {
    linkText: "Revenue",
    linkComponent: Revenue,
    linkRoute: "/batteries/revenue",
  },
  {
    linkText: "Operation",
    linkComponent: Operation,
    linkRoute: "/batteries/operations",
  },
  {
    linkText: "Case Studies",
    linkComponent: CaseStudies,
    linkRoute: "/case_studies",
  },
  {
    linkText: "Events",
    linkComponent: Events,
    linkRoute: "/events",
  },
];

const vppDashboardLinks = [
  {
    linkText: "Overview",
    linkComponent: PowerStationSummary,
    linkRoute: "/vpps",
  },
  {
    linkText: "Revenue",
    linkComponent: Revenue,
    linkRoute: "/vpps/revenue",
  },
  {
    linkText: "Operation",
    linkComponent: Operation,
    linkRoute: "/vpps/operations",
  },
];

type DashboardState = {
  showSidebar: boolean;
  stations: VPP[] | BessStation[];
  endDate: Date | null;
  isDashboardUpdating: boolean;
};

type DashboardProps = {
  selectedPage: string;
  dashboardTechnology: "BATTERY" | "VPP";
  history: H.History;
};
const INITIAL_STATE: DashboardState = {
  showSidebar: false,
  stations: [],
  endDate: null,
  isDashboardUpdating: true,
};

export type ElementProps = {
  stations: VPP[] | BessStation[];
  dashboardTechnology: "BATTERY" | "VPP";
  history: H.History;
  endDate: Date | null;
  isDashboardUpdating: boolean;
};

async function fetchVPPs() {
  try {
    const response = await axios.get(`${APIHOST}/vpps`, {
      timeout: 10000,
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
}

async function fetchBessStations() {
  try {
    const response = await axios.get(`${APIHOST}/batteries`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
}

async function fetchEndDate() {
  try {
    const response = await axios.get(`${APIHOST}/revenue_last_date`);
    if (!response.data) {
      return new Date();
    }
    let arr = response.data.split(/[- T :]/);
    let date = new Date(arr[0], arr[1] - 1, arr[2], arr[3], arr[4], arr[5]);
    return date;
  } catch (error) {
    console.error(error);
  }
}

export function Dashboard(props: DashboardProps) {
  const { dashboardTechnology } = props;
  const [state, setState] = useState(INITIAL_STATE);

  function toggleSidebar() {
    setState({ ...state, showSidebar: !state.showSidebar });
  }
  const sidebarLinks =
    dashboardTechnology === "BATTERY" ? DASHBOARD_LINKS : vppDashboardLinks;

  const Element = sidebarLinks.find(
    (element: DashboardLink) => element.linkText === props.selectedPage
  )?.linkComponent;
  const { type: viewportType } = useContext(ViewportContext);
  const shouldShowSidebar = !["mobile", "tablet"].includes(viewportType);
  const elementProps: ElementProps = {
    stations: state.stations,
    dashboardTechnology: dashboardTechnology,
    history: props.history,
    endDate: state.endDate,
    isDashboardUpdating: state.isDashboardUpdating,
  };
  useEffect(() => {
    async function fetchStations() {
      setState((prevState) => ({ ...prevState, isDashboardUpdating: true }));
      const technology_type_to_fetch_function = {
        BATTERY: fetchBessStations,
        VPP: fetchVPPs,
      };
      const stations: BessStation[] = await technology_type_to_fetch_function[
        dashboardTechnology
      ]();
      const endDate = await fetchEndDate();
      setState((prevState) => ({
        ...prevState,
        stations: stations,
        endDate: endDate || null,
        isDashboardUpdating: false,
      }));
    }
    fetchStations();
  }, [dashboardTechnology]);

  return (
    <Box height={"100%"}>
      <Navbar
        showHamburger={!shouldShowSidebar}
        toggleSidebar={toggleSidebar}
        activePage={dashboardTechnology === "BATTERY" ? "BESS" : "VPP"}
      />
      {shouldShowSidebar ? (
        <Sidebar
          selectedPage={props.selectedPage}
          dashboardLinks={sidebarLinks}
          toggleSidebar={toggleSidebar}
          showSideBar={state.showSidebar}
        />
      ) : (
        <SidebarDrawer
          isOpen={true}
          dashboardLinks={sidebarLinks}
          selectedPage={props.selectedPage}
        />
      )}
      <Box
        position={"fixed"}
        top={`${NAVBAR_HEIGHT}px`}
        left={shouldShowSidebar ? SIDEBAR_WIDTH : "5px"}
        width={`calc(100vw - ${shouldShowSidebar ? SIDEBAR_WIDTH : 5}px)`}
        height={"calc(100vh - 80px)"}
        overflowY={"scroll"}
        p={[0, 4]}
      >
        {Element && <Element {...elementProps} />}
      </Box>
    </Box>
  );
}
