import {
  Box,
  Button,
  Center,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import customTheme from "../styles/theme";
import { pdfjs } from "react-pdf";
import { CaseStudyCard } from "./CaseStudyCard";
import { InfoIcon } from "@chakra-ui/icons";
import ViewportContext from "../styles/viewport-context";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const INITIAL_STATE = {
  isLoaded: false,
  caseStudies: [],
  bessStations: [],
};

const HOST = process.env.REACT_APP_APIHOST;

async function fetchCaseStudies() {
  try {
    const response = await axios.get(`${HOST}/case_studies`);
    const caseStudies = response.data.map((summary) => ({
      ...summary,
      name: summary.id,
    }));
    return caseStudies;
  } catch (error) {
    console.error(error);
  }
}

async function fetchBessStations() {
  try {
    const response = await axios.get(`${HOST}/batteries`);
    const bessMetadata = response.data.map((bess) => ({
      ...bess,
    }));
    return bessMetadata;
  } catch (error) {
    console.error(error);
  }
}

export function CaseStudies(props) {
  const [state, setState] = useState(INITIAL_STATE);
  const viewport = useContext(ViewportContext);
  const isSmallScreen = ["mobile", "tablet"].includes(viewport.type);
  const { isOpen, onOpen, onClose } = useDisclosure();
  useEffect(() => {
    async function fetchData() {
      const caseStudies = await fetchCaseStudies();
      const bessStations = await fetchBessStations();
      setState((state) => ({
        ...state,
        caseStudies: caseStudies,
        isLoaded: true,
        bessStations: bessStations,
      }));
    }
    fetchData();
  }, []);
  const SubText = (
    <Text padding={5} paddingLeft={1} paddingTop={2} textAlign={"justify"}>
      Nembess does its best to <strong>estimate</strong> BESS performance using
      publicly available data, however in reality the BESS's may have operated
      differently to our assumptions. This page examines some individual case
      studies, as a comparison between NEMBESS's estimated values and those
      reported by the BESS participants themselves.
    </Text>
  );
  return (
    <Box>
      <Box>
        <Flex>
          <Box>
            <Heading size={"lg"} padding={1}>
              Case Studies
            </Heading>
          </Box>
          {isSmallScreen && (
            <Center>
              <InfoIcon
                color={customTheme.colors.pybessPurple}
                onClick={onOpen}
                w={5}
                h={5}
              />
            </Center>
          )}
        </Flex>
        {!isSmallScreen && <Box>{SubText}</Box>}
        <Modal onClose={onClose} isOpen={isOpen} isCentered>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>NEMBESS Case Studies</ModalHeader>
            <ModalCloseButton />
            <ModalBody>{SubText}</ModalBody>
            <ModalFooter>
              <Button onClick={onClose}>Close</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Box>

      {state.isLoaded ? (
        <Tabs variant={"enclosed"} paddingTop={6}>
          <TabList>
            {state.caseStudies.map((case_study, i) => (
              <Tab
                key={i}
                _selected={{
                  bg: "white",
                  color: customTheme.colors.pybessPurple,
                }}
                _focus={null}
                borderTopRadius={"10px"}
              >
                {case_study.station_name.split(" ")[0]}
              </Tab>
            ))}
          </TabList>
          <TabPanels>
            {state.caseStudies.map((case_study, i) => (
              <TabPanel
                key={i}
                pb={4}
                marginBottom={4}
                bg={"white"}
                borderTopRadius={"10px"}
              >
                <CaseStudyCard
                  pdf_url={case_study.pdf_url}
                  pdf_page={case_study.pdf_page}
                  metrics={case_study.metrics}
                  station_name={case_study.station_name}
                  station_id={case_study.station_id}
                  image={
                    state.bessStations.filter(
                      (station) => station.id === case_study.station_id
                    )[0].image
                  }
                ></CaseStudyCard>
              </TabPanel>
            ))}
          </TabPanels>
        </Tabs>
      ) : (
        <Box>
          <Center mt={"30vh"}>
            <Spinner color={customTheme.colors.pybessPurple} />
            <Text>Loading</Text>
          </Center>
        </Box>
      )}
    </Box>
  );
}
