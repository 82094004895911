import { Box, Button, Center, Grid, GridItem, Heading, Image, Link, Progress } from "@chakra-ui/react";
import React, { useContext, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { NumericFormat } from "react-number-format";
import ViewportContext from "../styles/viewport-context";

const VIEWPORT_TYPE_TO_NUM_COLS = {
  mobile: 1,
  tablet: 1,
  desktop: 2,
  "desktop-lg": 2,
};

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export function CaseStudyCard(props) {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(props.pdf_page);
  const viewport = useContext(ViewportContext);
  const isMobile = ["mobile"].includes(viewport.type);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(props.pdf_page);
  }

  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  return (
    <Box p="4" maxW="100%" borderWidth="1px" borderRadius="lg" bg="white">
      <Center minH={"4rem"}>
        <Link href={`/batteries/${props.station_id}`}>
          <Heading as="h4" size="sm">
            {props.station_name}
          </Heading>
        </Link>
      </Center>
      <Grid
        templateColumns={`repeat(${
          VIEWPORT_TYPE_TO_NUM_COLS[viewport.type]
        }, 1fr)`}
      >
        <GridItem colSpan={1} p={"1rem"}>
          <Image borderRadius="lg" src={props.image} w={"100%"} />
          <Grid templateColumns={`repeat(${isMobile ? 3 : 4}, 1fr)`} p={2}>
            <GridItem
              fontWeight={"bold"}
              fontSize="sm"
              // backgroundColor={customTheme.colors.pybessPurple}
              // color={"white"}
            >
              <Center>Metric</Center>
            </GridItem>
            <GridItem
              fontWeight={"bold"}
              fontSize="sm"
              // backgroundColor={customTheme.colors.pybessPurple}
              // color={"white"}
            >
              <Center>Reported</Center>
            </GridItem>
            <GridItem
              fontWeight={"bold"}
              fontSize="sm"
              // backgroundColor={customTheme.colors.pybessPurple}
              // color={"white"}
            >
              <Center>Estimated</Center>
            </GridItem>
            {!isMobile && (
              <GridItem
                fontSize="sm"
                fontWeight={"bold"}
                // backgroundColor={customTheme.colors.pybessPurple}
                // color={"white"}
              >
                <Center>Error (%)</Center>
              </GridItem>
            )}
            {props.metrics.map((metric, i) => (
              <React.Fragment key={i}>
                <GridItem
                  backgroundColor={i % 2 === 0 ? "white" : "rgb(245, 245, 245)"}
                  paddingTop={1}
                  paddingBottom={1}
                  fontSize="sm"
                >
                  {metric.metric} ({metric.unit})
                </GridItem>
                <GridItem
                  backgroundColor={i % 2 === 0 ? "white" : "rgb(245, 245, 245)"}
                  paddingTop={1}
                  paddingBottom={1}
                  fontSize="sm"
                >
                  <Center>
                    <NumericFormat
                      value={metric.actual}
                      displayType={"text"}
                      thousandSeparator={true}
                      decimalScale={0}
                    />
                  </Center>
                </GridItem>
                <GridItem
                  backgroundColor={i % 2 === 0 ? "white" : "rgb(245, 245, 245)"}
                  paddingTop={1}
                  paddingBottom={1}
                  fontSize="sm"
                >
                  <Center>
                    <NumericFormat
                      value={metric.estimated}
                      displayType={"text"}
                      thousandSeparator={true}
                      decimalScale={0}
                    />
                  </Center>
                </GridItem>
                {!isMobile && (
                  <GridItem
                    fontSize="sm"
                    backgroundColor={
                      i % 2 === 0 ? "white" : "rgb(245, 245, 245)"
                    }
                    paddingTop={1}
                    paddingBottom={1}
                  >
                    {metric.percent_error && (
                      <>
                        <Center>
                          <NumericFormat
                            value={Math.abs(metric.percent_error)}
                            displayType={"text"}
                            decimalScale={2}
                            suffix={"%"}
                          />
                        </Center>
                        <Progress
                          colorScheme="red"
                          size="xs"
                          max={100}
                          value={Math.abs(metric.percent_error)}
                        />
                      </>
                    )}
                  </GridItem>
                )}
              </React.Fragment>
            ))}
          </Grid>
          <p>
            Reported Source: <Link> https://arena.gov.au/knowledge-bank/</Link>
          </p>
          {/*<Table*/}
          {/*  size="sm"*/}
          {/*  variant="striped"*/}
          {/*  colorScheme="gray"*/}
          {/*  className={isMobile && "table-tiny"}*/}
          {/*>*/}
          {/*  <TableCaption></TableCaption>*/}
          {/*  <Thead>*/}
          {/*    <Tr>*/}
          {/*      <Th w={"50px"}>Metric</Th>*/}
          {/*      <Th w={"50px"}>Reported</Th>*/}
          {/*      <Th w={"50px"}>Estimated</Th>*/}
          {/*      {!isMobile && <Th isNumeric>Error (%)</Th>}*/}
          {/*    </Tr>*/}
          {/*  </Thead>*/}
          {/*  <Tbody>*/}
          {/*    {props.metrics.map((metric, i) => (*/}
          {/*      <Tr>*/}
          {/*        <Td>*/}
          {/*          {metric.metric} ({metric.unit})*/}
          {/*        </Td>*/}
          {/*        <Td>*/}
          {/*          <NumberFormat*/}
          {/*            value={metric.actual}*/}
          {/*            displayType={"text"}*/}
          {/*            thousandSeparator={true}*/}
          {/*            decimalScale={0}*/}
          {/*          />*/}
          {/*        </Td>*/}
          {/*        <Td>*/}
          {/*          <NumberFormat*/}
          {/*            value={metric.estimated}*/}
          {/*            displayType={"text"}*/}
          {/*            thousandSeparator={true}*/}
          {/*            decimalScale={0}*/}
          {/*          />*/}
          {/*        </Td>*/}
          {/*        {!isMobile && (*/}
          {/*          <Td isNumeric>*/}
          {/*            <NumberFormat*/}
          {/*              value={metric.percent_error}*/}
          {/*              displayType={"text"}*/}
          {/*              decimalScale={2}*/}
          {/*              suffix={"%"}*/}
          {/*            />*/}
          {/*            <Progress*/}
          {/*              colorScheme="red"*/}
          {/*              size="xs"*/}
          {/*              max={100}*/}
          {/*              value={Math.abs(metric.percent_error)}*/}
          {/*            />*/}
          {/*          </Td>*/}
          {/*        )}*/}
          {/*      </Tr>*/}
          {/*    ))}*/}
          {/*  </Tbody>*/}
          {/*</Table>*/}
        </GridItem>
        <GridItem colSpan={1}>
          <Box borderWidth="1px" borderRadius="lg">
            <Center p="2">
              <Button
                type="button"
                disabled={pageNumber <= 1}
                onClick={previousPage}
              >
                Prev
              </Button>
              <Button
                m={2}
                type="button"
                disabled={pageNumber >= numPages}
                onClick={nextPage}
              >
                Next
              </Button>
            </Center>
            <Box id={"pdf-container"}>
              <Document
                file={props.pdf_url}
                onLoadSuccess={onDocumentLoadSuccess}
                className={"PDFDocument"}
              >
                <Page className={"PDFPage"} pageNumber={pageNumber} />
              </Document>
            </Box>
            <Center>
              <div>
                <p>
                  Page {pageNumber || (numPages ? 1 : "--")} of{" "}
                  {numPages || "--"}
                </p>
              </div>
            </Center>
          </Box>
        </GridItem>
      </Grid>
    </Box>
  );
}
