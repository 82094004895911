import { AbsoluteCenter, Box, Center, Flex, Grid, GridItem, Heading, Image, Link, Text } from '@chakra-ui/react';
import { Navbar, NAVBAR_HEIGHT } from './Navbar';
import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { SummaryCard } from './SummaryCard';
import { Carousel } from 'react-bootstrap';
import ViewportContext from '../styles/viewport-context';
import customTheme from '../styles/theme';
import { MapChart } from './MapChart';
import { BatteryCharging } from 'react-bootstrap-icons';
import { BessStation, RevenueTotal } from '../api/api-types';
import { NembessSpinner } from './designSystem/NembessSpinner';
import ReactGA from 'react-ga4';

type HomeState = {
  isLoaded: boolean;
  bessStations: BessStation[];
  revenueTotals: RevenueTotal[];
  bessStationInFocus: number;
};

const INITIAL_STATE: HomeState = {
  isLoaded: false,
  bessStations: [],
  revenueTotals: [],
  bessStationInFocus: 1,
};

const APIHOST = process.env.REACT_APP_APIHOST;

async function fetchBessStations() {
  try {
    const response = await axios.get(`${APIHOST}/batteries`, {
      timeout: 10000,
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
}

async function fetchRevenueTotals() {
  try {
    const response = await axios.get(`${APIHOST}/revenue_totals`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
}

export function Home(props: any) {
  const [state, setState] = useState(INITIAL_STATE);
  useEffect(() => {
    async function fetchData() {
      const bessStations = await fetchBessStations();
      const revenueTotals = await fetchRevenueTotals();
      setState((state) => ({
        ...state,
        bessStations: bessStations,
        revenueTotals: revenueTotals,
        isLoaded: true,
      }));
    }

    fetchData();
  }, []);
  const viewport = useContext(ViewportContext);
  const isSmallScreen = ['mobile', 'tablet'].includes(viewport.type);

  function handleCarouselChange(eventKey: number, direction: any) {
    setState({ ...state, bessStationInFocus: state.bessStations[eventKey].id });
  }

  return (
    <Box
      backgroundColor={customTheme.colors.backgroundGray}
      backgroundRepeat='no-repeat'
      bgPos='center'
      minHeight='100vh'
      width='100%'
      backgroundSize='cover'
      overflow='hidden'
    >
      <Navbar activePage={'Home'} />
      {state.bessStations.length > 0 ? (
        <Grid templateColumns='repeat(3, 1fr)' gap={6} marginTop={`${NAVBAR_HEIGHT}px`}>
          {!isSmallScreen && (
            <GridItem colSpan={1}>
              <Box w='100%' margin='50px'>
                <Center>
                  <Heading
                    className={'title'}
                    fontSize='4em'
                    fontFamily='Sans-Serif'
                    bgGradient='linear(to-l, #6a008f, #c31fff)'
                    bgClip='text'
                    fontWeight='extrabold'
                  >
                    NEM BESS
                  </Heading>
                </Center>
                <Text>
                  A bird's eye view on battery energy storage systems (BESS) operating in Australia's National
                  Electricity Market (NEM).
                </Text>
                <Carousel indicators={false} variant={'dark'} onSlide={handleCarouselChange}>
                  {state.bessStations.map((bess, i) => (
                    <Carousel.Item key={bess.id}>
                      <Box
                        ml={'60px'}
                        mr={'60px'}
                        mt={'40px'}
                        mb={'40px'}
                        _hover={{
                          color: customTheme.colors.pybessPurple,
                          boxShadow: 'rgba(121, 40, 202, 0.3) 0px 5px 15px',
                        }}
                        borderRadius={20}
                      >
                        <Link
                          href={`/batteries/revenue/${bess.id}`}
                          _hover={{
                            color: customTheme.colors.pybessPurple,
                          }}
                        >
                          <SummaryCard
                            key={i}
                            bessName={bess.display_name}
                            powerCapacity={bess.current_power_capacity}
                            storageCapacity={bess.current_storage_capacity}
                            revenue={
                              state.revenueTotals.filter((summary) => {
                                return summary.station_id === bess.id;
                              })[0].total_revenue
                            }
                            imageUrl={bess.image}
                            incrementImageLoad={() => {
                            }}
                          />
                        </Link>
                      </Box>
                    </Carousel.Item>
                  ))}
                </Carousel>
              </Box>
            </GridItem>
          )}
          <GridItem colSpan={isSmallScreen ? 3 : 2}>
            <Box w='100%'>
              <MapChart data={state.bessStations} bessStationInFocus={state.bessStationInFocus} />
            </Box>
            {!isSmallScreen && (
              <Box position={'absolute'} bottom={'5px'} right={'15px'}>
                <Flex justifyContent={'flex-end'}>
                  <Flex flexDirection={'column'} alignItems={'center'}>
                    <Box><Text mb={0}>Sponsored By</Text></Box>

                    <Box>
                      <Link
                        href={'https://www.energyone.com/'}
                        onClick={() => {
                          ReactGA.event({
                            category: 'energyone',
                            action: 'energyone_click_through',
                          });
                        }}
                      >
                        <Image
                          src={'energyone-logo.svg'}
                          maxWidth={'260px'}
                          width={isSmallScreen ? '140px' : 'auto'}
                          height={isSmallScreen ? '20px' : '90px'}
                        />
                      </Link>
                    </Box>
                  </Flex>
                </Flex></Box>)}
            {isSmallScreen && (
              <Box
                className='mobile-carousel'
                style={{
                  width: '250px',
                  marginTop: `-20vh`,
                  marginLeft: `40px`,
                  minHeight: '7vh',
                }}
              >
                <Carousel indicators={false} controls={false} variant={'light'} onSlide={handleCarouselChange}>
                  {state.bessStations.map((bess, i) => (
                    <Carousel.Item key={bess.id}>
                      <Box ml={1} mb={4}>
                        <Link
                          href={`/batteries/revenue/${bess.id}`}
                          _hover={{
                            color: customTheme.colors.pybessPurple,
                          }}
                        >
                          <Flex alignItems={'center'}>
                            <BatteryCharging
                              style={{
                                color: customTheme.colors.pybessPurple,
                                fontSize: '1.2rem',
                              }}
                            />
                            <Text
                              pl={1}
                              fontSize={'1.1rem'}
                              mb={0}
                              style={{
                                color: customTheme.colors.pybessPurple,
                                fontWeight: `bold`,
                              }}
                            >
                              {' '}
                              {bess.display_name}{' '}
                            </Text>
                          </Flex>
                        </Link>
                      </Box>
                    </Carousel.Item>
                  ))}
                </Carousel>
              </Box>
            )}
            {isSmallScreen && (
              <Box
                paddingLeft={'2rem'}
                backgroundColor={customTheme.colors.backgroundGray}
                style={{
                  width: '100%',
                }}
              >
                <Heading
                  size={'xl'}
                  fontFamily='Sans-Serif'
                  bgGradient='linear(to-l, #6a008f, #c31fff)'
                  bgClip='text'
                  fontWeight='extrabold'
                >
                  NEM BESS
                </Heading>
                <Text marginTop={'1rem'} fontSize={'1rem'} maxWidth={'70vw'} textAlign={'justify'}>
                  A bird's eye view on Battery Energy Storage Systems (BESS) operating in the NEM
                </Text>

                <Flex justifyContent={'flex-end'} pt={2}>
                  <Flex flexDirection={'column'} alignItems={'center'}>
                    <Box><Text>Sponsored By</Text></Box>

                    <Box>
                      <Link
                        href={'https://www.energyone.com/'}
                        onClick={() => {
                          ReactGA.event({
                            category: 'energyone',
                            action: 'energyone_click_through',
                          });
                        }}
                      >
                        <Image
                          src={'energyone-logo.svg'}
                          maxWidth={'260px'}
                          width={isSmallScreen ? '140px' : 'auto'}
                          height={isSmallScreen ? '20px' : '90px'}
                        />
                      </Link>
                    </Box>
                  </Flex>
                </Flex>

              </Box>
            )}
          </GridItem>
        </Grid>
      ) : (
        <AbsoluteCenter><NembessSpinner /><Text>Loading</Text></AbsoluteCenter>
      )}
    </Box>
  );
}
