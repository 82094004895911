import React, { useContext, useLayoutEffect, useRef } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import { XYChart } from "@amcharts/amcharts4/charts";
import ViewportContext from "../styles/viewport-context";

export type PriceChartData = {
  settlementdate: Date;
  wholesale_price: number;
  raise_reg_price: number;
  lower_reg_price: number;
  avg_raise_contingency_price: number;
  avg_lower_contingency_price: number;
};

type PriceChartProps = {
  data: PriceChartData[];
  isLoaded: boolean;
};

export function PriceChart(props: PriceChartProps) {
  const chart = useRef<XYChart | null>(null);
  const viewport = useContext(ViewportContext);
  const isSmallScreen = ["mobile", "tablet"].includes(viewport.type);

  const { data } = props;

  useLayoutEffect(() => {
    let x = am4core.create("price-chartdiv", am4charts.XYChart);
    x.data = data;
    let dateAxis = x.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.grid.template.disabled = true;
    dateAxis.dateFormats.setKey("hour", "H");
    dateAxis.periodChangeDateFormats.setKey("hour", "H");
    let valueAxis = x.yAxes.push(new am4charts.ValueAxis());
    valueAxis.renderer.grid.template.disabled = false;
    valueAxis.cursorTooltipEnabled = false;
    x.exporting.menu = new am4core.ExportMenu();
    x.zoomOutButton.align = "left";
    x.zoomOutButton.valign = "top";
    x.zoomOutButton.background.fill = am4core.color("#25283D");
    x.zoomOutButton.icon.stroke = am4core.color("#EFD9CE");
    x.zoomOutButton.icon.strokeWidth = 2;
    const backgroundHover = x.zoomOutButton.background.states.getKey("hover");
    if (backgroundHover) {
      backgroundHover.properties.fill = am4core.color("#8e2eb2");
    }

    // Bunch of shit to get the axis title display above the y axis
    dateAxis.title.text = "Time of Day";

    let wholesaleSeries = x.series.push(new am4charts.LineSeries());
    wholesaleSeries.dataFields.valueY = "wholesale_price";
    wholesaleSeries.dataFields.dateX = "settlementdate";
    wholesaleSeries.tooltipText = "{value}";
    wholesaleSeries.name = "Wholesale";
    wholesaleSeries.tooltipText = "Wholesale: [bold]{valueY}[/] $/MWh";
    wholesaleSeries.strokeWidth = 1;

    let raiseRegSeries = x.series.push(new am4charts.LineSeries());
    raiseRegSeries.dataFields.valueY = "raise_reg_price";
    raiseRegSeries.dataFields.dateX = "settlementdate";
    raiseRegSeries.tooltipText = "{value}";
    raiseRegSeries.name = "Raise Reg";
    raiseRegSeries.tooltipText = "Raise Reg: [bold]{valueY}[/] $/MW";
    raiseRegSeries.strokeWidth = 1;

    let lowerRegSeries = x.series.push(new am4charts.LineSeries());
    lowerRegSeries.dataFields.valueY = "lower_reg_price";
    lowerRegSeries.dataFields.dateX = "settlementdate";
    lowerRegSeries.tooltipText = "{value}";
    lowerRegSeries.name = "Lower Reg";
    lowerRegSeries.tooltipText = "Lower Reg: [bold]{valueY}[/] $/MW";
    lowerRegSeries.strokeWidth = 1;

    let raiseContingencySeries = x.series.push(new am4charts.LineSeries());
    raiseContingencySeries.dataFields.valueY = "avg_raise_contingency_price";
    raiseContingencySeries.dataFields.dateX = "settlementdate";
    raiseContingencySeries.tooltipText = "{value}";
    raiseContingencySeries.name = "Avg Raise Contingency";
    raiseContingencySeries.tooltipText =
      "Raise Contingency: [bold]{valueY}[/] $/MW";
    raiseRegSeries.strokeWidth = 1;

    let lowerContingencySeries = x.series.push(new am4charts.LineSeries());
    lowerContingencySeries.dataFields.valueY = "avg_lower_contingency_price";
    lowerContingencySeries.dataFields.dateX = "settlementdate";
    lowerContingencySeries.tooltipText = "{value}";
    lowerContingencySeries.name = "Avg Lower Contingency";
    lowerContingencySeries.tooltipText =
      "Lower Contingency: [bold]{valueY}[/] $/MW";
    lowerContingencySeries.strokeWidth = 1;

    // x.legend = new am4charts.Legend();
    // x.legend.position = "bottom";
    x.cursor = new am4charts.XYCursor();
    x.cursor.lineY.disabled = true;
    x.cursor.lineX.disabled = true;

    if (isSmallScreen) {
      valueAxis.renderer.labels.template.fontSize = 10;
      valueAxis.renderer.labels.template.paddingLeft = 0;
      //valueAxis.strictMinMax = true;
    }
    x.legend = new am4charts.Legend();
    x.legend.labels.template.fontSize = `${isSmallScreen ? 8 : 14}`;
    x.legend.itemContainers.template.padding(3, 0, 3, 0);
    x.legend.itemContainers.template.margin(0, 1, 0, 1);
    x.legend.useDefaultMarker = true;

    //const valueAxis = x._yAxes._values[0];
    if (isSmallScreen) {
      valueAxis.renderer.labels.template.fontSize = 10;
      valueAxis.renderer.labels.template.paddingLeft = 0;
      //valueAxis.strictMinMax = true;
      dateAxis.renderer.minGridDistance = 30;
    } else {
      valueAxis.renderer.labels.template.fontSize = null;
      valueAxis.renderer.labels.template.paddingLeft = 0;
      valueAxis.strictMinMax = false;
      dateAxis.renderer.minGridDistance = 70;
    }
    chart.current = x;
    return () => {
      x.dispose();
    };
  }, [data, isSmallScreen]);

  return (
    <>
      <div id="price-chartdiv" style={{ width: "100%", height: "400px" }} />
      <div id="price-legendwrapper" style={{ overflowX: "auto" }}>
        <div id="price-legenddiv" />
      </div>
    </>
  );
}
