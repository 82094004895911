import * as React from "react";

const NEMLogo = (props) => (
  <svg
    width={288}
    height={288}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={288}
      height={288}
      viewBox="0 0 375 375"
      {...props}
    >
      <defs>
        <clipPath id="h">
          <path
            d="M226.781 43.547 318.562.387s11.688-2.946 5.266 7.238c-6.426 10.18-98.836 148.004-98.836 148.004l-36.226-78.262Zm0 0"
            className="color000 svgShape"
          />
        </clipPath>
        <clipPath id="a">
          <path
            d="M49.277 219H187v156H49.277Zm0 0"
            className="color000 svgShape"
          />
        </clipPath>
        <clipPath id="n">
          <path
            d="M228.93 42.531 115.219 95.992s-19.781 10.156-10.649 29.457l60.91 129.117s29.758 54.235-30.164 83.45c28.594-13.461 118.516-56.043 125.157-59.18 6.644-3.133 15.992-19.684 7.898-36.813-8.984-19.082-53.137-113.945-53.137-113.945s-30.117-53.605 13.696-85.547Zm0 0"
            className="color000 svgShape"
          />
        </clipPath>
        <clipPath id="d">
          <path d="M128 227h57v111h-57Zm0 0" className="color000 svgShape" />
        </clipPath>
        <clipPath id="e">
          <path
            d="m147.922 227.258-19.152 27.285s42.992 37.828 7.101 83.039l48.691-22.894s-3.89-85.91-36.64-87.43Zm0 0"
            className="color000 svgShape"
          />
        </clipPath>
        <clipPath id="m">
          <path d="M102 42h170v297H102Zm0 0" className="color000 svgShape" />
        </clipPath>
        <clipPath id="g">
          <path d="M188 0h138v156H188Zm0 0" className="color000 svgShape" />
        </clipPath>
        <clipPath id="b">
          <path
            d="m148.574 331.453-91.781 43.16s-11.688 2.946-5.262-7.238c6.422-10.18 98.758-148.004 98.758-148.004l36.23 78.262Zm0 0"
            className="color000 svgShape"
          />
        </clipPath>
        <clipPath id="k">
          <path
            d="m229.219 147.43 19.156-27.285s-42.992-37.829-7.102-83.016l-48.691 22.894s3.887 85.91 36.637 87.407Zm0 0"
            className="color000 svgShape"
          />
        </clipPath>
        <clipPath id="j">
          <path d="M192 37h57v111h-57Zm0 0" className="color000 svgShape" />
        </clipPath>
        <linearGradient
          xmlnsXlink="http://www.w3.org/1999/xlink"
          id="i"
          x1={-324.16}
          x2={-324.16}
          y1={2445.334}
          y2={2366.438}
          gradientTransform="matrix(1.02875 2.18272 -2.18522 1.02758 5851.094 -1680.865)"
          gradientUnits="userSpaceOnUse"
          xlinkActuate="onLoad"
          xlinkShow="other"
          xlinkType="simple"
        >
          <stop
            offset={0}
            stopColor="#8600b7"
            className="stopColor1b489c svgShape"
          />
          <stop
            offset={0.125}
            stopColor="#8600b7"
            className="stopColor1b489c svgShape"
          />
          <stop
            offset={0.156}
            stopColor="#8600b7"
            className="stopColor1b489c svgShape"
          />
          <stop
            offset={0.172}
            stopColor="#8600b7"
            className="stopColor1b489c svgShape"
          />
          <stop
            offset={0.176}
            stopColor="#8600b7"
            className="stopColor1b489c svgShape"
          />
          <stop
            offset={0.18}
            stopColor="#8700b8"
            className="stopColor1b499d svgShape"
          />
          <stop
            offset={0.184}
            stopColor="#8700b8"
            className="stopColor1b4a9d svgShape"
          />
          <stop
            offset={0.188}
            stopColor="#8800ba"
            className="stopColor1c4a9e svgShape"
          />
          <stop
            offset={0.191}
            stopColor="#8800ba"
            className="stopColor1c4b9e svgShape"
          />
          <stop
            offset={0.195}
            stopColor="#8800ba"
            className="stopColor1c4b9e svgShape"
          />
          <stop
            offset={0.199}
            stopColor="#8900bb"
            className="stopColor1c4c9f svgShape"
          />
          <stop
            offset={0.203}
            stopColor="#8900bb"
            className="stopColor1c4d9f svgShape"
          />
          <stop
            offset={0.207}
            stopColor="#8a00bc"
            className="stopColor1c4da0 svgShape"
          />
          <stop
            offset={0.211}
            stopColor="#8a00bc"
            className="stopColor1c4ea0 svgShape"
          />
          <stop
            offset={0.215}
            stopColor="#8a00bc"
            className="stopColor1c4ea0 svgShape"
          />
          <stop
            offset={0.219}
            stopColor="#8b00be"
            className="stopColor1d4fa1 svgShape"
          />
          <stop
            offset={0.223}
            stopColor="#8b00be"
            className="stopColor1d4fa1 svgShape"
          />
          <stop
            offset={0.227}
            stopColor="#8c00bf"
            className="stopColor1d50a2 svgShape"
          />
          <stop
            offset={0.23}
            stopColor="#8c00bf"
            className="stopColor1d51a2 svgShape"
          />
          <stop
            offset={0.234}
            stopColor="#8c00bf"
            className="stopColor1d51a2 svgShape"
          />
          <stop
            offset={0.238}
            stopColor="#8d00c0"
            className="stopColor1d52a3 svgShape"
          />
          <stop
            offset={0.242}
            stopColor="#8d00c0"
            className="stopColor1d52a3 svgShape"
          />
          <stop
            offset={0.246}
            stopColor="#8e00c1"
            className="stopColor1d53a4 svgShape"
          />
          <stop
            offset={0.25}
            stopColor="#8e00c2"
            className="stopColor1e54a4 svgShape"
          />
          <stop
            offset={0.254}
            stopColor="#8e00c2"
            className="stopColor1e54a4 svgShape"
          />
          <stop
            offset={0.258}
            stopColor="#8f00c3"
            className="stopColor1e55a5 svgShape"
          />
          <stop
            offset={0.262}
            stopColor="#8f00c3"
            className="stopColor1e55a5 svgShape"
          />
          <stop
            offset={0.266}
            stopColor="#9000c4"
            className="stopColor1e56a6 svgShape"
          />
          <stop
            offset={0.27}
            stopColor="#9000c4"
            className="stopColor1e57a6 svgShape"
          />
          <stop
            offset={0.273}
            stopColor="#9000c4"
            className="stopColor1e57a6 svgShape"
          />
          <stop
            offset={0.277}
            stopColor="#9000c5"
            className="stopColor1e58a7 svgShape"
          />
          <stop
            offset={0.281}
            stopColor="#9100c6"
            className="stopColor1f58a7 svgShape"
          />
          <stop
            offset={0.285}
            stopColor="#9200c7"
            className="stopColor1f59a8 svgShape"
          />
          <stop
            offset={0.289}
            stopColor="#9200c7"
            className="stopColor1f59a8 svgShape"
          />
          <stop
            offset={0.293}
            stopColor="#9200c7"
            className="stopColor1f5aa8 svgShape"
          />
          <stop
            offset={0.297}
            stopColor="#9300c8"
            className="stopColor1f5ba9 svgShape"
          />
          <stop
            offset={0.301}
            stopColor="#9300c8"
            className="stopColor1f5ba9 svgShape"
          />
          <stop
            offset={0.305}
            stopColor="#9300c9"
            className="stopColor1f5caa svgShape"
          />
          <stop
            offset={0.309}
            stopColor="#9300c9"
            className="stopColor1f5caa svgShape"
          />
          <stop
            offset={0.313}
            stopColor="#9400ca"
            className="stopColor205daa svgShape"
          />
          <stop
            offset={0.316}
            stopColor="#9500cb"
            className="stopColor205eab svgShape"
          />
          <stop
            offset={0.32}
            stopColor="#9500cb"
            className="stopColor205eab svgShape"
          />
          <stop
            offset={0.324}
            stopColor="#9600cc"
            className="stopColor205fac svgShape"
          />
          <stop
            offset={0.328}
            stopColor="#9600cc"
            className="stopColor205fac svgShape"
          />
          <stop
            offset={0.332}
            stopColor="#9600cc"
            className="stopColor2060ac svgShape"
          />
          <stop
            offset={0.336}
            stopColor="#9600cd"
            className="stopColor2061ad svgShape"
          />
          <stop
            offset={0.34}
            stopColor="#9700ce"
            className="stopColor2161ad svgShape"
          />
          <stop
            offset={0.344}
            stopColor="#9800cf"
            className="stopColor2162ae svgShape"
          />
          <stop
            offset={0.348}
            stopColor="#9800cf"
            className="stopColor2162ae svgShape"
          />
          <stop
            offset={0.352}
            stopColor="#9800cf"
            className="stopColor2163ae svgShape"
          />
          <stop
            offset={0.355}
            stopColor="#9900d0"
            className="stopColor2164af svgShape"
          />
          <stop
            offset={0.359}
            stopColor="#9900d0"
            className="stopColor2164af svgShape"
          />
          <stop
            offset={0.363}
            stopColor="#9900d1"
            className="stopColor2165b0 svgShape"
          />
          <stop
            offset={0.367}
            stopColor="#9900d1"
            className="stopColor2165b0 svgShape"
          />
          <stop
            offset={0.371}
            stopColor="#9a00d2"
            className="stopColor2266b0 svgShape"
          />
          <stop
            offset={0.375}
            stopColor="#9b00d3"
            className="stopColor2266b1 svgShape"
          />
          <stop
            offset={0.379}
            stopColor="#9b00d3"
            className="stopColor2267b1 svgShape"
          />
          <stop
            offset={0.383}
            stopColor="#9b00d4"
            className="stopColor2268b2 svgShape"
          />
          <stop
            offset={0.387}
            stopColor="#9b00d4"
            className="stopColor2268b2 svgShape"
          />
          <stop
            offset={0.391}
            stopColor="#9b00d4"
            className="stopColor2269b2 svgShape"
          />
          <stop
            offset={0.395}
            stopColor="#9c00d5"
            className="stopColor2269b3 svgShape"
          />
          <stop
            offset={0.398}
            stopColor="#9c00d5"
            className="stopColor226ab3 svgShape"
          />
          <stop
            offset={0.402}
            stopColor="#9d00d6"
            className="stopColor236bb3 svgShape"
          />
          <stop
            offset={0.406}
            stopColor="#9e00d7"
            className="stopColor236bb4 svgShape"
          />
          <stop
            offset={0.41}
            stopColor="#9e00d7"
            className="stopColor236cb4 svgShape"
          />
          <stop
            offset={0.414}
            stopColor="#9e00d8"
            className="stopColor236cb5 svgShape"
          />
          <stop
            offset={0.418}
            stopColor="#9e00d8"
            className="stopColor236db5 svgShape"
          />
          <stop
            offset={0.422}
            stopColor="#9e00d8"
            className="stopColor236eb5 svgShape"
          />
          <stop
            offset={0.426}
            stopColor="#9f00d9"
            className="stopColor236eb6 svgShape"
          />
          <stop
            offset={0.43}
            stopColor="#9f00d9"
            className="stopColor236fb6 svgShape"
          />
          <stop
            offset={0.434}
            stopColor="#a100db"
            className="stopColor246fb7 svgShape"
          />
          <stop
            offset={0.438}
            stopColor="#a100db"
            className="stopColor2470b7 svgShape"
          />
          <stop
            offset={0.441}
            stopColor="#a100db"
            className="stopColor2471b7 svgShape"
          />
          <stop
            offset={0.445}
            stopColor="#a100dc"
            className="stopColor2471b8 svgShape"
          />
          <stop
            offset={0.449}
            stopColor="#a100dc"
            className="stopColor2472b8 svgShape"
          />
          <stop
            offset={0.453}
            stopColor="#a200dd"
            className="stopColor2472b9 svgShape"
          />
          <stop
            offset={0.457}
            stopColor="#a200dd"
            className="stopColor2473b9 svgShape"
          />
          <stop
            offset={0.461}
            stopColor="#a300de"
            className="stopColor2573b9 svgShape"
          />
          <stop
            offset={0.465}
            stopColor="#a400df"
            className="stopColor2574ba svgShape"
          />
          <stop
            offset={0.469}
            stopColor="#a400df"
            className="stopColor2575ba svgShape"
          />
          <stop
            offset={0.473}
            stopColor="#a400e0"
            className="stopColor2575bb svgShape"
          />
          <stop
            offset={0.477}
            stopColor="#a400e0"
            className="stopColor2576bb svgShape"
          />
          <stop
            offset={0.48}
            stopColor="#a400e0"
            className="stopColor2576bb svgShape"
          />
          <stop
            offset={0.484}
            stopColor="#a500e1"
            className="stopColor2577bc svgShape"
          />
          <stop
            offset={0.488}
            stopColor="#a500e1"
            className="stopColor2578bc svgShape"
          />
          <stop
            offset={0.492}
            stopColor="#a600e3"
            className="stopColor2678bd svgShape"
          />
          <stop
            offset={0.496}
            stopColor="#a600e3"
            className="stopColor2679bd svgShape"
          />
          <stop
            offset={0.5}
            stopColor="#a600e3"
            className="stopColor2679bd svgShape"
          />
          <stop
            offset={0.504}
            stopColor="#a700e4"
            className="stopColor267abe svgShape"
          />
          <stop
            offset={0.508}
            stopColor="#a700e4"
            className="stopColor267bbe svgShape"
          />
          <stop
            offset={0.512}
            stopColor="#a800e5"
            className="stopColor267bbf svgShape"
          />
          <stop
            offset={0.516}
            stopColor="#a800e5"
            className="stopColor267cbf svgShape"
          />
          <stop
            offset={0.52}
            stopColor="#a800e5"
            className="stopColor267cbf svgShape"
          />
          <stop
            offset={0.523}
            stopColor="#a900e7"
            className="stopColor277dc0 svgShape"
          />
          <stop
            offset={0.527}
            stopColor="#a900e7"
            className="stopColor277ec0 svgShape"
          />
          <stop
            offset={0.531}
            stopColor="#aa00e8"
            className="stopColor277ec1 svgShape"
          />
          <stop
            offset={0.535}
            stopColor="#aa00e8"
            className="stopColor277fc1 svgShape"
          />
          <stop
            offset={0.539}
            stopColor="#aa00e8"
            className="stopColor277fc1 svgShape"
          />
          <stop
            offset={0.543}
            stopColor="#ab00e9"
            className="stopColor2780c2 svgShape"
          />
          <stop
            offset={0.547}
            stopColor="#ab00e9"
            className="stopColor2780c2 svgShape"
          />
          <stop
            offset={0.551}
            stopColor="#ac00ea"
            className="stopColor2781c3 svgShape"
          />
          <stop
            offset={0.555}
            stopColor="#ac00eb"
            className="stopColor2882c3 svgShape"
          />
          <stop
            offset={0.559}
            stopColor="#ac00eb"
            className="stopColor2882c3 svgShape"
          />
          <stop
            offset={0.563}
            stopColor="#ad00ec"
            className="stopColor2883c4 svgShape"
          />
          <stop
            offset={0.566}
            stopColor="#ad00ec"
            className="stopColor2883c4 svgShape"
          />
          <stop
            offset={0.57}
            stopColor="#ae00ed"
            className="stopColor2884c5 svgShape"
          />
          <stop
            offset={0.574}
            stopColor="#ae00ed"
            className="stopColor2885c5 svgShape"
          />
          <stop
            offset={0.578}
            stopColor="#ae00ed"
            className="stopColor2885c5 svgShape"
          />
          <stop
            offset={0.582}
            stopColor="#af00ee"
            className="stopColor2886c6 svgShape"
          />
          <stop
            offset={0.586}
            stopColor="#af00ef"
            className="stopColor2986c6 svgShape"
          />
          <stop
            offset={0.59}
            stopColor="#b000f0"
            className="stopColor2987c7 svgShape"
          />
          <stop
            offset={0.594}
            stopColor="#b000f0"
            className="stopColor2988c7 svgShape"
          />
          <stop
            offset={0.598}
            stopColor="#b000f0"
            className="stopColor2988c7 svgShape"
          />
          <stop
            offset={0.602}
            stopColor="#b100f1"
            className="stopColor2989c8 svgShape"
          />
          <stop
            offset={0.605}
            stopColor="#b100f1"
            className="stopColor2989c8 svgShape"
          />
          <stop
            offset={0.609}
            stopColor="#b100f2"
            className="stopColor298ac9 svgShape"
          />
          <stop
            offset={0.613}
            stopColor="#b200f3"
            className="stopColor2a8ac9 svgShape"
          />
          <stop
            offset={0.617}
            stopColor="#b200f3"
            className="stopColor2a8bc9 svgShape"
          />
          <stop
            offset={0.621}
            stopColor="#b300f4"
            className="stopColor2a8cca svgShape"
          />
          <stop
            offset={0.625}
            stopColor="#b300f4"
            className="stopColor2a8cca svgShape"
          />
          <stop
            offset={0.629}
            stopColor="#b400f5"
            className="stopColor2a8dcb svgShape"
          />
          <stop
            offset={0.633}
            stopColor="#b400f5"
            className="stopColor2a8dcb svgShape"
          />
          <stop
            offset={0.637}
            stopColor="#b400f5"
            className="stopColor2a8ecb svgShape"
          />
          <stop
            offset={0.641}
            stopColor="#b400f6"
            className="stopColor2a8fcc svgShape"
          />
          <stop
            offset={0.645}
            stopColor="#b500f7"
            className="stopColor2b8fcc svgShape"
          />
          <stop
            offset={0.648}
            stopColor="#b600f8"
            className="stopColor2b90cd svgShape"
          />
          <stop
            offset={0.652}
            stopColor="#b600f8"
            className="stopColor2b90cd svgShape"
          />
          <stop
            offset={0.656}
            stopColor="#b600f8"
            className="stopColor2b91cd svgShape"
          />
          <stop
            offset={0.66}
            stopColor="#b700f9"
            className="stopColor2b92ce svgShape"
          />
          <stop
            offset={0.664}
            stopColor="#b700f9"
            className="stopColor2b92ce svgShape"
          />
          <stop
            offset={0.668}
            stopColor="#b700fa"
            className="stopColor2b93cf svgShape"
          />
          <stop
            offset={0.672}
            stopColor="#b700fa"
            className="stopColor2b93cf svgShape"
          />
          <stop
            offset={0.676}
            stopColor="#b800fb"
            className="stopColor2c94cf svgShape"
          />
          <stop
            offset={0.68}
            stopColor="#b900fc"
            className="stopColor2c95d0 svgShape"
          />
          <stop
            offset={0.684}
            stopColor="#b900fc"
            className="stopColor2c95d0 svgShape"
          />
          <stop
            offset={0.688}
            stopColor="#ba00fd"
            className="stopColor2c96d1 svgShape"
          />
          <stop
            offset={0.691}
            stopColor="#ba00fd"
            className="stopColor2c96d1 svgShape"
          />
          <stop
            offset={0.695}
            stopColor="#ba00fd"
            className="stopColor2c97d1 svgShape"
          />
          <stop
            offset={0.699}
            stopColor="#ba00fe"
            className="stopColor2c97d2 svgShape"
          />
          <stop
            offset={0.703}
            stopColor="#ba00fe"
            className="stopColor2c98d2 svgShape"
          />
          <stop
            offset={0.707}
            stopColor="#bb01ff"
            className="stopColor2d99d3 svgShape"
          />
          <stop
            offset={0.711}
            stopColor="#bb01ff"
            className="stopColor2d99d3 svgShape"
          />
          <stop
            offset={0.715}
            stopColor="#bb01ff"
            className="stopColor2d9ad3 svgShape"
          />
          <stop
            offset={0.719}
            stopColor="#bc02ff"
            className="stopColor2d9ad4 svgShape"
          />
          <stop
            offset={0.723}
            stopColor="#bc02ff"
            className="stopColor2d9bd4 svgShape"
          />
          <stop
            offset={0.727}
            stopColor="#bc03ff"
            className="stopColor2d9cd5 svgShape"
          />
          <stop
            offset={0.73}
            stopColor="#bc03ff"
            className="stopColor2d9cd5 svgShape"
          />
          <stop
            offset={0.734}
            stopColor="#bc04ff"
            className="stopColor2e9dd5 svgShape"
          />
          <stop
            offset={0.738}
            stopColor="#bc05ff"
            className="stopColor2e9dd6 svgShape"
          />
          <stop
            offset={0.742}
            stopColor="#bc05ff"
            className="stopColor2e9ed6 svgShape"
          />
          <stop
            offset={0.746}
            stopColor="#bd06ff"
            className="stopColor2e9fd7 svgShape"
          />
          <stop
            offset={0.75}
            stopColor="#bd06ff"
            className="stopColor2e9fd7 svgShape"
          />
          <stop
            offset={0.754}
            stopColor="#bd06ff"
            className="stopColor2ea0d7 svgShape"
          />
          <stop
            offset={0.758}
            stopColor="#bd07ff"
            className="stopColor2ea0d8 svgShape"
          />
          <stop
            offset={0.762}
            stopColor="#bd07ff"
            className="stopColor2ea1d8 svgShape"
          />
          <stop
            offset={0.766}
            stopColor="#bd09ff"
            className="stopColor2fa2d9 svgShape"
          />
          <stop
            offset={0.77}
            stopColor="#bd09ff"
            className="stopColor2fa2d9 svgShape"
          />
          <stop
            offset={0.773}
            stopColor="#bd09ff"
            className="stopColor2fa3d9 svgShape"
          />
          <stop
            offset={0.777}
            stopColor="#be0aff"
            className="stopColor2fa3da svgShape"
          />
          <stop
            offset={0.781}
            stopColor="#be0aff"
            className="stopColor2fa4da svgShape"
          />
          <stop
            offset={0.785}
            stopColor="#be0bff"
            className="stopColor2fa4db svgShape"
          />
          <stop
            offset={0.789}
            stopColor="#be0bff"
            className="stopColor2fa5db svgShape"
          />
          <stop
            offset={0.793}
            stopColor="#be0bff"
            className="stopColor2fa6db svgShape"
          />
          <stop
            offset={0.797}
            stopColor="#be0dff"
            className="stopColor30a6dc svgShape"
          />
          <stop
            offset={0.801}
            stopColor="#be0dff"
            className="stopColor30a7dc svgShape"
          />
          <stop
            offset={0.805}
            stopColor="#bf0eff"
            className="stopColor30a7dd svgShape"
          />
          <stop
            offset={0.809}
            stopColor="#bf0eff"
            className="stopColor30a8dd svgShape"
          />
          <stop
            offset={0.813}
            stopColor="#bf0eff"
            className="stopColor30a9dd svgShape"
          />
          <stop
            offset={0.816}
            stopColor="#bf0fff"
            className="stopColor30a9de svgShape"
          />
          <stop
            offset={0.82}
            stopColor="#bf0fff"
            className="stopColor30aade svgShape"
          />
          <stop
            offset={0.824}
            stopColor="#bf10ff"
            className="stopColor30aadf svgShape"
          />
          <stop
            offset={0.828}
            stopColor="#c011ff"
            className="stopColor31abdf svgShape"
          />
          <stop
            offset={0.832}
            stopColor="#c011ff"
            className="stopColor31acdf svgShape"
          />
          <stop
            offset={0.836}
            stopColor="#c012ff"
            className="stopColor31ace0 svgShape"
          />
          <stop
            offset={0.84}
            stopColor="#c012ff"
            className="stopColor31ade0 svgShape"
          />
          <stop
            offset={0.844}
            stopColor="#c013ff"
            className="stopColor31ade1 svgShape"
          />
          <stop
            offset={0.848}
            stopColor="#c013ff"
            className="stopColor31aee1 svgShape"
          />
          <stop
            offset={0.852}
            stopColor="#c013ff"
            className="stopColor31aee1 svgShape"
          />
          <stop
            offset={0.855}
            stopColor="#c014ff"
            className="stopColor31afe2 svgShape"
          />
          <stop
            offset={0.859}
            stopColor="#c115ff"
            className="stopColor32b0e2 svgShape"
          />
          <stop
            offset={0.863}
            stopColor="#c116ff"
            className="stopColor32b0e3 svgShape"
          />
          <stop
            offset={0.867}
            stopColor="#c116ff"
            className="stopColor32b1e3 svgShape"
          />
          <stop
            offset={0.871}
            stopColor="#c116ff"
            className="stopColor32b1e3 svgShape"
          />
          <stop
            offset={0.875}
            stopColor="#c117ff"
            className="stopColor32b2e4 svgShape"
          />
          <stop
            offset={0.879}
            stopColor="#c117ff"
            className="stopColor32b3e4 svgShape"
          />
          <stop
            offset={0.883}
            stopColor="#c118ff"
            className="stopColor32b3e5 svgShape"
          />
          <stop
            offset={0.887}
            stopColor="#c219ff"
            className="stopColor33b4e5 svgShape"
          />
          <stop
            offset={0.891}
            stopColor="#c219ff"
            className="stopColor33b4e5 svgShape"
          />
          <stop
            offset={0.895}
            stopColor="#c21aff"
            className="stopColor33b5e6 svgShape"
          />
          <stop
            offset={0.898}
            stopColor="#c21aff"
            className="stopColor33b6e6 svgShape"
          />
          <stop
            offset={0.902}
            stopColor="#c21bff"
            className="stopColor33b6e7 svgShape"
          />
          <stop
            offset={0.906}
            stopColor="#c21bff"
            className="stopColor33b7e7 svgShape"
          />
          <stop
            offset={0.91}
            stopColor="#c21bff"
            className="stopColor33b7e7 svgShape"
          />
          <stop
            offset={0.914}
            stopColor="#c21cff"
            className="stopColor33b8e8 svgShape"
          />
          <stop
            offset={0.918}
            stopColor="#c31dff"
            className="stopColor34b9e8 svgShape"
          />
          <stop
            offset={0.922}
            stopColor="#c31eff"
            className="stopColor34b9e9 svgShape"
          />
          <stop
            offset={0.926}
            stopColor="#c31eff"
            className="stopColor34bae9 svgShape"
          />
          <stop
            offset={0.93}
            stopColor="#c31eff"
            className="stopColor34bae9 svgShape"
          />
          <stop
            offset={0.934}
            stopColor="#c31fff"
            className="stopColor34bbea svgShape"
          />
          <stop
            offset={0.938}
            stopColor="#c31fff"
            className="stopColor34bbea svgShape"
          />
          <stop
            offset={0.941}
            stopColor="#c420ff"
            className="stopColor34bceb svgShape"
          />
          <stop
            offset={0.945}
            stopColor="#c420ff"
            className="stopColor34bdeb svgShape"
          />
          <stop
            offset={0.949}
            stopColor="#c421ff"
            className="stopColor35bdeb svgShape"
          />
          <stop
            offset={0.953}
            stopColor="#c422ff"
            className="stopColor35beec svgShape"
          />
          <stop
            offset={0.957}
            stopColor="#c422ff"
            className="stopColor35beec svgShape"
          />
          <stop
            offset={0.961}
            stopColor="#c423ff"
            className="stopColor35bfed svgShape"
          />
          <stop
            offset={0.965}
            stopColor="#c423ff"
            className="stopColor35c0ed svgShape"
          />
          <stop
            offset={0.969}
            stopColor="#c423ff"
            className="stopColor35c0ed svgShape"
          />
          <stop
            offset={0.973}
            stopColor="#c524ff"
            className="stopColor35c1ee svgShape"
          />
          <stop
            offset={0.977}
            stopColor="#c524ff"
            className="stopColor35c1ee svgShape"
          />
          <stop
            offset={0.98}
            stopColor="#c526ff"
            className="stopColor36c2ef svgShape"
          />
          <stop
            offset={0.984}
            stopColor="#c526ff"
            className="stopColor36c3ef svgShape"
          />
          <stop
            offset={0.988}
            stopColor="#c526ff"
            className="stopColor36c3ef svgShape"
          />
          <stop
            offset={0.992}
            stopColor="#c527ff"
            className="stopColor36c4f0 svgShape"
          />
          <stop
            offset={1}
            stopColor="#c527ff"
            className="stopColor36c4f0 svgShape"
          />
        </linearGradient>
        <linearGradient
          xmlnsXlink="http://www.w3.org/1999/xlink"
          id="l"
          x1={153.09}
          x2={195.2}
          y1={-59.187}
          y2={-97.793}
          gradientTransform="matrix(2.36249 .50159 -.50216 2.35979 -227.844 194.4)"
          gradientUnits="userSpaceOnUse"
          xlinkActuate="onLoad"
          xlinkShow="other"
          xlinkType="simple"
        >
          <stop
            offset={0}
            stopColor="#69008f"
            className="stopColor00468f svgShape"
          />
          <stop
            offset={0.25}
            stopColor="#69008f"
            className="stopColor00468f svgShape"
          />
          <stop
            offset={0.375}
            stopColor="#69008f"
            className="stopColor00468f svgShape"
          />
          <stop
            offset={0.406}
            stopColor="#69008f"
            className="stopColor00468f svgShape"
          />
          <stop
            offset={0.414}
            stopColor="#6a0091"
            className="stopColor014790 svgShape"
          />
          <stop
            offset={0.414}
            stopColor="#6b0092"
            className="stopColor014791 svgShape"
          />
          <stop
            offset={0.422}
            stopColor="#6c0093"
            className="stopColor024891 svgShape"
          />
          <stop
            offset={0.43}
            stopColor="#6c0094"
            className="stopColor024892 svgShape"
          />
          <stop
            offset={0.438}
            stopColor="#6d0095"
            className="stopColor034892 svgShape"
          />
          <stop
            offset={0.445}
            stopColor="#6f0097"
            className="stopColor044993 svgShape"
          />
          <stop
            offset={0.453}
            stopColor="#700099"
            className="stopColor054a94 svgShape"
          />
          <stop
            offset={0.461}
            stopColor="#71009a"
            className="stopColor054a95 svgShape"
          />
          <stop
            offset={0.469}
            stopColor="#72009c"
            className="stopColor064b96 svgShape"
          />
          <stop
            offset={0.477}
            stopColor="#74009e"
            className="stopColor074b97 svgShape"
          />
          <stop
            offset={0.484}
            stopColor="#7500a0"
            className="stopColor084c98 svgShape"
          />
          <stop
            offset={0.492}
            stopColor="#7600a1"
            className="stopColor094d98 svgShape"
          />
          <stop
            offset={0.5}
            stopColor="#7700a2"
            className="stopColor094d99 svgShape"
          />
          <stop
            offset={0.508}
            stopColor="#7800a4"
            className="stopColor0a4e9a svgShape"
          />
          <stop
            offset={0.516}
            stopColor="#7a00a6"
            className="stopColor0b4e9b svgShape"
          />
          <stop
            offset={0.523}
            stopColor="#7b00a8"
            className="stopColor0c4f9c svgShape"
          />
          <stop
            offset={0.531}
            stopColor="#7d00aa"
            className="stopColor0d509d svgShape"
          />
          <stop
            offset={0.539}
            stopColor="#7d00ab"
            className="stopColor0d509e svgShape"
          />
          <stop
            offset={0.547}
            stopColor="#7f00ad"
            className="stopColor0e519f svgShape"
          />
          <stop
            offset={0.555}
            stopColor="#8000ae"
            className="stopColor0f519f svgShape"
          />
          <stop
            offset={0.563}
            stopColor="#8100b0"
            className="stopColor1052a0 svgShape"
          />
          <stop
            offset={0.57}
            stopColor="#8200b1"
            className="stopColor1053a1 svgShape"
          />
          <stop
            offset={0.578}
            stopColor="#8300b3"
            className="stopColor1153a2 svgShape"
          />
          <stop
            offset={0.586}
            stopColor="#8500b5"
            className="stopColor1254a3 svgShape"
          />
          <stop
            offset={0.586}
            stopColor="#8500b5"
            className="stopColor1254a3 svgShape"
          />
          <stop
            offset={0.594}
            stopColor="#8600b7"
            className="stopColor1354a4 svgShape"
          />
          <stop
            offset={0.602}
            stopColor="#8800b9"
            className="stopColor1455a5 svgShape"
          />
          <stop
            offset={0.609}
            stopColor="#8800b9"
            className="stopColor1456a5 svgShape"
          />
          <stop
            offset={0.625}
            stopColor="#8900bb"
            className="stopColor1556a6 svgShape"
          />
          <stop
            offset={0.75}
            stopColor="#8900bb"
            className="stopColor1556a6 svgShape"
          />
          <stop
            offset={1}
            stopColor="#8900bb"
            className="stopColor1556a6 svgShape"
          />
        </linearGradient>
        <linearGradient
          xmlnsXlink="http://www.w3.org/1999/xlink"
          id="f"
          x1={-272.594}
          x2={-266.617}
          y1={2456.845}
          y2={2503.071}
          gradientTransform="matrix(1.02875 2.18272 -2.18522 1.02758 5851.094 -1680.865)"
          gradientUnits="userSpaceOnUse"
          xlinkActuate="onLoad"
          xlinkShow="other"
          xlinkType="simple"
        >
          <stop
            offset={0}
            stopColor="#69008f"
            className="stopColor00468f svgShape"
          />
          <stop
            offset={0.141}
            stopColor="#69008f"
            className="stopColor00468f svgShape"
          />
          <stop
            offset={0.25}
            stopColor="#69008f"
            className="stopColor00468f svgShape"
          />
          <stop
            offset={0.375}
            stopColor="#69008f"
            className="stopColor00468f svgShape"
          />
          <stop
            offset={0.438}
            stopColor="#69008f"
            className="stopColor00468f svgShape"
          />
          <stop
            offset={0.469}
            stopColor="#69008f"
            className="stopColor00468f svgShape"
          />
          <stop
            offset={0.477}
            stopColor="#6a0091"
            className="stopColor014790 svgShape"
          />
          <stop
            offset={0.484}
            stopColor="#6a0091"
            className="stopColor014790 svgShape"
          />
          <stop
            offset={0.492}
            stopColor="#6b0092"
            className="stopColor014791 svgShape"
          />
          <stop
            offset={0.5}
            stopColor="#6c0093"
            className="stopColor024891 svgShape"
          />
          <stop
            offset={0.508}
            stopColor="#6c0094"
            className="stopColor024892 svgShape"
          />
          <stop
            offset={0.516}
            stopColor="#6d0095"
            className="stopColor034892 svgShape"
          />
          <stop
            offset={0.523}
            stopColor="#6e0096"
            className="stopColor034993 svgShape"
          />
          <stop
            offset={0.531}
            stopColor="#6f0097"
            className="stopColor044993 svgShape"
          />
          <stop
            offset={0.539}
            stopColor="#6f0098"
            className="stopColor044994 svgShape"
          />
          <stop
            offset={0.547}
            stopColor="#700099"
            className="stopColor054a94 svgShape"
          />
          <stop
            offset={0.555}
            stopColor="#71009a"
            className="stopColor054a95 svgShape"
          />
          <stop
            offset={0.563}
            stopColor="#72009b"
            className="stopColor064b95 svgShape"
          />
          <stop
            offset={0.57}
            stopColor="#72009c"
            className="stopColor064b96 svgShape"
          />
          <stop
            offset={0.578}
            stopColor="#73009d"
            className="stopColor074b96 svgShape"
          />
          <stop
            offset={0.586}
            stopColor="#74009e"
            className="stopColor074c97 svgShape"
          />
          <stop
            offset={0.594}
            stopColor="#7500a0"
            className="stopColor084c98 svgShape"
          />
          <stop
            offset={0.602}
            stopColor="#7500a0"
            className="stopColor084c98 svgShape"
          />
          <stop
            offset={0.609}
            stopColor="#7700a2"
            className="stopColor094d99 svgShape"
          />
          <stop
            offset={0.617}
            stopColor="#7700a2"
            className="stopColor094d99 svgShape"
          />
          <stop
            offset={0.625}
            stopColor="#7800a4"
            className="stopColor0a4d9a svgShape"
          />
          <stop
            offset={0.633}
            stopColor="#7800a4"
            className="stopColor0a4e9a svgShape"
          />
          <stop
            offset={0.641}
            stopColor="#7a00a6"
            className="stopColor0b4e9b svgShape"
          />
          <stop
            offset={0.648}
            stopColor="#7a00a6"
            className="stopColor0b4f9b svgShape"
          />
          <stop
            offset={0.656}
            stopColor="#7b00a8"
            className="stopColor0c4f9c svgShape"
          />
          <stop
            offset={0.664}
            stopColor="#7b00a8"
            className="stopColor0c4f9c svgShape"
          />
          <stop
            offset={0.672}
            stopColor="#7d00aa"
            className="stopColor0d509d svgShape"
          />
          <stop
            offset={0.68}
            stopColor="#7d00aa"
            className="stopColor0d509d svgShape"
          />
          <stop
            offset={0.688}
            stopColor="#7e00ac"
            className="stopColor0e509e svgShape"
          />
          <stop
            offset={0.695}
            stopColor="#7e00ac"
            className="stopColor0e519e svgShape"
          />
          <stop
            offset={0.703}
            stopColor="#7f00ad"
            className="stopColor0e519f svgShape"
          />
          <stop
            offset={0.711}
            stopColor="#8000ae"
            className="stopColor0f519f svgShape"
          />
          <stop
            offset={0.719}
            stopColor="#8000af"
            className="stopColor0f52a0 svgShape"
          />
          <stop
            offset={0.727}
            stopColor="#8200b1"
            className="stopColor1052a1 svgShape"
          />
          <stop
            offset={0.734}
            stopColor="#8200b1"
            className="stopColor1053a1 svgShape"
          />
          <stop
            offset={0.742}
            stopColor="#8300b3"
            className="stopColor1153a2 svgShape"
          />
          <stop
            offset={0.75}
            stopColor="#8300b3"
            className="stopColor1153a2 svgShape"
          />
          <stop
            offset={0.758}
            stopColor="#8500b5"
            className="stopColor1254a3 svgShape"
          />
          <stop
            offset={0.766}
            stopColor="#8500b5"
            className="stopColor1254a3 svgShape"
          />
          <stop
            offset={0.773}
            stopColor="#8600b7"
            className="stopColor1354a4 svgShape"
          />
          <stop
            offset={0.781}
            stopColor="#8600b7"
            className="stopColor1355a4 svgShape"
          />
          <stop
            offset={0.789}
            stopColor="#8800b9"
            className="stopColor1455a5 svgShape"
          />
          <stop
            offset={0.797}
            stopColor="#8800b9"
            className="stopColor1455a5 svgShape"
          />
          <stop
            offset={0.813}
            stopColor="#8900bb"
            className="stopColor1556a6 svgShape"
          />
          <stop
            offset={0.859}
            stopColor="#8900bb"
            className="stopColor1556a6 svgShape"
          />
          <stop
            offset={0.875}
            stopColor="#8900bb"
            className="stopColor1556a6 svgShape"
          />
          <stop
            offset={1}
            stopColor="#8900bb"
            className="stopColor1556a6 svgShape"
          />
        </linearGradient>
        <linearGradient
          xmlnsXlink="http://www.w3.org/1999/xlink"
          id="c"
          x1={-305.773}
          x2={-225.046}
          y1={2452.677}
          y2={2533.405}
          gradientTransform="matrix(1.02875 2.18272 -2.18522 1.02758 5851.094 -1680.865)"
          gradientUnits="userSpaceOnUse"
          xlinkActuate="onLoad"
          xlinkShow="other"
          xlinkType="simple"
        >
          <stop
            offset={0}
            stopColor="#8600b7"
            className="stopColor1b489c svgShape"
          />
          <stop
            offset={0.125}
            stopColor="#8600b7"
            className="stopColor1b489c svgShape"
          />
          <stop
            offset={0.188}
            stopColor="#8600b7"
            className="stopColor1b489c svgShape"
          />
          <stop
            offset={0.195}
            stopColor="#8600b7"
            className="stopColor1b489c svgShape"
          />
          <stop
            offset={0.199}
            stopColor="#8700b8"
            className="stopColor1b499d svgShape"
          />
          <stop
            offset={0.203}
            stopColor="#8700b8"
            className="stopColor1b499d svgShape"
          />
          <stop
            offset={0.207}
            stopColor="#8800ba"
            className="stopColor1c4a9e svgShape"
          />
          <stop
            offset={0.211}
            stopColor="#8800ba"
            className="stopColor1c4b9e svgShape"
          />
          <stop
            offset={0.215}
            stopColor="#8900bb"
            className="stopColor1c4c9f svgShape"
          />
          <stop
            offset={0.219}
            stopColor="#8900bb"
            className="stopColor1c4c9f svgShape"
          />
          <stop
            offset={0.223}
            stopColor="#8a00bc"
            className="stopColor1c4da0 svgShape"
          />
          <stop
            offset={0.227}
            stopColor="#8a00bc"
            className="stopColor1c4ea0 svgShape"
          />
          <stop
            offset={0.23}
            stopColor="#8b00be"
            className="stopColor1d4fa1 svgShape"
          />
          <stop
            offset={0.234}
            stopColor="#8b00be"
            className="stopColor1d4fa1 svgShape"
          />
          <stop
            offset={0.238}
            stopColor="#8c00bf"
            className="stopColor1d50a2 svgShape"
          />
          <stop
            offset={0.242}
            stopColor="#8c00bf"
            className="stopColor1d51a2 svgShape"
          />
          <stop
            offset={0.246}
            stopColor="#8d00c0"
            className="stopColor1d52a3 svgShape"
          />
          <stop
            offset={0.25}
            stopColor="#8d00c0"
            className="stopColor1d52a3 svgShape"
          />
          <stop
            offset={0.254}
            stopColor="#8e00c1"
            className="stopColor1d53a4 svgShape"
          />
          <stop
            offset={0.258}
            stopColor="#8e00c2"
            className="stopColor1e54a4 svgShape"
          />
          <stop
            offset={0.262}
            stopColor="#8f00c3"
            className="stopColor1e55a5 svgShape"
          />
          <stop
            offset={0.266}
            stopColor="#8f00c3"
            className="stopColor1e55a5 svgShape"
          />
          <stop
            offset={0.27}
            stopColor="#9000c4"
            className="stopColor1e56a6 svgShape"
          />
          <stop
            offset={0.273}
            stopColor="#9000c4"
            className="stopColor1e57a6 svgShape"
          />
          <stop
            offset={0.277}
            stopColor="#9000c5"
            className="stopColor1e58a7 svgShape"
          />
          <stop
            offset={0.281}
            stopColor="#9100c6"
            className="stopColor1f58a7 svgShape"
          />
          <stop
            offset={0.285}
            stopColor="#9200c7"
            className="stopColor1f59a8 svgShape"
          />
          <stop
            offset={0.289}
            stopColor="#9200c7"
            className="stopColor1f5aa8 svgShape"
          />
          <stop
            offset={0.293}
            stopColor="#9300c8"
            className="stopColor1f5ba9 svgShape"
          />
          <stop
            offset={0.297}
            stopColor="#9300c8"
            className="stopColor1f5ba9 svgShape"
          />
          <stop
            offset={0.301}
            stopColor="#9300c9"
            className="stopColor1f5caa svgShape"
          />
          <stop
            offset={0.305}
            stopColor="#9400ca"
            className="stopColor205daa svgShape"
          />
          <stop
            offset={0.309}
            stopColor="#9500cb"
            className="stopColor205eab svgShape"
          />
          <stop
            offset={0.313}
            stopColor="#9500cb"
            className="stopColor205eab svgShape"
          />
          <stop
            offset={0.316}
            stopColor="#9600cc"
            className="stopColor205fac svgShape"
          />
          <stop
            offset={0.32}
            stopColor="#9600cc"
            className="stopColor2060ac svgShape"
          />
          <stop
            offset={0.324}
            stopColor="#9600cd"
            className="stopColor2061ad svgShape"
          />
          <stop
            offset={0.328}
            stopColor="#9700ce"
            className="stopColor2161ad svgShape"
          />
          <stop
            offset={0.332}
            stopColor="#9800cf"
            className="stopColor2162ae svgShape"
          />
          <stop
            offset={0.336}
            stopColor="#9800cf"
            className="stopColor2163ae svgShape"
          />
          <stop
            offset={0.34}
            stopColor="#9900d0"
            className="stopColor2164af svgShape"
          />
          <stop
            offset={0.344}
            stopColor="#9900d0"
            className="stopColor2164af svgShape"
          />
          <stop
            offset={0.348}
            stopColor="#9900d1"
            className="stopColor2165b0 svgShape"
          />
          <stop
            offset={0.352}
            stopColor="#9a00d2"
            className="stopColor2266b0 svgShape"
          />
          <stop
            offset={0.355}
            stopColor="#9b00d3"
            className="stopColor2266b1 svgShape"
          />
          <stop
            offset={0.359}
            stopColor="#9b00d3"
            className="stopColor2267b1 svgShape"
          />
          <stop
            offset={0.363}
            stopColor="#9b00d4"
            className="stopColor2268b2 svgShape"
          />
          <stop
            offset={0.367}
            stopColor="#9b00d4"
            className="stopColor2269b2 svgShape"
          />
          <stop
            offset={0.371}
            stopColor="#9c00d5"
            className="stopColor2269b3 svgShape"
          />
          <stop
            offset={0.375}
            stopColor="#9c00d5"
            className="stopColor226ab3 svgShape"
          />
          <stop
            offset={0.379}
            stopColor="#9e00d7"
            className="stopColor236bb4 svgShape"
          />
          <stop
            offset={0.383}
            stopColor="#9e00d7"
            className="stopColor236cb4 svgShape"
          />
          <stop
            offset={0.387}
            stopColor="#9e00d8"
            className="stopColor236cb5 svgShape"
          />
          <stop
            offset={0.391}
            stopColor="#9e00d8"
            className="stopColor236db5 svgShape"
          />
          <stop
            offset={0.395}
            stopColor="#9f00d9"
            className="stopColor236eb6 svgShape"
          />
          <stop
            offset={0.398}
            stopColor="#9f00d9"
            className="stopColor236fb6 svgShape"
          />
          <stop
            offset={0.402}
            stopColor="#a100db"
            className="stopColor246fb7 svgShape"
          />
          <stop
            offset={0.406}
            stopColor="#a100db"
            className="stopColor2470b7 svgShape"
          />
          <stop
            offset={0.41}
            stopColor="#a100dc"
            className="stopColor2471b8 svgShape"
          />
          <stop
            offset={0.414}
            stopColor="#a100dc"
            className="stopColor2472b8 svgShape"
          />
          <stop
            offset={0.418}
            stopColor="#a200dd"
            className="stopColor2472b9 svgShape"
          />
          <stop
            offset={0.422}
            stopColor="#a200dd"
            className="stopColor2473b9 svgShape"
          />
          <stop
            offset={0.426}
            stopColor="#a400df"
            className="stopColor2574ba svgShape"
          />
          <stop
            offset={0.43}
            stopColor="#a400df"
            className="stopColor2575ba svgShape"
          />
          <stop
            offset={0.434}
            stopColor="#a400e0"
            className="stopColor2575bb svgShape"
          />
          <stop
            offset={0.438}
            stopColor="#a400e0"
            className="stopColor2576bb svgShape"
          />
          <stop
            offset={0.441}
            stopColor="#a500e1"
            className="stopColor2577bc svgShape"
          />
          <stop
            offset={0.445}
            stopColor="#a500e1"
            className="stopColor2578bc svgShape"
          />
          <stop
            offset={0.449}
            stopColor="#a600e3"
            className="stopColor2678bd svgShape"
          />
          <stop
            offset={0.453}
            stopColor="#a600e3"
            className="stopColor2679bd svgShape"
          />
          <stop
            offset={0.457}
            stopColor="#a700e4"
            className="stopColor267abe svgShape"
          />
          <stop
            offset={0.461}
            stopColor="#a700e4"
            className="stopColor267bbe svgShape"
          />
          <stop
            offset={0.465}
            stopColor="#a800e5"
            className="stopColor267bbf svgShape"
          />
          <stop
            offset={0.469}
            stopColor="#a800e5"
            className="stopColor267cbf svgShape"
          />
          <stop
            offset={0.473}
            stopColor="#a900e7"
            className="stopColor277dc0 svgShape"
          />
          <stop
            offset={0.477}
            stopColor="#a900e7"
            className="stopColor277ec0 svgShape"
          />
          <stop
            offset={0.48}
            stopColor="#aa00e8"
            className="stopColor277ec1 svgShape"
          />
          <stop
            offset={0.484}
            stopColor="#aa00e8"
            className="stopColor277fc1 svgShape"
          />
          <stop
            offset={0.488}
            stopColor="#ab00e9"
            className="stopColor2780c2 svgShape"
          />
          <stop
            offset={0.492}
            stopColor="#ab00e9"
            className="stopColor2781c2 svgShape"
          />
          <stop
            offset={0.496}
            stopColor="#ac00eb"
            className="stopColor2881c3 svgShape"
          />
          <stop
            offset={0.5}
            stopColor="#ac00eb"
            className="stopColor2882c3 svgShape"
          />
          <stop
            offset={0.504}
            stopColor="#ad00ec"
            className="stopColor2883c4 svgShape"
          />
          <stop
            offset={0.508}
            stopColor="#ad00ec"
            className="stopColor2883c4 svgShape"
          />
          <stop
            offset={0.512}
            stopColor="#ae00ed"
            className="stopColor2884c5 svgShape"
          />
          <stop
            offset={0.516}
            stopColor="#ae00ed"
            className="stopColor2885c5 svgShape"
          />
          <stop
            offset={0.52}
            stopColor="#af00ee"
            className="stopColor2886c6 svgShape"
          />
          <stop
            offset={0.523}
            stopColor="#af00ef"
            className="stopColor2986c6 svgShape"
          />
          <stop
            offset={0.527}
            stopColor="#b000f0"
            className="stopColor2987c7 svgShape"
          />
          <stop
            offset={0.531}
            stopColor="#b000f0"
            className="stopColor2988c7 svgShape"
          />
          <stop
            offset={0.535}
            stopColor="#b100f1"
            className="stopColor2989c8 svgShape"
          />
          <stop
            offset={0.539}
            stopColor="#b100f1"
            className="stopColor2989c8 svgShape"
          />
          <stop
            offset={0.543}
            stopColor="#b100f2"
            className="stopColor298ac9 svgShape"
          />
          <stop
            offset={0.547}
            stopColor="#b200f3"
            className="stopColor2a8bc9 svgShape"
          />
          <stop
            offset={0.551}
            stopColor="#b300f4"
            className="stopColor2a8cca svgShape"
          />
          <stop
            offset={0.555}
            stopColor="#b300f4"
            className="stopColor2a8cca svgShape"
          />
          <stop
            offset={0.559}
            stopColor="#b400f5"
            className="stopColor2a8dcb svgShape"
          />
          <stop
            offset={0.563}
            stopColor="#b400f5"
            className="stopColor2a8ecb svgShape"
          />
          <stop
            offset={0.566}
            stopColor="#b400f6"
            className="stopColor2a8fcc svgShape"
          />
          <stop
            offset={0.57}
            stopColor="#b500f7"
            className="stopColor2b8fcc svgShape"
          />
          <stop
            offset={0.574}
            stopColor="#b600f8"
            className="stopColor2b90cd svgShape"
          />
          <stop
            offset={0.578}
            stopColor="#b600f8"
            className="stopColor2b91cd svgShape"
          />
          <stop
            offset={0.582}
            stopColor="#b700f9"
            className="stopColor2b92ce svgShape"
          />
          <stop
            offset={0.586}
            stopColor="#b700f9"
            className="stopColor2b92ce svgShape"
          />
          <stop
            offset={0.59}
            stopColor="#b700fa"
            className="stopColor2b93cf svgShape"
          />
          <stop
            offset={0.594}
            stopColor="#b800fb"
            className="stopColor2c94cf svgShape"
          />
          <stop
            offset={0.598}
            stopColor="#b900fc"
            className="stopColor2c95d0 svgShape"
          />
          <stop
            offset={0.602}
            stopColor="#b900fc"
            className="stopColor2c95d0 svgShape"
          />
          <stop
            offset={0.605}
            stopColor="#ba00fd"
            className="stopColor2c96d1 svgShape"
          />
          <stop
            offset={0.609}
            stopColor="#ba00fd"
            className="stopColor2c97d1 svgShape"
          />
          <stop
            offset={0.613}
            stopColor="#ba00fe"
            className="stopColor2c98d2 svgShape"
          />
          <stop
            offset={0.617}
            stopColor="#b0f"
            className="stopColor2d98d2 svgShape"
          />
          <stop
            offset={0.621}
            stopColor="#bb01ff"
            className="stopColor2d99d3 svgShape"
          />
          <stop
            offset={0.625}
            stopColor="#bb01ff"
            className="stopColor2d9ad3 svgShape"
          />
          <stop
            offset={0.629}
            stopColor="#bc02ff"
            className="stopColor2d9bd4 svgShape"
          />
          <stop
            offset={0.633}
            stopColor="#bc02ff"
            className="stopColor2d9bd4 svgShape"
          />
          <stop
            offset={0.637}
            stopColor="#bc03ff"
            className="stopColor2d9cd5 svgShape"
          />
          <stop
            offset={0.641}
            stopColor="#bc04ff"
            className="stopColor2e9dd5 svgShape"
          />
          <stop
            offset={0.645}
            stopColor="#bc05ff"
            className="stopColor2e9ed6 svgShape"
          />
          <stop
            offset={0.648}
            stopColor="#bc05ff"
            className="stopColor2e9ed6 svgShape"
          />
          <stop
            offset={0.652}
            stopColor="#bd06ff"
            className="stopColor2e9fd7 svgShape"
          />
          <stop
            offset={0.656}
            stopColor="#bd06ff"
            className="stopColor2ea0d7 svgShape"
          />
          <stop
            offset={0.66}
            stopColor="#bd07ff"
            className="stopColor2ea0d8 svgShape"
          />
          <stop
            offset={0.664}
            stopColor="#bd07ff"
            className="stopColor2ea1d8 svgShape"
          />
          <stop
            offset={0.668}
            stopColor="#bd09ff"
            className="stopColor2fa2d9 svgShape"
          />
          <stop
            offset={0.672}
            stopColor="#bd09ff"
            className="stopColor2fa3d9 svgShape"
          />
          <stop
            offset={0.676}
            stopColor="#be0aff"
            className="stopColor2fa3da svgShape"
          />
          <stop
            offset={0.68}
            stopColor="#be0aff"
            className="stopColor2fa4da svgShape"
          />
          <stop
            offset={0.684}
            stopColor="#be0bff"
            className="stopColor2fa5db svgShape"
          />
          <stop
            offset={0.688}
            stopColor="#be0bff"
            className="stopColor2fa6db svgShape"
          />
          <stop
            offset={0.691}
            stopColor="#be0dff"
            className="stopColor30a6dc svgShape"
          />
          <stop
            offset={0.695}
            stopColor="#be0dff"
            className="stopColor30a7dc svgShape"
          />
          <stop
            offset={0.699}
            stopColor="#bf0eff"
            className="stopColor30a8dd svgShape"
          />
          <stop
            offset={0.703}
            stopColor="#bf0eff"
            className="stopColor30a9dd svgShape"
          />
          <stop
            offset={0.707}
            stopColor="#bf0fff"
            className="stopColor30a9de svgShape"
          />
          <stop
            offset={0.711}
            stopColor="#bf0fff"
            className="stopColor30aade svgShape"
          />
          <stop
            offset={0.715}
            stopColor="#c011ff"
            className="stopColor31abdf svgShape"
          />
          <stop
            offset={0.719}
            stopColor="#c011ff"
            className="stopColor31acdf svgShape"
          />
          <stop
            offset={0.723}
            stopColor="#c012ff"
            className="stopColor31ace0 svgShape"
          />
          <stop
            offset={0.727}
            stopColor="#c012ff"
            className="stopColor31ade0 svgShape"
          />
          <stop
            offset={0.73}
            stopColor="#c013ff"
            className="stopColor31aee1 svgShape"
          />
          <stop
            offset={0.734}
            stopColor="#c013ff"
            className="stopColor31afe1 svgShape"
          />
          <stop
            offset={0.738}
            stopColor="#c115ff"
            className="stopColor32afe2 svgShape"
          />
          <stop
            offset={0.742}
            stopColor="#c115ff"
            className="stopColor32b0e2 svgShape"
          />
          <stop
            offset={0.746}
            stopColor="#c116ff"
            className="stopColor32b1e3 svgShape"
          />
          <stop
            offset={0.75}
            stopColor="#c116ff"
            className="stopColor32b2e3 svgShape"
          />
          <stop
            offset={0.754}
            stopColor="#c117ff"
            className="stopColor32b2e4 svgShape"
          />
          <stop
            offset={0.758}
            stopColor="#c117ff"
            className="stopColor32b3e4 svgShape"
          />
          <stop
            offset={0.762}
            stopColor="#c219ff"
            className="stopColor33b4e5 svgShape"
          />
          <stop
            offset={0.766}
            stopColor="#c219ff"
            className="stopColor33b5e5 svgShape"
          />
          <stop
            offset={0.77}
            stopColor="#c21aff"
            className="stopColor33b5e6 svgShape"
          />
          <stop
            offset={0.773}
            stopColor="#c21aff"
            className="stopColor33b6e6 svgShape"
          />
          <stop
            offset={0.777}
            stopColor="#c21bff"
            className="stopColor33b7e7 svgShape"
          />
          <stop
            offset={0.781}
            stopColor="#c21bff"
            className="stopColor33b8e7 svgShape"
          />
          <stop
            offset={0.785}
            stopColor="#c21cff"
            className="stopColor33b8e8 svgShape"
          />
          <stop
            offset={0.789}
            stopColor="#c31dff"
            className="stopColor34b9e8 svgShape"
          />
          <stop
            offset={0.793}
            stopColor="#c31eff"
            className="stopColor34bae9 svgShape"
          />
          <stop
            offset={0.797}
            stopColor="#c31eff"
            className="stopColor34bbe9 svgShape"
          />
          <stop
            offset={0.801}
            stopColor="#c31fff"
            className="stopColor34bbea svgShape"
          />
          <stop
            offset={0.805}
            stopColor="#c31fff"
            className="stopColor34bcea svgShape"
          />
          <stop
            offset={0.809}
            stopColor="#c420ff"
            className="stopColor34bdeb svgShape"
          />
          <stop
            offset={0.813}
            stopColor="#c422ff"
            className="stopColor35beec svgShape"
          />
          <stop
            offset={0.816}
            stopColor="#c422ff"
            className="stopColor35beec svgShape"
          />
          <stop
            offset={0.82}
            stopColor="#c423ff"
            className="stopColor35bfed svgShape"
          />
          <stop
            offset={0.824}
            stopColor="#c423ff"
            className="stopColor35c0ed svgShape"
          />
          <stop
            offset={0.828}
            stopColor="#c524ff"
            className="stopColor35c0ee svgShape"
          />
          <stop
            offset={0.832}
            stopColor="#c524ff"
            className="stopColor35c1ee svgShape"
          />
          <stop
            offset={0.836}
            stopColor="#c526ff"
            className="stopColor36c2ef svgShape"
          />
          <stop
            offset={0.84}
            stopColor="#c526ff"
            className="stopColor36c3ef svgShape"
          />
          <stop
            offset={0.844}
            stopColor="#c527ff"
            className="stopColor36c3f0 svgShape"
          />
          <stop
            offset={0.875}
            stopColor="#c527ff"
            className="stopColor36c4f0 svgShape"
          />
          <stop
            offset={1}
            stopColor="#c527ff"
            className="stopColor36c4f0 svgShape"
          />
        </linearGradient>
        <linearGradient
          xmlnsXlink="http://www.w3.org/1999/xlink"
          id="o"
          x1={-368.702}
          x2={-225.964}
          y1={2453.88}
          y2={2453.88}
          gradientTransform="matrix(1.02875 2.18272 -2.18522 1.02758 5851.094 -1680.865)"
          gradientUnits="userSpaceOnUse"
          xlinkActuate="onLoad"
          xlinkShow="other"
          xlinkType="simple"
        >
          <stop
            offset={0}
            stopColor="#9e00d8"
            className="stopColor246cb4 svgShape"
          />
          <stop
            offset={0.125}
            stopColor="#9e00d8"
            className="stopColor246cb4 svgShape"
          />
          <stop
            offset={0.156}
            stopColor="#9e00d8"
            className="stopColor246cb4 svgShape"
          />
          <stop
            offset={0.164}
            stopColor="#9e00d8"
            className="stopColor246cb4 svgShape"
          />
          <stop
            offset={0.168}
            stopColor="#9e00d8"
            className="stopColor236cb5 svgShape"
          />
          <stop
            offset={0.172}
            stopColor="#9e00d8"
            className="stopColor236db5 svgShape"
          />
          <stop
            offset={0.176}
            stopColor="#9e00d8"
            className="stopColor226db6 svgShape"
          />
          <stop
            offset={0.18}
            stopColor="#9e00d8"
            className="stopColor216db7 svgShape"
          />
          <stop
            offset={0.184}
            stopColor="#9e00d7"
            className="stopColor206eb7 svgShape"
          />
          <stop
            offset={0.188}
            stopColor="#9e00d7"
            className="stopColor1f6eb8 svgShape"
          />
          <stop
            offset={0.191}
            stopColor="#9e00d7"
            className="stopColor1e6eb9 svgShape"
          />
          <stop
            offset={0.195}
            stopColor="#9d00d6"
            className="stopColor1d6fb9 svgShape"
          />
          <stop
            offset={0.199}
            stopColor="#9d00d6"
            className="stopColor1c6fba svgShape"
          />
          <stop
            offset={0.203}
            stopColor="#9d00d6"
            className="stopColor1b6fbb svgShape"
          />
          <stop
            offset={0.207}
            stopColor="#9c00d5"
            className="stopColor1a70bb svgShape"
          />
          <stop
            offset={0.211}
            stopColor="#9c00d5"
            className="stopColor1970bc svgShape"
          />
          <stop
            offset={0.215}
            stopColor="#9c00d5"
            className="stopColor1870bd svgShape"
          />
          <stop
            offset={0.219}
            stopColor="#9c00d5"
            className="stopColor1771be svgShape"
          />
          <stop
            offset={0.223}
            stopColor="#9b00d4"
            className="stopColor1671be svgShape"
          />
          <stop
            offset={0.227}
            stopColor="#9c00d5"
            className="stopColor1671bf svgShape"
          />
          <stop
            offset={0.23}
            stopColor="#9c00d5"
            className="stopColor1572c0 svgShape"
          />
          <stop
            offset={0.234}
            stopColor="#9b00d4"
            className="stopColor1472c0 svgShape"
          />
          <stop
            offset={0.238}
            stopColor="#9b00d4"
            className="stopColor1372c1 svgShape"
          />
          <stop
            offset={0.242}
            stopColor="#9b00d4"
            className="stopColor1273c2 svgShape"
          />
          <stop
            offset={0.246}
            stopColor="#9b00d3"
            className="stopColor1173c2 svgShape"
          />
          <stop
            offset={0.25}
            stopColor="#9b00d3"
            className="stopColor1073c3 svgShape"
          />
          <stop
            offset={0.254}
            stopColor="#9b00d3"
            className="stopColor0f74c4 svgShape"
          />
          <stop
            offset={0.258}
            stopColor="#9b00d3"
            className="stopColor0e74c5 svgShape"
          />
          <stop
            offset={0.262}
            stopColor="#9a00d2"
            className="stopColor0d74c5 svgShape"
          />
          <stop
            offset={0.266}
            stopColor="#9a00d2"
            className="stopColor0c75c6 svgShape"
          />
          <stop
            offset={0.27}
            stopColor="#9a00d2"
            className="stopColor0b75c7 svgShape"
          />
          <stop
            offset={0.273}
            stopColor="#9900d1"
            className="stopColor0a75c7 svgShape"
          />
          <stop
            offset={0.277}
            stopColor="#9a00d2"
            className="stopColor0a76c8 svgShape"
          />
          <stop
            offset={0.281}
            stopColor="#9a00d2"
            className="stopColor0976c9 svgShape"
          />
          <stop
            offset={0.285}
            stopColor="#9a00d2"
            className="stopColor0876ca svgShape"
          />
          <stop
            offset={0.289}
            stopColor="#9900d1"
            className="stopColor0777ca svgShape"
          />
          <stop
            offset={0.293}
            stopColor="#9900d1"
            className="stopColor0677cb svgShape"
          />
          <stop
            offset={0.297}
            stopColor="#9900d1"
            className="stopColor0577cc svgShape"
          />
          <stop
            offset={0.301}
            stopColor="#9900d0"
            className="stopColor0478cc svgShape"
          />
          <stop
            offset={0.305}
            stopColor="#9900d0"
            className="stopColor0378cd svgShape"
          />
          <stop
            offset={0.309}
            stopColor="#9900d1"
            className="stopColor0479cd svgShape"
          />
          <stop
            offset={0.313}
            stopColor="#9a00d2"
            className="stopColor047ace svgShape"
          />
          <stop
            offset={0.316}
            stopColor="#9b00d3"
            className="stopColor057ace svgShape"
          />
          <stop
            offset={0.32}
            stopColor="#9b00d3"
            className="stopColor057bce svgShape"
          />
          <stop
            offset={0.324}
            stopColor="#9c00d5"
            className="stopColor067ccf svgShape"
          />
          <stop
            offset={0.328}
            stopColor="#9c00d5"
            className="stopColor067dcf svgShape"
          />
          <stop
            offset={0.332}
            stopColor="#9d00d6"
            className="stopColor077dcf svgShape"
          />
          <stop
            offset={0.336}
            stopColor="#9e00d7"
            className="stopColor077ed0 svgShape"
          />
          <stop
            offset={0.34}
            stopColor="#9e00d8"
            className="stopColor087fd0 svgShape"
          />
          <stop
            offset={0.344}
            stopColor="#9e00d8"
            className="stopColor087fd0 svgShape"
          />
          <stop
            offset={0.348}
            stopColor="#a000da"
            className="stopColor0980d1 svgShape"
          />
          <stop
            offset={0.352}
            stopColor="#a000da"
            className="stopColor0981d1 svgShape"
          />
          <stop
            offset={0.355}
            stopColor="#a000da"
            className="stopColor0982d1 svgShape"
          />
          <stop
            offset={0.359}
            stopColor="#a100dc"
            className="stopColor0a82d2 svgShape"
          />
          <stop
            offset={0.363}
            stopColor="#a100dc"
            className="stopColor0a83d2 svgShape"
          />
          <stop
            offset={0.367}
            stopColor="#a200dd"
            className="stopColor0b84d2 svgShape"
          />
          <stop
            offset={0.371}
            stopColor="#a300de"
            className="stopColor0b85d3 svgShape"
          />
          <stop
            offset={0.375}
            stopColor="#a400df"
            className="stopColor0c85d3 svgShape"
          />
          <stop
            offset={0.379}
            stopColor="#a400df"
            className="stopColor0c86d3 svgShape"
          />
          <stop
            offset={0.383}
            stopColor="#a500e1"
            className="stopColor0d87d4 svgShape"
          />
          <stop
            offset={0.387}
            stopColor="#a500e1"
            className="stopColor0d88d4 svgShape"
          />
          <stop
            offset={0.391}
            stopColor="#a600e2"
            className="stopColor0e88d4 svgShape"
          />
          <stop
            offset={0.395}
            stopColor="#a600e3"
            className="stopColor0e89d5 svgShape"
          />
          <stop
            offset={0.398}
            stopColor="#a700e4"
            className="stopColor0f8ad5 svgShape"
          />
          <stop
            offset={0.402}
            stopColor="#a700e4"
            className="stopColor0f8bd5 svgShape"
          />
          <stop
            offset={0.406}
            stopColor="#a900e6"
            className="stopColor108bd6 svgShape"
          />
          <stop
            offset={0.41}
            stopColor="#a900e6"
            className="stopColor108cd6 svgShape"
          />
          <stop
            offset={0.414}
            stopColor="#aa00e8"
            className="stopColor118dd7 svgShape"
          />
          <stop
            offset={0.418}
            stopColor="#aa00e8"
            className="stopColor118dd7 svgShape"
          />
          <stop
            offset={0.422}
            stopColor="#ab00e9"
            className="stopColor128ed7 svgShape"
          />
          <stop
            offset={0.426}
            stopColor="#ac00ea"
            className="stopColor128fd8 svgShape"
          />
          <stop
            offset={0.43}
            stopColor="#ac00eb"
            className="stopColor1390d8 svgShape"
          />
          <stop
            offset={0.434}
            stopColor="#ac00eb"
            className="stopColor1390d8 svgShape"
          />
          <stop
            offset={0.438}
            stopColor="#ae00ed"
            className="stopColor1491d9 svgShape"
          />
          <stop
            offset={0.441}
            stopColor="#ae00ed"
            className="stopColor1492d9 svgShape"
          />
          <stop
            offset={0.445}
            stopColor="#af00ee"
            className="stopColor1593d9 svgShape"
          />
          <stop
            offset={0.449}
            stopColor="#af00ef"
            className="stopColor1593da svgShape"
          />
          <stop
            offset={0.453}
            stopColor="#b000f0"
            className="stopColor1694da svgShape"
          />
          <stop
            offset={0.457}
            stopColor="#b000f0"
            className="stopColor1695da svgShape"
          />
          <stop
            offset={0.461}
            stopColor="#b100f2"
            className="stopColor1796db svgShape"
          />
          <stop
            offset={0.465}
            stopColor="#b100f2"
            className="stopColor1796db svgShape"
          />
          <stop
            offset={0.469}
            stopColor="#b200f3"
            className="stopColor1897db svgShape"
          />
          <stop
            offset={0.473}
            stopColor="#b300f4"
            className="stopColor1898dc svgShape"
          />
          <stop
            offset={0.477}
            stopColor="#b400f5"
            className="stopColor1999dc svgShape"
          />
          <stop
            offset={0.48}
            stopColor="#b400f5"
            className="stopColor1999dc svgShape"
          />
          <stop
            offset={0.484}
            stopColor="#b500f7"
            className="stopColor1a9add svgShape"
          />
          <stop
            offset={0.488}
            stopColor="#b500f7"
            className="stopColor1a9bdd svgShape"
          />
          <stop
            offset={0.492}
            stopColor="#b600f8"
            className="stopColor1b9bdd svgShape"
          />
          <stop
            offset={0.496}
            stopColor="#b700f9"
            className="stopColor1b9cde svgShape"
          />
          <stop
            offset={0.5}
            stopColor="#b700fa"
            className="stopColor1c9dde svgShape"
          />
          <stop
            offset={0.504}
            stopColor="#b700fa"
            className="stopColor1c9ede svgShape"
          />
          <stop
            offset={0.508}
            stopColor="#b900fc"
            className="stopColor1d9edf svgShape"
          />
          <stop
            offset={0.512}
            stopColor="#b900fc"
            className="stopColor1d9fdf svgShape"
          />
          <stop
            offset={0.516}
            stopColor="#ba00fd"
            className="stopColor1ea0df svgShape"
          />
          <stop
            offset={0.52}
            stopColor="#ba00fe"
            className="stopColor1ea1e0 svgShape"
          />
          <stop
            offset={0.523}
            stopColor="#b0f"
            className="stopColor1fa1e0 svgShape"
          />
          <stop
            offset={0.527}
            stopColor="#b0f"
            className="stopColor1fa2e0 svgShape"
          />
          <stop
            offset={0.531}
            stopColor="#bc02ff"
            className="stopColor20a3e1 svgShape"
          />
          <stop
            offset={0.535}
            stopColor="#bc02ff"
            className="stopColor20a4e1 svgShape"
          />
          <stop
            offset={0.539}
            stopColor="#bc03ff"
            className="stopColor21a4e1 svgShape"
          />
          <stop
            offset={0.543}
            stopColor="#bc04ff"
            className="stopColor21a5e2 svgShape"
          />
          <stop
            offset={0.547}
            stopColor="#bc05ff"
            className="stopColor22a6e2 svgShape"
          />
          <stop
            offset={0.551}
            stopColor="#bc05ff"
            className="stopColor22a7e2 svgShape"
          />
          <stop
            offset={0.555}
            stopColor="#bd07ff"
            className="stopColor23a7e3 svgShape"
          />
          <stop
            offset={0.559}
            stopColor="#bd07ff"
            className="stopColor23a8e3 svgShape"
          />
          <stop
            offset={0.563}
            stopColor="#bd08ff"
            className="stopColor24a9e3 svgShape"
          />
          <stop
            offset={0.566}
            stopColor="#bd09ff"
            className="stopColor24a9e4 svgShape"
          />
          <stop
            offset={0.57}
            stopColor="#be0aff"
            className="stopColor25aae4 svgShape"
          />
          <stop
            offset={0.574}
            stopColor="#be0aff"
            className="stopColor25abe4 svgShape"
          />
          <stop
            offset={0.578}
            stopColor="#be0cff"
            className="stopColor26ace5 svgShape"
          />
          <stop
            offset={0.582}
            stopColor="#be0cff"
            className="stopColor26ace5 svgShape"
          />
          <stop
            offset={0.586}
            stopColor="#be0dff"
            className="stopColor27ade5 svgShape"
          />
          <stop
            offset={0.59}
            stopColor="#bf0eff"
            className="stopColor27aee6 svgShape"
          />
          <stop
            offset={0.594}
            stopColor="#bf0fff"
            className="stopColor28afe6 svgShape"
          />
          <stop
            offset={0.598}
            stopColor="#bf0fff"
            className="stopColor28afe6 svgShape"
          />
          <stop
            offset={0.602}
            stopColor="#c011ff"
            className="stopColor29b0e7 svgShape"
          />
          <stop
            offset={0.605}
            stopColor="#c011ff"
            className="stopColor29b1e7 svgShape"
          />
          <stop
            offset={0.609}
            stopColor="#c012ff"
            className="stopColor2ab2e7 svgShape"
          />
          <stop
            offset={0.613}
            stopColor="#c013ff"
            className="stopColor2ab2e8 svgShape"
          />
          <stop
            offset={0.617}
            stopColor="#c014ff"
            className="stopColor2bb3e8 svgShape"
          />
          <stop
            offset={0.621}
            stopColor="#c014ff"
            className="stopColor2bb4e8 svgShape"
          />
          <stop
            offset={0.625}
            stopColor="#c116ff"
            className="stopColor2cb5e9 svgShape"
          />
          <stop
            offset={0.629}
            stopColor="#c116ff"
            className="stopColor2cb5e9 svgShape"
          />
          <stop
            offset={0.633}
            stopColor="#c117ff"
            className="stopColor2db6e9 svgShape"
          />
          <stop
            offset={0.637}
            stopColor="#c118ff"
            className="stopColor2db7ea svgShape"
          />
          <stop
            offset={0.641}
            stopColor="#c219ff"
            className="stopColor2eb7ea svgShape"
          />
          <stop
            offset={0.645}
            stopColor="#c219ff"
            className="stopColor2eb8ea svgShape"
          />
          <stop
            offset={0.648}
            stopColor="#c21bff"
            className="stopColor2fb9eb svgShape"
          />
          <stop
            offset={0.652}
            stopColor="#c21bff"
            className="stopColor2fbaeb svgShape"
          />
          <stop
            offset={0.656}
            stopColor="#c21cff"
            className="stopColor30baeb svgShape"
          />
          <stop
            offset={0.66}
            stopColor="#c31dff"
            className="stopColor30bbec svgShape"
          />
          <stop
            offset={0.664}
            stopColor="#c31eff"
            className="stopColor31bcec svgShape"
          />
          <stop
            offset={0.668}
            stopColor="#c31fff"
            className="stopColor31bded svgShape"
          />
          <stop
            offset={0.672}
            stopColor="#c420ff"
            className="stopColor32bded svgShape"
          />
          <stop
            offset={0.676}
            stopColor="#c420ff"
            className="stopColor32beed svgShape"
          />
          <stop
            offset={0.68}
            stopColor="#c422ff"
            className="stopColor33bfee svgShape"
          />
          <stop
            offset={0.684}
            stopColor="#c422ff"
            className="stopColor33c0ee svgShape"
          />
          <stop
            offset={0.688}
            stopColor="#c423ff"
            className="stopColor34c0ee svgShape"
          />
          <stop
            offset={0.691}
            stopColor="#c524ff"
            className="stopColor34c1ef svgShape"
          />
          <stop
            offset={0.695}
            stopColor="#c524ff"
            className="stopColor34c2ef svgShape"
          />
          <stop
            offset={0.699}
            stopColor="#c525ff"
            className="stopColor35c3ef svgShape"
          />
          <stop
            offset={0.703}
            stopColor="#c526ff"
            className="stopColor35c3f0 svgShape"
          />
          <stop
            offset={0.707}
            stopColor="#c526ff"
            className="stopColor36c2ef svgShape"
          />
          <stop
            offset={0.711}
            stopColor="#c525ff"
            className="stopColor36c2ee svgShape"
          />
          <stop
            offset={0.715}
            stopColor="#c423ff"
            className="stopColor35bfed svgShape"
          />
          <stop
            offset={0.719}
            stopColor="#c420ff"
            className="stopColor34bceb svgShape"
          />
          <stop
            offset={0.723}
            stopColor="#c31eff"
            className="stopColor34b9e9 svgShape"
          />
          <stop
            offset={0.727}
            stopColor="#c21bff"
            className="stopColor33b7e7 svgShape"
          />
          <stop
            offset={0.73}
            stopColor="#c219ff"
            className="stopColor33b4e5 svgShape"
          />
          <stop
            offset={0.734}
            stopColor="#c116ff"
            className="stopColor32b1e3 svgShape"
          />
          <stop
            offset={0.738}
            stopColor="#c014ff"
            className="stopColor32aee1 svgShape"
          />
          <stop
            offset={0.742}
            stopColor="#c011ff"
            className="stopColor31abdf svgShape"
          />
          <stop
            offset={0.746}
            stopColor="#bf10ff"
            className="stopColor31a9de svgShape"
          />
          <stop
            offset={0.75}
            stopColor="#be0dff"
            className="stopColor30a6dc svgShape"
          />
          <stop
            offset={0.754}
            stopColor="#be0aff"
            className="stopColor2fa3da svgShape"
          />
          <stop
            offset={0.758}
            stopColor="#bd08ff"
            className="stopColor2fa0d8 svgShape"
          />
          <stop
            offset={0.762}
            stopColor="#bc05ff"
            className="stopColor2e9dd6 svgShape"
          />
          <stop
            offset={0.766}
            stopColor="#bc03ff"
            className="stopColor2e9bd4 svgShape"
          />
          <stop
            offset={0.77}
            stopColor="#b0f"
            className="stopColor2d98d2 svgShape"
          />
          <stop
            offset={0.773}
            stopColor="#ba00fd"
            className="stopColor2d95d0 svgShape"
          />
          <stop
            offset={0.777}
            stopColor="#b800fb"
            className="stopColor2c92cf svgShape"
          />
          <stop
            offset={0.781}
            stopColor="#b600f8"
            className="stopColor2b8fcd svgShape"
          />
          <stop
            offset={0.785}
            stopColor="#b400f6"
            className="stopColor2b8dcb svgShape"
          />
          <stop
            offset={0.789}
            stopColor="#b200f3"
            className="stopColor2a8ac9 svgShape"
          />
          <stop
            offset={0.793}
            stopColor="#b100f1"
            className="stopColor2a87c7 svgShape"
          />
          <stop
            offset={0.797}
            stopColor="#af00ee"
            className="stopColor2984c5 svgShape"
          />
          <stop
            offset={0.801}
            stopColor="#ad00ec"
            className="stopColor2981c3 svgShape"
          />
          <stop
            offset={0.805}
            stopColor="#ac00ea"
            className="stopColor287fc2 svgShape"
          />
          <stop
            offset={0.809}
            stopColor="#aa00e8"
            className="stopColor287cc0 svgShape"
          />
          <stop
            offset={0.813}
            stopColor="#a800e5"
            className="stopColor2779be svgShape"
          />
          <stop
            offset={0.816}
            stopColor="#a600e2"
            className="stopColor2676bc svgShape"
          />
          <stop
            offset={0.82}
            stopColor="#a400e0"
            className="stopColor2673ba svgShape"
          />
          <stop
            offset={0.824}
            stopColor="#a300de"
            className="stopColor2571b9 svgShape"
          />
          <stop
            offset={0.828}
            stopColor="#a100dc"
            className="stopColor256fb7 svgShape"
          />
          <stop
            offset={0.844}
            stopColor="#a100dc"
            className="stopColor256fb7 svgShape"
          />
          <stop
            offset={0.875}
            stopColor="#a100dc"
            className="stopColor256fb7 svgShape"
          />
          <stop
            offset={1}
            stopColor="#a100dc"
            className="stopColor256fb7 svgShape"
          />
        </linearGradient>
      </defs>
      <g clipPath="url(#a)" className="color000 svgShape">
        <g clipPath="url(#b)" className="color000 svgShape">
          <path
            fill="url(#c)"
            d="m161.621 166.543-172.387 81.062 84.942 180.223 172.387-81.062Zm0 0"
          />
        </g>
      </g>
      <g clipPath="url(#d)" className="color000 svgShape">
        <g clipPath="url(#e)" className="color000 svgShape">
          <path
            fill="url(#f)"
            d="m174.441 205.781-88.234 41.492 52.684 111.786 88.234-41.493Zm0 0"
          />
        </g>
      </g>
      <g clipPath="url(#g)" className="color000 svgShape">
        <g clipPath="url(#h)" className="color000 svgShape">
          <path
            fill="url(#i)"
            d="m301.125-52.836-172.402 81.07 84.945 180.235 172.406-81.074Zm0 0"
          />
        </g>
      </g>
      <g clipPath="url(#j)" className="color000 svgShape">
        <g clipPath="url(#k)" className="color000 svgShape">
          <path
            fill="url(#l)"
            d="M194.992 25.797 170.125 142.66l75.836 16.102 24.871-116.867Zm0 0"
          />
        </g>
      </g>
      <g clipPath="url(#m)" className="color000 svgShape">
        <g clipPath="url(#n)" className="color000 svgShape">
          <path
            fill="url(#o)"
            d="M243.621-27.148-18.562 96.14 128.28 407.699 390.465 284.41Zm0 0"
          />
        </g>
      </g>
    </svg>
  </svg>
);

export default NEMLogo;
