import {
  Box,
  Button,
  Center,
  Grid,
  GridItem,
  Link,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { SummaryCard } from './SummaryCard';
import customTheme, { PAGE_SPACING_PER_VIEWPORT } from '../styles/theme';
import React, { useContext, useEffect, useRef, useState } from 'react';
import ViewportContext from '../styles/viewport-context';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { BessStation, RevenueTotal, VPP } from '../api/api-types';
import { NembessSpinner } from './designSystem/NembessSpinner';

const VIEWPORT_TYPE_TO_NUM_COLS = {
  mobile: 1,
  tablet: 2,
  desktop: 3,
  'desktop-lg': 3,
};

type BessSummaryProps = {
  stations: VPP[] | BessStation[];
  isDashboardUpdating: boolean;
  endDate: Date | null;
  dashboardTechnology: 'BATTERY' | 'VPP';
};

type BessSummaryState = {
  isLoaded: boolean;
  revenueTotals: RevenueTotal[];
  stations: VPP[] | BessStation[];
  sortCriteria: 'REVENUE' | 'CAPACITY';
};

const INITIAL_STATE: BessSummaryState = {
  isLoaded: false,
  revenueTotals: [],
  stations: [],
  sortCriteria: 'REVENUE',
};

const HOST = process.env.REACT_APP_APIHOST;

async function fetchRevenueTotals(): Promise<RevenueTotal[] | undefined> {
  try {
    const response = await axios.get(`${HOST}/revenue_totals`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
}

export function PowerStationSummary(props: BessSummaryProps) {
  const viewport = useContext(ViewportContext);
  const [state, setState] = useState(INITIAL_STATE);
  const { onClose } = useDisclosure();
  const counter = useRef(0);
  // The image onLoad function will only be called for unique images as the browser will cache the same image.
  const stationImages = [
    ...new Set(props.stations.map((station) => station.image)),
  ];
  const incrementImageLoad = () => {
    counter.current += 1;
    if (counter.current >= stationImages.length) {
      setState((prevState) => ({
        ...prevState,
        isLoaded: true,
      }));
    }
  };

  useEffect(() => {
    async function fetchData() {
      const revenueTotals = await fetchRevenueTotals();

      setState((state) => ({
        ...state,
        revenueTotals: revenueTotals || [],
      }));
    }

    fetchData();
  }, []);

  useEffect(() => {
    setState((state) => ({ ...state, isLoaded: false }));

    function sortStations(a: BessStation | VPP, b: BessStation | VPP) {
      if (state.sortCriteria === 'CAPACITY') {
        return a.current_power_capacity < b.current_power_capacity ? 1 : -1;
      }
      if (state.sortCriteria === 'REVENUE') {
        const aRev =
          state.revenueTotals?.find((summary) => {
            return summary.station_id === a.id;
          })?.total_revenue || 0;
        const bRev =
          state.revenueTotals?.find((summary) => {
            return summary.station_id === b.id;
          })?.total_revenue || 0;
        return aRev < bRev ? 1 : -1;
      }
      return 0;
    }

    const sortedStations = props.stations.sort(sortStations);
    setState((state) => ({
      ...state,
      stations: sortedStations || [],
    }));
  }, [props.stations, state.revenueTotals, state.sortCriteria]);

  function handleSortCriteria(sortCriteria: 'REVENUE' | 'CAPACITY') {
    const sortedStations = [...state.stations].sort((a, b) => {
      if (sortCriteria === 'CAPACITY') {
        return a.current_power_capacity < b.current_power_capacity ? 1 : -1;
      }
      if (sortCriteria === 'REVENUE') {
        const aRev =
          state.revenueTotals.find((summary) => {
            return summary.station_id === a.id;
          })?.total_revenue || 0;
        const bRev =
          state.revenueTotals.find((summary) => {
            return summary.station_id === b.id;
          })?.total_revenue || 0;
        return aRev < bRev ? 1 : -1;
      }
      return 0;
    });
    setState((state) => ({
      ...state,
      sortCriteria: sortCriteria,
      stations: sortedStations,
    }));
  }

  const history = useNavigate();

  const urlPrefix =
    props.dashboardTechnology === 'BATTERY' ? '/batteries' : '/vpps';
  return (
    <Grid
      templateColumns={`repeat(${
        VIEWPORT_TYPE_TO_NUM_COLS[viewport.type]
      }, 1fr)`}
      gap={4}
      margin={4}
      px={PAGE_SPACING_PER_VIEWPORT}
      py={5}
    >
      <GridItem colSpan={VIEWPORT_TYPE_TO_NUM_COLS[viewport.type]}>
        <Button
          onClick={() => handleSortCriteria('CAPACITY')}
          isActive={state.sortCriteria === 'CAPACITY'}
          _active={{
            color: 'white',
            bgColor: customTheme.colors.pybessPurple,
            borderColor: 'white',
          }}
          float={'right'}
        >
          Capacity
        </Button>
        <Button
          onClick={() => handleSortCriteria('REVENUE')}
          isActive={state.sortCriteria === 'REVENUE'}
          _active={{
            color: 'white',
            bgColor: customTheme.colors.pybessPurple,
            borderColor: 'white',
          }}
          float={'right'}
        >
          Revenue
        </Button>
        <Box float={'right'} padding={2}>
          <Text>Sort By</Text>
        </Box>
      </GridItem>
      {state.revenueTotals.length > 0 &&
        state.stations.map((station, i) => (
          <GridItem
            _hover={{
              color: customTheme.colors.pybessPurple,
              boxShadow: 'rgba(121, 40, 202, 0.3) 0px 5px 15px',
            }}
            borderRadius={20}
            key={i}
          >
            <Link
              onClick={() => {
                history(`${urlPrefix}/revenue/${station.id}`);
              }}
              _hover={{
                color: customTheme.colors.pybessPurple,
              }}
            >
              <SummaryCard
                key={i}
                bessName={station.display_name || station.station_name}
                powerCapacity={station.current_power_capacity}
                storageCapacity={
                  'current_storage_capacity' in station
                    ? station.current_storage_capacity
                    : null
                }
                revenue={
                  state.revenueTotals.filter((summary) => {
                    return summary.station_id === station.id;
                  })[0]?.total_revenue
                }
                imageUrl={station.image}
                incrementImageLoad={incrementImageLoad}
              />
            </Link>
          </GridItem>
        ))}
      {!state.isLoaded && (
        <Modal onClose={onClose} isOpen={!state.isLoaded} isCentered>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              <Center>Loading</Center>
            </ModalHeader>
            <ModalBody>
              <Center height={'200px'}>
                <NembessSpinner />
              </Center>
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </Grid>
  );
}
