import {
  Box,
  Button,
  Center,
  Flex,
  Heading,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
  useDisclosure
} from "@chakra-ui/react";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import customTheme from "../styles/theme";
import theme from "../styles/theme";
import { pdfjs } from "react-pdf";
import { NumericFormat } from "react-number-format";

import { EventContribution } from "./EventContributions";
import { InfoIcon } from "@chakra-ui/icons";
import ViewportContext from "../styles/viewport-context";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  RowData,
  useReactTable
} from "@tanstack/react-table";
import moment from "moment";

declare module "@tanstack/table-core" {
  interface ColumnMeta<TData extends RowData, TValue> {
    columnWidth: string;
  }
}
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const INITIAL_STATE = {
  isLoaded: false,
  events: [],
};

const HOST = process.env.REACT_APP_APIHOST;

async function fetchEvents() {
  try {
    const response = await axios.get(`${HOST}/power_system_events`);
    const events = response.data.map((summary: Event) => ({
      ...summary,
      name: summary.id,
    }));
    return events;
  } catch (error) {
    console.error(error);
  }
}

// function EventTitleFormatter(cell, row) {
//   return (
//     <Link href={row.link} target={"_blank"}>
//       <Text noOfLines={2}>{row.title}</Text>
//     </Link>
//   );
// }
//
// function EventContributionFormatter(cell, row) {
//   return (
//     <EventContribution
//       key={row.date}
//       date={row.date}
//       title={row.title}
//       link={row.link}
//     />
//   );
// }
//
export function revenueFormatter(revenue: number) {
  return (
    <NumericFormat
      value={revenue}
      displayType={"text"}
      thousandSeparator={true}
      decimalScale={0}
    />
  );
}

function dateFormatter(dateString: string) {
  const momentObj = moment(dateString, "YYYY-MM-DD 00:00:00");
  const momentString = momentObj.format("YYYY-MM-DD");
  return <Box>{momentString}</Box>;
}

type Event = {
  id: number;
  date: string;
  title: string;
  total_revenue: number;
  link: string;
};

const columnHelper = createColumnHelper<Event>();
const columns = [
  columnHelper.accessor("date", {
    id: "date",
    header: () => <Center>Date</Center>,
    cell: (info) => (
      <Center whiteSpace={"nowrap"}>{dateFormatter(info.getValue())}</Center>
    ),
    meta: { columnWidth: "20%" },
  }),
  columnHelper.accessor("title", {
    id: "title",
    header: () => <span>Title</span>,
    cell: (info) => (
      <Box>
        <Link href={info.row.original.link}>{info.getValue()}</Link>
      </Box>
    ),
    meta: { columnWidth: "50%" },
  }),
  columnHelper.accessor("total_revenue", {
    id: "total_revenue",
    header: () => <Center>Total Revenue</Center>,
    cell: (info) => (
      <Center whiteSpace={"nowrap"}>{revenueFormatter(info.getValue())}</Center>
    ),
    meta: { columnWidth: "20%" },
  }),
  columnHelper.display({
    id: "contribution",
    cell: (props) => (
      <EventContribution
        key={props.row.getValue("date")}
        date={props.row.getValue("date")}
        title={props.row.getValue("title")}
        link={props.row.original.link}
      />
    ),
    meta: { columnWidth: "10%" },
  }),
];

export function Events() {
  const [state, setState] = useState(INITIAL_STATE);
  const viewport = useContext(ViewportContext);
  const isSmallScreen = ["mobile", "tablet"].includes(viewport.type);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const table = useReactTable({
    data: state.events,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  useEffect(() => {
    async function fetchData() {
      let events = await fetchEvents();
      events = events.map((evt: Event, index: number) => ({
        ...evt,
        key: index,
      }));
      setState((state) => ({
        ...state,
        events: events,
        isLoaded: true,
      }));
    }
    fetchData();
  }, []);

  const SubText = (
    <Text size={["sm", "md"]} padding={1} paddingBottom={4}>
      The financial performance of BESS's is often highly dependent on price
      spikes caused by unusual events in the power system. This page shows some
      power system events that have been reported by AEMO, and the visualises
      the revenue that participating BESS's collected on the day each event
      occurred.
    </Text>
  );

  return (
    <Box padding={4}>
      <Flex padding={2}>
        <Box>
          <Heading size={"lg"} padding={1}>
            Power System Events
          </Heading>
        </Box>
        {isSmallScreen && (
          <Center>
            <InfoIcon
              color={customTheme.colors.pybessPurple}
              onClick={onOpen}
              w={5}
              h={5}
            />
          </Center>
        )}
      </Flex>
      {!isSmallScreen && <Box>{SubText}</Box>}
      <Modal onClose={onClose} isOpen={isOpen} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Power System Events</ModalHeader>
          <ModalCloseButton />
          <ModalBody>{SubText}</ModalBody>
          <ModalFooter>
            <Button onClick={onClose}>Close</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      {state.isLoaded ? (
        <Box>
          <table style={{ width: "100%" }}>
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr
                  key={headerGroup.id}
                  style={{
                    backgroundColor: "white",
                    color: theme.colors.pybessPurple,
                    borderBottom: "1px solid #dee2e6",
                  }}
                >
                  {headerGroup.headers.map((header) => (
                    <th
                      key={header.id}
                      style={{
                        width:
                          header.column.columnDef.meta?.columnWidth || "20%",
                      }}
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map((row) => (
                <tr
                  key={row.id}
                  style={{
                    backgroundColor:
                      parseInt(row.id) % 2 === 0
                        ? "white"
                        : `${theme.colors.pybessPurple}20`,
                  }}
                >
                  {row.getVisibleCells().map((cell) => (
                    <td key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
            <tfoot>
              {table.getFooterGroups().map((footerGroup) => (
                <tr key={footerGroup.id}>
                  {footerGroup.headers.map((header) => (
                    <th key={header.id}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.footer,
                            header.getContext()
                          )}
                    </th>
                  ))}
                </tr>
              ))}
            </tfoot>
          </table>
          <button
            className="border rounded p-1"
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            {"<"}
          </button>
          <button
            className="border rounded p-1"
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
          >
            {">"}
          </button>
          <span className="flex items-center gap-1">
            <div>Page</div>
            <strong>
              {table.getState().pagination.pageIndex + 1} of{" "}
              {table.getPageCount()}
            </strong>
          </span>
        </Box>
      ) : (
        <Box>
          <Center mt={"30vh"}>
            <Spinner color={customTheme.colors.pybessPurple} />
            <Text>Loading</Text>
          </Center>
        </Box>
      )}
    </Box>
  );
}
