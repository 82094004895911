import {
  Box,
  Button,
  Center,
  Flex,
  Heading,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Progress,
  Text,
  useDisclosure
} from "@chakra-ui/react";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import customTheme from "../styles/theme";
import moment from "moment";
import axios from "axios";
import { useState } from "react";
import { NumericFormat } from "react-number-format";
import { revenueFormatter } from "./Events";
import { createColumnHelper, flexRender, getCoreRowModel, useReactTable } from "@tanstack/react-table";

const INITIAL_STATE = {
  contributions: [],
};

function dateFormatter(string) {
  const momentObj = moment(string, "YYYY-MM-DD 00:00:00");
  const momentString = momentObj.format("Do MMMM YYYY");
  return <div> {momentString}</div>;
}

const HOST = process.env.REACT_APP_APIHOST;

async function fetchEventContributions(date) {
  try {
    const response = await axios.get(
      `${HOST}/power_system_event_contributions?date=${date}`
    );
    const contributions = response.data.map((summary) => ({
      ...summary,
      name: summary.id,
    }));
    return contributions;
  } catch (error) {
    console.error(error);
  }
}

function contributionFormatter(contribution) {
  return (
    <Box>
      <NumericFormat
        suffix={"%"}
        decimalScale={0}
        displayType={"text"}
        value={contribution * 100}
      />
      <Progress colorScheme="red" size="xs" max={1} value={contribution} />
    </Box>
  );
}

const columnHelper = createColumnHelper();
const columns = [
  columnHelper.accessor("station_name", {
    header: () => <span>Station</span>,
    cell: (info) => info.getValue(),
    meta: { columnWidth: "40%" },
  }),
  columnHelper.accessor("bess_revenue", {
    header: () => <Center>BESS Revenue ($)</Center>,
    cell: (info) => <Center>{revenueFormatter(info.getValue())}</Center>,
    meta: { columnWidth: "20%" },
  }),
  columnHelper.accessor("contribution", {
    header: () => <Center>Contribution</Center>,
    cell: (info) => contributionFormatter(info.getValue()),
    meta: { columnWidth: "30%" },
  }),
  columnHelper.display({
    id: "link",
    header: <Center>View</Center>,
    cell: (props) => (
      <Box>
        <Center>
          <Link
            href={`/batteries/operations/${
              props.row.original.station_id
            }?date=${moment(
              props.row.original.date,
              "YYYY-MM-DD 00:00:00"
            ).format("YYYY-MM-DD")}`}
          >
            <ExternalLinkIcon
              color={customTheme.colors.pybessPurple}
              w={5}
              h={5}
            />
          </Link>
        </Center>
      </Box>
    ),
    meta: { columnWidth: "10%" },
  }),
];

export function EventContribution(props) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [state, setState] = useState(INITIAL_STATE);

  const table = useReactTable({
    data: state.contributions,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  async function handleClick(date) {
    let contributions = await fetchEventContributions(date);
    contributions = contributions.map((contribution, index) => ({
      ...contribution,
      key: index,
    }));
    setState((state) => ({
      ...state,
      contributions: contributions,
    }));
    onOpen();
  }

  return (
    <>
      <Center>
        <ExternalLinkIcon
          onClick={() => handleClick(props.date)}
          color={customTheme.colors.pybessPurple}
          w={5}
          h={5}
          cursor={"pointer"}
        />
      </Center>

      <Modal size={"full"} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Heading size={"lg"} fontWeight={"semibold"}>
              {dateFormatter(props.date)}
            </Heading>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Link target="_blank" href={props.link}>
              <Flex paddingBottom={2}>
                <Box>
                  <Text>{props.title}</Text>
                </Box>
                <Box>
                  <ExternalLinkIcon marginLeft={2} />
                </Box>
              </Flex>
            </Link>

            <Box mt={"10px"}>
              <table>
                <thead>
                  {table.getHeaderGroups().map((headerGroup) => (
                    <tr key={headerGroup.id}>
                      {headerGroup.headers.map((header) => (
                        <th
                          key={header.id}
                          style={{
                            width:
                              header.column.columnDef.meta?.columnWidth ||
                              "20%",
                          }}
                        >
                          {header.isPlaceholder
                            ? null
                            : flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody>
                  {table.getRowModel().rows.map((row) => (
                    <tr key={row.id}>
                      {row.getVisibleCells().map((cell) => (
                        <td key={cell.id}>
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
                <tfoot>
                  {table.getFooterGroups().map((footerGroup) => (
                    <tr key={footerGroup.id}>
                      {footerGroup.headers.map((header) => (
                        <th key={header.id}>
                          {header.isPlaceholder
                            ? null
                            : flexRender(
                                header.column.columnDef.footer,
                                header.getContext()
                              )}
                        </th>
                      ))}
                    </tr>
                  ))}
                </tfoot>
              </table>
            </Box>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
