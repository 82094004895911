import {
  AbsoluteCenter,
  Box,
  Center,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Link,
  Stack,
  Text,
  useDisclosure
} from "@chakra-ui/react";
import React from "react";
import { NAVBAR_HEIGHT } from "./Navbar";
import { DashboardLink } from "./Dashboard";
import { useNavigate } from "react-router-dom";
import customTheme from "../styles/theme";
import { ChevronRight } from "react-bootstrap-icons";

type SidebarDrawerProps = {
  isOpen: boolean;
  dashboardLinks: DashboardLink[];
  selectedPage: string;
};

const EXPAND_ICON_DIAMETER = 25;

export function SidebarDrawer(props: SidebarDrawerProps) {
  const { dashboardLinks, selectedPage } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const history = useNavigate();
  return (
    <Box
      left={0}
      top={`${NAVBAR_HEIGHT - EXPAND_ICON_DIAMETER / 2}px`}
      position={"fixed"}
      zIndex={1000}
    >
      <Box
        position={"relative"}
        onClick={onOpen}
        borderRadius={"0px 50% 50% 0px"}
        boxShadow={"rgba(108, 122, 137, 0.5) 5px 3px 5px"}
        bg={"white"}
        w={`${EXPAND_ICON_DIAMETER}px`}
        h={`${EXPAND_ICON_DIAMETER}px`}
        zIndex={1001}
      >
        <AbsoluteCenter>
          <ChevronRight color={customTheme.colors.pybessPurple} size={16} />
        </AbsoluteCenter>
      </Box>
      <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader></DrawerHeader>

          <DrawerBody>
            <Stack direction="column" spacing={0}>
              {dashboardLinks.map((link, index) => {
                return (
                  <Link
                    onClick={() => {
                      history(link.linkRoute);
                    }}
                    key={index}
                    _focus={undefined}
                  >
                    <Box paddingTop={5} paddingBottom={5}>
                      <Box>
                        <Center
                          backgroundColor={
                            link.linkText === selectedPage
                              ? "rgba(142, 46, 178, 0.2)"
                              : undefined
                          }
                          borderRadius={"0px 10px 10px 0px"}
                        >
                          <Text
                            fontWeight={
                              link.linkText === selectedPage
                                ? "semibold"
                                : undefined
                            }
                          >
                            {link.linkText}
                          </Text>
                        </Center>
                      </Box>
                    </Box>

                    {/*<Divider orientation={"horizontal"} color={"white"} />*/}
                  </Link>
                );
              })}
            </Stack>
          </DrawerBody>

          <DrawerFooter></DrawerFooter>
        </DrawerContent>
      </Drawer>
    </Box>
  );
}
